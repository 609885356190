import { MutationTree } from 'vuex';
import { pageSize } from '@/common/constants/getVerifiedConstants';
import {
  SET_ORG_PROFILE_INFO,
  SET_ORG_IS_PROGRESS,
  SET_ORG_REQUEST_CHANGE_INPROGRESS,
  SET_REQUEST_INFO,
  SET_ACTIVE_STEPS,
  SET_CARLA_KEYCLOAK_TOKEN,
  SET_VC_FILTER_PARAMS,
  SET_VC_DETAILS,
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
  SET_APPEND_VCS,
} from './mutation-types';

import {
  IOrgProfileState
} from './IntOrgProfileState';

const mutations: MutationTree<IOrgProfileState> = {
  [SET_CARLA_KEYCLOAK_TOKEN](state: IOrgProfileState, payload) {
    state.carlaKeycloakToken = payload;
  },
  [SET_ORG_PROFILE_INFO](state: IOrgProfileState, payload) {
    state.orgProfileInfo = payload;
  },
  [SET_ORG_IS_PROGRESS](state: IOrgProfileState, payload: boolean) {
    state.isProgress = payload;
  },
  [SET_ORG_REQUEST_CHANGE_INPROGRESS](state: IOrgProfileState, payload: boolean) {
    state.requestChangeInProgress = payload;
  },
  [SET_REQUEST_INFO](state: IOrgProfileState, payload) {
    state.requestInfo = payload;
  },
  [SET_ACTIVE_STEPS](state: IOrgProfileState, payload) {
    state.activeSteps = payload;
  },
  [SET_VC_FILTER_PARAMS](state: IOrgProfileState, payload) {
    state.VCFilterParams.filterParam = payload?.filterParam ? payload?.filterParam !== 'exp_status:ALL' ? payload?.filterParam : '' : '';
    state.VCFilterParams.page.pageStart = payload?.pageStart || 0;
    state.VCFilterParams.page.pageSize = payload?.pageSize || pageSize;
    state.VCFilterParams.searchKeyword = payload?.searchKeyword || '';
  },
  [SET_VC_DETAILS](state: IOrgProfileState, payload) {
    if (state.appendVCs && payload.content[0]?.legalPersonVCs?.length) {
      state.VCDetails.content[0].legalPersonVCs.push(...payload.content[0].legalPersonVCs);
    } else if(!state.appendVCs) {
      state.VCDetails = payload;
    }
  },
  [SET_FETCH_VC_DETAILS_IN_PROGRESS](state: IOrgProfileState, payload: boolean) {
    state.fetchVCDetailsInProgress = payload;
  },
  [SET_APPEND_VCS](state: IOrgProfileState, payload: boolean) {
    state.appendVCs = payload;
  },
};
export default mutations;