import {Module} from 'vuex';
import {
} from './getter-types';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import {
  IntDataspaceState
} from './IntDataspaces';

// initial state
const getDefaultState = (): IntDataspaceState => {
  return {
    isLoading: false,
    dataspaces: [],
    selectedDataspace: null,
    myDataspaces: [],
    mySelectedDataspace: null
  }
}
const INITIAL_STATE = getDefaultState();

// org profile store
const DataspaceStore: Module<IntDataspaceState, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};

export default DataspaceStore;
