import {MutationTree} from 'vuex';
import {
  SET_DATASPACES,
  SET_IS_LOADING,
  SET_SELECTED_DATASPACE,
  SET_MY_DATASPACES,
  SET_MY_SELECTED_DATASPACE,
  UPDATE_MY_DATASPACES
} from './mutation-types';

import {
  IntDataspaceState,
  IntDataspace
} from './IntDataspaces';

const mutations: MutationTree<IntDataspaceState> = {
  [SET_DATASPACES](state, payload: []) {
    state.dataspaces = payload;
  },
  [SET_IS_LOADING](state, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_SELECTED_DATASPACE](state, payload: IntDataspace) {
    state.selectedDataspace = payload;
  },
  [SET_MY_DATASPACES](state, payload) {
    state.myDataspaces = payload;
  },
  [SET_MY_SELECTED_DATASPACE](state, payload: IntDataspace) {
    state.mySelectedDataspace = payload
  },
  [UPDATE_MY_DATASPACES](state, payload) {
    // TODO
    if(payload.length) {
      payload.forEach((payloadItem: IntDataspace) => {
        state.myDataspaces.forEach(item => {
          if(payloadItem.id === item.id) {
            item.status = payloadItem.status;
          }
        });
      });
    }
  }
};
export default mutations;