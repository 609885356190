import { createStore } from 'vuex';

import auth from './modules/auth';
import UserProfileStore from './modules/user';
import LoadingState from './modules/loading';
import manageOrgOnboardingState from '../views/onboarding/store';
import notificationsState from '@/components/NotificationBar/store';
import OrgProfileState from '../views/organizations/store';
import DataspaceStore from '../views/dataspaces/stores';
import NotificationStore from '@/views/notifications/stores';

export default createStore({
  modules: {
    auth,
    LoadingState,
    UserProfileStore,
    manageOrgOnboardingState,
    notificationsState,
    OrgProfileState,
    DataspaceStore,
    NotificationStore
  }
})
