/**
 * This service is use for organization request
 */

import axios from 'axios';
import Util from '../util';
import {
  NewOrgRequestInt
} from '@/views/onboarding/store/OrgOnboardingInt';

export default class OrganizationService {
    /**
     * Create a new organization
     * @param organization 
     * @returns object
     */
    static registerNewOrganization(organization: NewOrgRequestInt) {
      return axios.post(Util.getRestApiUrl('organization/request-to-join', 'org'), organization);
    }

    /**
     * Onboardin user with plan selection with uuid
     * @returns {Promise<IConnector[]>}
     */
    static checkoutPlan(payload: unknown) {
      const reqPayload = payload;
      return axios.post(Util.getRestApiUrl('checkout', 'plan'), reqPayload)
        .then(res => res.data);
    }

    /**
     * Get User's organization list
     * @returns Array of orgnization details object
     */
    static getOrgnizationListByUser() {
      return axios.get(Util.getRestApiUrl('organization/profile', 'org'));
    }

    /**
     * Get Organization details by id
     * @returns organization details
     */
    static getOrganizationDetailsById(id: string) {
      return axios.get(Util.getRestApiUrl(`organization/${id}`, 'org'));
    }

    /**
     * Request a change for the organization
     */
     static organizationRequestChangeDetailsById(id: string) {
      return axios.post(Util.getRestApiUrl(`organization/${id}/change-request`, 'org'));
    }

    /**
     * Onboardin user with plan selection with uuid
     * @returns {Promise}
     */
    static getConnectedWithDataspace(payload: unknown) {
      const reqPayload = payload;
      return axios.post(Util.getRestApiUrl('checkout', 'plan'), reqPayload);
    }
}