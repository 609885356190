import {ActionTree} from 'vuex';
import Util from '@/common/util';

import {
  FETCH_ORG_BY_ID,
  ORG_DETAILS_CHANGE_REQUEST,
  SEND_VC_REQUEST,
  CARLA_KEYCLOAK_REQUEST,
  FETCH_VC_REQUEST,
  REVOKE_VC_REQUEST,
} from './action-types';
import {
  SET_ORG_PROFILE_INFO,
  SET_ORG_IS_PROGRESS,
  SET_ORG_REQUEST_CHANGE_INPROGRESS,
  SET_CARLA_KEYCLOAK_TOKEN,
  SET_VC_DETAILS,
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
} from './mutation-types';
import OrganizationService from '@/common/services/OrganizationServices';
import GetVerifiedService from '@/common/services/GetVerifiedServices';
import {
  ADD_ERROR_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION
} from '@/components/NotificationBar/store/mutation-types';
import {
  IOrgProfileState,
  requestInfoField
} from './IntOrgProfileState';
import { countriesList } from '@/common/constants/countriesList';

function createPayload(requestInfo: requestInfoField) {
  const {
    type,
    legalName,
    country,
    street,
    city,
    postalCode,
    vatid,
    eori,
    lei,
    local,
    did,
    exceptProfile
  } = requestInfo;

  // add uniqueId's only when they are added in forms
  const uniqueIdArray: { type: string, value: string}[] = [];
  if (vatid !== '') {
    uniqueIdArray.push({
      type: "vatID",
      value: vatid
    });
  }
  if (eori !== '') {
    uniqueIdArray.push({
      type: "EORI",
      value: eori
    });
  }
  if (local !== '') {
    uniqueIdArray.push({
      type: "local",
      value: local
    });
  }
  if (lei !== '') {
    uniqueIdArray.push({
      type: "leiCode",
      value: lei
    });
  }
  
  const countryObj = countriesList.find(item => item.value === country);
  const payload = {
    participantDetails: {
      name: legalName || '',
      city: city || '',
      street: street || '',
      country: countryObj?.text || '',
      countryAlpha2Code: country || '',
      postalCode: postalCode || '',
    },
    identityDetails: {
      did: did || '',
      uniqueIds: uniqueIdArray
    },
    callbackUrl: window.location.host + "/api/v1/validation/callback",
    vcType: type,
    exceptProfile: exceptProfile || true
  };

  return payload;
}

export const actions: ActionTree<IOrgProfileState, {}> = {
  /**
   * Dispatch the get request to get user organization
   */
  [FETCH_ORG_BY_ID]({commit}) {
    commit(SET_ORG_IS_PROGRESS, true);
    OrganizationService.getOrgnizationListByUser()
      .then(res => {
        const { status, data} = res;
        if(status === 200) {
          commit(SET_ORG_PROFILE_INFO, data);
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
        }
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
      .finally(() => {
        commit(SET_ORG_IS_PROGRESS, false);
      });
  },

  /**
   * Request to change organization details
   * @Param payload: string organisation Id
   */
  [ORG_DETAILS_CHANGE_REQUEST]({commit, dispatch}, payload) {
    const id = payload;
    commit(SET_ORG_REQUEST_CHANGE_INPROGRESS, true);
    OrganizationService.organizationRequestChangeDetailsById(id)
      .then(res => {
        const { status } = res;
        if(status === 202) {
          commit(ADD_SUCCESS_NOTIFICATION, 'Your organization change request is sucessfully raised');
          dispatch(FETCH_ORG_BY_ID);
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
        }
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
      .finally(() => {
        commit(SET_ORG_REQUEST_CHANGE_INPROGRESS, false);
      });
  },

  /**
   * Dispatch the post request create a new access_token to authorized all carla services
   * @param param0
   */
  [CARLA_KEYCLOAK_REQUEST]({commit}) {
    const payload = "client_id=" + import.meta.env.VITE_APP_CARLA_CLIENT_ID+
        "&grant_type=" + import.meta.env.VITE_APP_CARLA_GRANT_TYPE +
        "&client_secret=" + import.meta.env.VITE_APP_CARLA_CLIENT_SECRET ;
    return new Promise((resolve, reject) => { 
      GetVerifiedService.carlaTokenRequest(payload)
      .then((res) => {
        const { data } = res;
        // Added received token in env veriable for future use
        commit(SET_CARLA_KEYCLOAK_TOKEN, data.access_token);
        resolve('success');
      })
      .catch(() => {
        reject('error');
      })
    });
  },

  /**
   * Dispatch the post request to create a new singed verified credentials
   * @param param0
   */
  [SEND_VC_REQUEST]({commit, state, dispatch}) {
    const {requestInfo, carlaKeycloakToken} = state;
    //request payload
    const payload = createPayload(requestInfo);
    return new Promise((resolve, reject) => {
      GetVerifiedService.createVCRequest(payload, carlaKeycloakToken)
        .then((res) => {
          commit(ADD_SUCCESS_NOTIFICATION, 'Successfully signed VC');
          resolve('success');
        })
        .catch((err) => {
          if (err.response?.data?.right?.errorMessages) {
            const errorMessages = err.response?.data?.right?.errorMessages;
            commit(ADD_ERROR_NOTIFICATION, { text: "Errors", list: errorMessages });
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
          }
          reject('error');
        })
    });
  },

  /**
   * Dispatch the get request to fetch singed verified credentials
   * @param param0
   */
  async [FETCH_VC_REQUEST]({ commit, state, dispatch }) {
    commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, true);
    await dispatch(CARLA_KEYCLOAK_REQUEST);
    const {VCFilterParams: {filterParam, page : {pageStart, pageSize }, searchKeyword }, carlaKeycloakToken} = state;
    return new Promise((resolve, reject) => {
      GetVerifiedService.fetchVCRequest(filterParam, pageStart, pageSize, searchKeyword, carlaKeycloakToken)
      .then(res => {
        const { status, data } = res;
        if(status === 200) {
          commit(SET_VC_DETAILS, data);
          if( Object.keys(data.content[0]).length ){
            resolve('success');
          }
          else {
            resolve('no results');
          }
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject('error');
        }
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
        reject('error');
      })
      .finally(() => {
        commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, false);
      })
    });
  },

  async [REVOKE_VC_REQUEST]({ commit, state, dispatch }, selectedVCs) {
    commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, true);
    await dispatch(CARLA_KEYCLOAK_REQUEST);
    const { carlaKeycloakToken } = state;
    const payload = state.VCDetails.content[0].legalPersonVCs
      .filter((el) => selectedVCs.includes(el.id))
      .map((el) => { 
        const credentialStatus = el.verifiableCredential.credentialStatus;
        credentialStatus.statusListCredential = `${Util.getRestApiUrl('credentials/status/revocation', 'carla-revoke-check')}`
        return { credentialStatus }
      });
      GetVerifiedService.revokeVCRequest(payload, carlaKeycloakToken)
      .then(res => {
        commit(ADD_SUCCESS_NOTIFICATION, 'Successfully revoked VCs');
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
  },
}
