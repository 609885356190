import {MutationTree} from 'vuex';
import {
  SET_USER_INFO,
  SET_IS_LOADING,
  SET_PAGE_BREADCRUMBS
} from './mutation-types';
import {
  IntUserProfileState,
  ICurrentRoute
} from './index';
import i18n from '@/i18n';

const mutations: MutationTree<IntUserProfileState> = {
  [SET_USER_INFO](state, payload) {
    state.userInfo = payload;
    //// TODO
    i18n.global.locale.value = payload.language ? payload.language.toLocaleLowerCase() : 'en';
  },
  [SET_IS_LOADING](state, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_PAGE_BREADCRUMBS](state, payload: ICurrentRoute[]) {
    state.breadcrumbs = [];
    setTimeout(() => state.breadcrumbs = payload, 100);
  }
};
 export default mutations;