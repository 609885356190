import {GetterTree} from 'vuex';
import {
  GET_ORG_PROFILE_INFO,
  GET_ORG_IS_PROGRESS,
  GET_ORG_PROFILE_ADDRESS,
  GET_ORG_REQUEST_CHANGE_INPROGRESS,
  GET_REQUEST_INFO,
  GET_ACTIVE_STEPS,
  GET_CARLA_KEYCLOAK_TOKEN,
  GET_VC_FILTER_PARAMS,
  GET_VC_DETAILS,
  GET_FETCH_VC_DETAILS_IN_PROGRESS,
  GET_APPEND_VCS,
} from './getter-types';
import {
  IOrgProfileState
} from './IntOrgProfileState';

export const getters: GetterTree<IOrgProfileState, {}> = {
  [GET_ORG_PROFILE_INFO]: state => state.orgProfileInfo,
  [GET_ORG_PROFILE_ADDRESS]: state => state.orgProfileInfo?.address?.city ,
  [GET_ORG_IS_PROGRESS]: state => state.isProgress,
  [GET_ORG_REQUEST_CHANGE_INPROGRESS]: state => state.requestChangeInProgress,
  [GET_REQUEST_INFO]: (state) => state.requestInfo,
  [GET_ACTIVE_STEPS]: (state) => state.activeSteps,
  [GET_CARLA_KEYCLOAK_TOKEN]: (state) => state.carlaKeycloakToken,
  [GET_VC_FILTER_PARAMS]: (state) => state.VCFilterParams,
  [GET_VC_DETAILS]: (state) => state.VCDetails.content[0],
  [GET_FETCH_VC_DETAILS_IN_PROGRESS]: (state) => state.fetchVCDetailsInProgress,
  [GET_APPEND_VCS]: (state) => state.appendVCs,
}