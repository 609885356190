export const IS_AUTHORIZED = 'IS_AUTHORIZED';
export const USERNAME = 'USERNAME';
export const USER_KEYCLOAK_ID = 'USER_KEYCLOAK_ID';
export const IS_FREEMIUM = 'IS_FREEMIUM';
export const KEYCLOAK_REALM = 'KEYCLOAK_REALM';
export const CURRENT_REALM_NAME = 'CURRENT_REALM_NAME';
export const TOKEN = 'TOKEN';
export const REGISTRATION_TYPE = 'REGISTRATION_TYPE';
export const IS_DATA_PARTNER_REGISTRATION_IN_PROGRESS = 'IS_DATA_PARTNER_REGISTRATION_IN_PROGRESS';
export const IS_PREMIUM_REGISTRATION_IN_PROGRESS = 'IS_PREMIUM_REGISTRATION_IN_PROGRESS';
export const IS_REGISTRATION_IN_PROGRESS = 'IS_REGISTRATION_IN_PROGRESS';
