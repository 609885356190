{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "DIH - Organization Registration",
    "dihSubTitle": "Extract Value From Data Securely",
    "profile": "Profile",
    "logout": "Logout"
  },
  "sideMenu": {
    "dashboard": "Dashboard",
    "connectors": "Connectors",
    "myConnectors": "My connectors",
    "dataspaces": "Dataspaces",
    "myDataspaces": "My memberships",
    "browseDataspaces": "Browse data spaces",
    "organizations": "Organizations",
    "orgProfile": "Organization profile",
    "create": "Create",
    "delete": "Delete",
    "collapse": "Collapse sidebar",
    "getVerified": "Get verified"
  },
  "common": {
    "start": "Start",
    "firstName": "First Name",
    "lastName": "Last Name",
    "email": "Email",
    "phoneNumber": "Phone Number",
    "contactPerson": "Contact Person",
    "welcome": "Welcome",
    "street": "Street",
    "city": "City",
    "country": "Country",
    "number": "Number",
    "next": "Next",
    "prev": "Prev",
    "alreadyAccount": "You already have an account ?",
    "login": "Login",
    "loginNow": "Login now",
    "register": "Register",
    "home": "Home",
    "submit": "Submit",
    "attention": "Attention",
    "desc": "Description",
    "website": "Website",
    "contact": "Contact",
    "details": "Details",
    "listView": "List view",
    "gridView": "Grid view",
    "firstPage": "To the first page",
    "previousPage": "To the previous page",
    "nextPage": "To the next page",
    "lastPage": "To the last page"
  },
  "genericError": "Oh, something went wrong! Please try again.",
  "validation": {
    "fieldRequired": "{field} is required.",
    "fieldNumeric": "The field {field} may contain only numbers.",
    "fieldAlpha": "The field {field} may contain only letters, _ and -.",
    "fieldAlphanumeric": "The field {field} may contain only letters, numbers, _ and -.",
    "fieldNumericDash": "The field {field} may contain only numbers and -.",
    "fieldAlphaDash": "The field {field} may contain only letters and -.",
    "exceptHTMLTagBrackets": "The field {field} must not contain the following characters: ^<>",
    "isMaxLength": "exceeds the maximum length of 10 characters.",
    "emailFormat": "E-mail has wrong format.",
    "fileSizeError": "The file size is bigger than {size} MB.",
    "fileExtensionError": "This file extension is not suitable."
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Imprint",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contact",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Terms & Conditions",
    "privacy": "Privacy Policy",
    "telekom": "T-Systems International GmbH. All rights reserved.",
    "note": "Telekom Data Intelligence Hub – For Business Customers only",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Help",
    "cookieSettings": "Cookie Settings",
    "life": "Life is for sharing"
  },
  "onboarding": {
    "welcome": "Welcome to DIH organization registration",
    "welcomeDIH": "Welcome to Data Intelligence Hub",
    "informationForOrganization": "Your information from the Organization:",
    "orgNameLabel": "Organization Name",
    "orgUnitNameLabel": "Organization Unit Name",
    "emailAddressLabel": "E-mail Address",
    "countryNameLabel": "Country Name",
    "bpnNumberLabel": "BPN Number",
    "contactPersonInfo": "Contact person",
    "fillInfoBelow": "Please fill the info below",
    "orgRegNameLabel": "Registered Name of Organization/Unit",
    "legalNameHint": "Please enter the legal registered name of your organization",
    "addressHeading": "Address of the HQ",
    "PLZCode": "PLZ/Post Code",
    "verifyEmail": "Verify Email",
    "verifyEmailMessage": "Thanks for joining DIH - your organization is already registered within DIH. Please verify your e-mail.",
    "taxId": "TAX Id",
    "commerceRegisterNumber": "Commerce register number",
    "orgRepresentative": {
      "title": "Set up your organization representative account",
      "orgContactPerson": "Organization contact person",
      "whatDefinesYou": "What defines you best?",
      "agreeText": "I confirm that I have read and will adhere to {1} of Data Intelligence Hub ",
      "confirmText": "I approve that my personal data is stored and processed according to <a href='#'>the the Data Privacy Statement</a>"
    },
    "success": "Organization registration successful",
    "underReview": "Your organisation is under verification.",
    "verified": "You have successfully verified your organization",
    "steps": {
      "stepWizard": {
        "orgInfo": "Organization info",
        "selectConnection": "Select your connection",
        "selectPackage": "Select your plan",
        "checkout": "Checkout"
      },
      "actionButton": {
        "back": "Back",
        "next": "Next",
        "order": "Order with obligation to pay",
        "checkout": "Checkout"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Organization details",
          "dataSpaceAttributes": "Additional attributes",
          "orgName": "Organization legal name",
          "departmentName": "Department name",
          "crnNumber": "Commerce register number",
          "taxId": "TAX Id",
          "bpnNo": "Business Partner Number (BPN)",
          "addressInfo": "Billing address",
          "countryName": "Country name",
          "plzCode": "Post code",
          "number": "Number",
          "city": "City",
          "street": "Street",
          "contactPersonLabel": "Contact person",
          "fullName": "Full name",
          "contactPerson": "Contact person",
          "phoneNo": "Phone number",
          "emailAdd": "E-mail address",
          "dataPrivacyCheck": "I approve that my personal data is stored and processed according to the {dataprivacystatement}",
          "termsAndConditionsCheck": "I confirm that I have read and will adhere to the {termsandcondition} of Data Intelligence Hub",
          "dataprivacystatement": "Data Privacy Statement",
          "termsandcondition": "Terms & Conditions"
        }
      },
      "connection": {
        "sectionHeading": "Select your connection",
        "sectionSubHeading": "Please select your connection type for the {dsName} dataspace",
        "textNote": "Only options that are compatible with Catena-X can be selected"
      },
      "packages": {
        "sectionHeading": "Select your plan",
        "sectionSubHeading": "Data plan variations",
        "dataPackage": "Data package",
        "outgoindTraffic": "Outgoing Traffic",
        "storage": "Storage"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Cloud infrastructure"
      },
      "checkout": {
        "sectionHeading": "Your connection package to {orgName}",
        "setupCost": "Setup Cost",
        "monthlyCost": "Monthly Costs",
        "payg": "Pay-as-you-go",
        "pipSheet": "Product Information Sheet",
        "totalCost": "Total Cost",
        "totalPayable": "Total payable",
        "dataPrivacyCheck": "I agree to pay the costs associated with the services ordered above",
        "termsAndConditionsCheck": "I agree to the contract duration and the cancellation policies",
        "termsandcondition": "Terms & Conditions",
        "EOMAmountPolicy": "I accept that the total amount payable will be calculated at the end of the month",
        "generalAgreement": "I agree to enter a contract with T-Systems International GmbH based on above service description",
        "DPAAgreement": "I consent to the {dataprocessingagreement} (DPA)",
        "dataprocessingagreement": "Data Processing Agreement",
        "dataPackage": "Data package",
        "dataPlan": "Data Plan",
        "outgoindTraffic": "Outgoing Traffic",
        "storage": "Storage",
        "linksText": {
          "information": "Information Sheet",
          "service": "Service Description"
        }
      }
    },
    "statuses": {
      "complete": {
        "title": "You have already registered",
        "description": "The registration link cannot be used another time.",
        "buttonText": "Go to DIH home"
      },
      "error": {
        "title": "Something went wrong",
        "description": "Please restart the process",
        "buttonText": "Restart"
      },
      "expired": {
        "title": "Unfortunately your link is expired",
        "description": "Please restart the process from Catena-X portal.",
        "buttonText": "Back to Catena-X"
      },
      "success": {
        "title": "Thank you for the registration",
        "description": "We’re reviewing your organization details. <br/> You’ll get for the information by e-mail.",
        "buttonText": "Go to our website"
      },
      "invalid": {
        "title": "Unauthorized",
        "description": "You are not authorized to access this page.",
        "buttonText": "Go to our website"
      },
      "dih-success": {
        "title": "Thank you for your registration",
        "description": "We are reviewing your organization's details. <br/> You will receive further information by e-mail",
        "buttonText": "Go to our website"
      }
    }
  },
  "dashboard": {
    "pageTitle": "DIH Home",
    "pageSubtitle": "This is your home control center for everything within DIH."
  },
  "services": {
    "finishConfig": "Finish configuration",
    "open": "Open",
    "viewDetails": "View details"
  },
  "dataspaces": {
    "dataspaces": "Data Spaces",
    "viewAllDataspaces": "View All Dataspaces",
    "configure": {
      "pageTitle": "Configure Data Space connection",
      "basicInfo": "Basic Info",
      "certificate": "Certificate",
      "instructionCertrificate": "Please upload the certificate you received from the DS Owner",
      "save": "Save"
    },
    "browse": {
      "browseDataspace": "Browse Data spaces",
      "availableDS": "Available data spaces",
      "howToConnect": "How to connect",
      "getConnected": "Get Connected",
      "viewDetails": "View Details"
    },
    "user": {
      "myMemberships": "My memberships",
      "enterDS": "Enter data space",
      "registeredDS": "Your data spaces"
    },
    "statuses": {
      "attentionRequired": "Attention Required",
      "comingSoon": "Coming Soon",
      "connected": "Connected",
      "disconnected": "Disconnected",
      "deploying": "Deploying",
      "deployed": "Deployed",
      "failed": "Failed",
      "available": "Available",
      "success": "Success"
    }
  },
  "organization": {
    "org": "Organizations",
    "orgProfile": "Organization Profile",
    "legalName": "Legal Name",
    "address": "Address",
    "contact": "Contact",
    "vatID": "VAT ID",
    "requestChange": "Request a change",
    "verifiableCredentials": {
      "getVerified": "Get verified",
      "credentials": "Credentials",
      "requestCredential": "Request credential",
      "form": {
        "typePlaceholder": "Choose your type",
        "typeLabel": "Choose your type",
        "legalName": {
          "label": "Legal name of organization",
          "helpText": "Legally binding name"
        },
        "country": {
          "placeholder": "Select country",
          "label": "Country"
        },
        "region": {
          "placeholder": "Select region",
          "label": "Region"
        },
        "street": {
          "label": "Street & building"
        },
        "city": {
          "label": "City"
        },
        "postalCode": {
          "label": "Postal code"
        },
        "CRN": {
          "label": "Commercial Registration Number",
          "helpText": "HRB 56930",
          "tooltipText": "The Commercial Registration Number (CRN) is a unique identifier assigned to companies for their legal commercial registration. It is used to validate and verify the authenticity of a company's registration and financial transaction identifiers against trusted data sources."
        },
        "vatId": {
          "label": "VAT ID",
          "helpText": "DE 857 038 540",
          "tooltipText": "A VAT ID (Value-Added Tax Identification number) is a unique identifier assigned to businesses for tax purposes."
        },
        "eori": {
          "label": "EORI",
          "helpText": "DE 103 280 493 572",
          "tooltipText": "EORI (Economic Operator's Registration and Identification number) is a unique identifier assigned to economic operators engaged in international trade within the European Union (EU)."
        },
        "lei": {
          "label": "LEI",
          "helpText": "5299009D9BIL4D4UHT93",
          "tooltipText": "LEI (Legal Entity Identifier) is a unique identification code assigned to legal entities engaging in financial transactions involved in global financial markets."
        },
        "did": {
          "label": "Credential subject (DID)",
          "info": "If you have no DID please keep this field empty, it will be generated automatically."
        },
        "request": "Request",
        "consentPersonalData": "I hereby agree and confirm that all my personal information and data (‘personal data’) stated above are accurate.",
        "consentProcessing": "I hereby consent and authorise Gaia-X to process any of my data (including any personal sensitive data)."
      },
      "vcRequestSuccess": "Successfully signed VC",
      "selectAllButton": "Select All",
      "deselectAllButton": "Deselect All",
      "revokeButton": "Revoke",
      "searchboxPlaceholder": "Enter at least 3 characters",
      "credentialTile": {
        "revokeButton": "Revoke",
        "issuedBy": "Issued by",
        "issuedOn": "Issued on",
        "expiresOn": "Expires on",
        "expiredOn": "Expired on",
        "viewDetailsButton": "View Details"
      },
      "revokeModal": {
        "heading": "Revoke Confirmation",
        "message": "Are you sure you want to revoke the item?",
        "primaryButton": "Revoke",
        "secondaryButton": "Cancel"
      },
      "notificationText":"Sorry, we couldn't find any results matching your search criteria. Please refine your search and try again."
    }
  },
  "profile": {
    "profile": "Profile",
    "userProfile": "User Profile",
    "personalInfo": "Personal Info",
    "accountSecurity": "Account Security",
    "yourInfomration": "Your Information",
    "fullName": "Full Name",
    "contact": "Contact",
    "language": "Language",
    "changePassword": "Change Password"
  },
  "plansBenefits": {
    "communicationIntegrity": "Communication Integrity",
    "protectedConnection": "Protected Connection",
    "mutualAuth": "Mutual authentication",
    "stateArtOfCrypto": "State art of the cryptography",
    "remoteAttestation": "Remote Attestation",
    "platforInt": "Platform Integrity",
    "configureAndApp": "Configuration & app integration",
    "dataUsageControl": "Data usage control",
    "definitionOfUsagePolicy": "Defenition of usage policy",
    "from": "from",
    "month": "month",
    "connectStarter": {
      "edc": "Eclipse Dataspace Connector",
      "gaixComplaint": "GAIA-X compliant / IDSA certified",
      "basicUsageControl": "Basic data usage control",
      "uptoUser": "Up to 5 users",
      "simpleDataUpload": "Simple data upload with 1 adapter",
      "outgoingTraffic": "Up to 1 GB outgoing traffic",
      "dataStorage": "Up to 100 GB data storage"
    },
    "connectComfort": {
      "everytingInConnectStarter": "Everything in Connect Starter",
      "advUsageControl": "Advanced data usage control",
      "uptoUser": "Up to 200 users",
      "comfortDataUpload": "Comfort data upload with 150+ adapters",
      "cloudSubscription": "Bring your own cloud subscription",
      "e2eSovereignity": "E-2-E data sovereignty with Google Sovereign Cloud",
      "outgoingTraffic": "Up to 100 GB outgoing traffic",
      "dataStorage": "Up to 1 TB data storage"
    },
    "connectChoice": {
      "easySetup": "Easy & quick automated setup for Catena-X network",
      "edc": "Eclipse Dataspace Connector",
      "sde": "Simple Data Exchanger for Catena-X network",
      "gaixComplaint": "GAIA-X compliant / IDSA certified",
      "basicUsageControl": "Basic data usage control",
      "dataStorage": "Unlimited data storage",
      "enhancedService": "Enhanced service by Telekom"
    }
  },
  "cookieSettings": {
    "collapsedTitle": "Privacy Settings",
    "collapsedText": "This website uses cookies and similar technologies. These are small text files that are stored and read on your computer. By clicking on '{0}', you accept the processing, the creation of individual user profiles across websites and partners, and the transfer of your data to third parties, some of whom process your data in countries outside the European Union (GDPR Art. 49). Details can be found in section'2 of the {1}. The data is used for analysis, retargeting and for playing out personalized content and advertising on Telekom sites and third-party sites. Further information, including information on data processing by third-party providers and the possibility of revocation, can be found in the settings and in our privacy information. Here you can {2} only with the necessary tools.",
    "extendedTitle": "Privacy Settings",
    "extendedText": "In order to provide you with an optimal website experience, we use cookies. These include cookies for the operation and optimization of the site as well as for services such as text or video chat and for advertising based on your online usage behavior. This allows us, for example, to detect if you visit our pages repeatedly from the same device. We would like to give you the choice which cookies you allow:",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "privacy information",
    "policyLinkText": "Privacy information",
    "rejectLinkText": "continue",
    "acceptButton": "Accept all",
    "saveButton": "Save settings",
    "settingsButton": "Change settings",
    "showMore": "Show more",
    "showLess": "Show less",
    "categoryTitleEssential": "Required cookies",
    "categoryTitleAnalytics": "Analytical cookies",
    "categoryTitleMarketing": "Marketing cookies",
    "categoryTitleThirdParty": "Services by other companies (independent third party providers)",
    "categoryTextEssential": "These cookies are required to enable you to navigate through the websites and use key functions. They support basic functions, such as order processing in the online shop and access to secured areas of the web page. They also serve the purpose of performing an anonymous analysis of user patterns, which we use to continuously develop and improve our web pages for you.",
    "categoryTextAnalytics": "These cookies help us to improve our understanding of user behavior. Analysis cookies allow for the compilation of usage and identification data by the original provider or third party providers into pseudonymous usage profiles. We use analysis cookies e.g. to determine the number of individual visitors to a web page or a service, to collect statistical data on the performance of our products and to analyze the visitors' usage patterns and visitor interactions on the basis of anonymous and pseudonymous information. This information cannot be traced back to a person.",
    "categoryTextMarketing": "These cookies and similar technologies are used to enable the display of personalized and therefore relevant marketing content. Marketing cookies are used to display interesting advertising content and to measure the effectiveness of our campaigns. This happens not only on Telekom websites, but also on other advertising partner sites (third party providers). This is also called retargeting, It is used to create pseudonymous content or ad profiles, to the placement of relevant advertising on other websites and to derive insights into target groups that have viewed the ads and content. This information cannot be traced back to a person. Marketing and retargeting tools assist us in serving you advertising content that is potentially relevant for you. By suppressing marketing cookies, you will still see the same amount of advertising, but it may be less relevant to you.",
    "categoryTextThirdParty": "Telekom's websites include links to third party service providers, who provide their services under their own responsibility. When you visit Telekom’s web pages, cookies or similar technologies record data and send it to third parties, in part for Telekom’s own purposes. The scope, purpose and legal basis on which further processing is carried out for the third party’s own purposes can be found in the third party’s data privacy information. Information on the independent third party providers can be found in the data privacy information."
  },
  "notifications": {
    "notifications": "Notifications",
    "inbox": "Inbox",
    "clearAll": "Clear all",
    "blankState": {
      "heading": "No notifications yet",
      "description": "When you get notifications, they'll show up here",
      "actionButtonText": "Refresh"
    }
  },
  "statusTile": {
    "noMembership": {
      "title": "You have no membership yet",
      "subTitle": "Browse data spaces to join one",
      "getConnected": "Get Connected"
    }
  }
}