export const filterOptions = [
    {
        text: 'All',
        value: 'all',
    },
    {
        text: 'Valid',
        value: 'valid',
    },
    {
        text: 'Expired',
        value: 'expired',
    }
];

export const VCtypes: any = {
    legalPersonVCs: 'Company Identification',
    // will be updated as per future updates
    serviceOffering: 'Compliance and Certification',
    memberships: 'Memberships',
};

export const pageSize = 20;

export enum enumCredentialStatus {
    VALID = 'valid',
    PENDING = 'pending',
    EXPIRED = 'expired'
};