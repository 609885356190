<template>
  <div class="t-loading-bar">
    <div
      :class="{'is-loading': isLoading}"
      class="t-loading-bar-progress"/>
    <transition name="opacity">
      <div
        v-if="hasOverlay && isLoading"
        class="t-loading-bar-overlay"/>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      hasOverlay: {
        type: Boolean,
        default: false
      }
    }
  });
</script>

