import {ActionTree, Commit} from 'vuex';
import {manageOnboardingInt, NewOrgRequestInt, orgFormField} from './OrgOnboardingInt';
import {ONBOARDING_BUY_PLAN, SEND_ORGANIZATION_REQUEST} from './action-types';
import {RESET_FORM, SET_IS_REQUEST_SUCCESS, SET_IS_SUBMIT_INPROGRESS} from './mutation-types'
import OrganizationService from '@/common/services/OrganizationServices';
import {ADD_ERROR_NOTIFICATION} from '@/components/NotificationBar/store/mutation-types';
import i18n from "@/i18n";

function createPayload(organizationInfo: orgFormField, orgId: string) {
  const {
    orgnaizationName,
    orgAddress,
    taxId,
    orgContact,
    commerceRegisterNumber,
    bpnNumber,
    orgnaizationUnitName
  } = organizationInfo;
  const payload: NewOrgRequestInt = {
    legalName: orgnaizationName || '',
    address: {
      street: orgAddress.street || '',
      zip: `${orgAddress.postCode || ''}`,
      city: orgAddress.city || '',
      number: `${orgAddress.number || ''}`,
      country: orgAddress.country || ''
    },
    taxId: taxId || '',
    contactPerson: {
      firstName: orgContact.name || '',
      lastName: '',
      email: orgContact.email || '',
      phone: `${orgContact.phoneNumber}` || '',
      title: '',
      language: i18n.global.locale.value.toUpperCase()
    },
    crnNumber: commerceRegisterNumber || '',
    bpnNumber: `${bpnNumber || ''}`,
    unitName: orgnaizationUnitName || ''
  };
  
  if (orgId && orgId.length) {
    payload['onboardReqId'] = orgId;
  } else {
    payload['isDihUser'] = true;
  }
  
  return payload;
}

function handleResponse(commit: Commit, res: any) {
  const {status} = res;
  if (status !== 201) {
    commit(ADD_ERROR_NOTIFICATION, 'genericError');
    throw new Error('error');
  } else {
    commit(SET_IS_REQUEST_SUCCESS, true);
    commit(RESET_FORM);
  }
}

function handleError(commit: Commit, err: any, orgId: any) {
  const {response} = err;
  if (response.status == 409) {
    if (!(orgId && orgId.length)) {
      commit(ADD_ERROR_NOTIFICATION, response.data);
    }
    throw new Error('proceed');
  } else {
    commit(ADD_ERROR_NOTIFICATION, response.data);
    throw new Error('error');
  }
}

export const actions: ActionTree<manageOnboardingInt, {}> = {
  /**
   * Dispatch the post request to create a new organization
   * @param param0
   */
  [SEND_ORGANIZATION_REQUEST]({commit, state}, orgId: string) {
    const {organizationInfo} = state;
    //request payload
    const payload = createPayload(organizationInfo, orgId);
    commit(SET_IS_SUBMIT_INPROGRESS, true);
    return new Promise((resolve, reject) => {
      OrganizationService.registerNewOrganization(payload)
        .then((res) => {
          handleResponse(commit, res);
          resolve('success');
        })
        .catch((err) => {
          handleError(commit, err, orgId);
          reject('error');
        })
        .finally(() => {
          commit(SET_IS_SUBMIT_INPROGRESS, false);
        });
    });
  },
  
  /**
   * Dispatch post request for buy plan
   * @payload: object : { deviceID: string, planId: string, my dataspace id
   */
  [ONBOARDING_BUY_PLAN]({commit}, payload) {
    return new Promise((resolve, reject) => {
      OrganizationService.checkoutPlan(payload)
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject('error')
        });
    });
  }
}