/**
 * This service is use for dataspaces call
 */

import axios from 'axios';
import Util from '../util';

export default class DataspaceService {
  /**
  * Get all dataspaces
  * @returns Array
  */
  static getAllDataspaces() {
    return axios.get(Util.getRestApiUrl('dataspace', 'dataspace'));
  }

  /**
  * Join Dataspace
  * @params: Object
  */
  static joinDataspace(requestPayload: {
    id: string | string[];
    payload: {
      message: string;
    }
  }) {
    const { payload, id} = requestPayload;
    return axios.post(Util.getRestApiUrl(`dataspace/${id}/apply`, 'dataspace'), payload)
  }

  /**
   * Get user's joined dataspace 
   */

  static myDataspaces() {
    return axios.get(Util.getRestApiUrl('my/dataspaces', 'dataspace'));
  }

  /**
   * Configure dataspace with connector certificate
   */
  static configureDataspace(requestPayload: {
    id: string | string[],
    payload: {
      publicKey: {
        file: File
      },
      privateKey: {
        file: File
      }
    }
  }) {
    const {id, payload} = requestPayload;
    const uploadData = new FormData();
    uploadData.append('publicKey', payload.publicKey.file);
    uploadData.append('privateKey', payload.privateKey.file);

    return axios.post(Util.getRestApiUrl(`my/dataspaces/${id}/uploadCertificate`, 'dataspace'), uploadData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  /**
   * Configure dataspace service 
   */
  static configureDataspaceService(requestPayload: {
    myDataspaceId: string,
    serviceId: string,
    certificate: File
  }) {
    const uploadData = new FormData();
    uploadData.append('certificate', requestPayload.certificate);
    return axios.post(Util.getRestApiUrl(`my/dataspaces/${requestPayload.myDataspaceId}/uploadCertificate`, 'dataspace'), uploadData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        serviceId: requestPayload.serviceId
      }
    });
  }

  /**
   * 
   * @param id Get the detail of dataspace
   * @returns Object
   */
  static getDataspaceDetails(id: string | string[]) {
    return axios.get(Util.getRestApiUrl(`dataspace/${id}`, 'dataspace'));
  }

  /**
   * Get the details of user dataspace
   * @return Object
   */
  static getMyDataspaceDetails(id: string | string[]) {
    return axios.get(Util.getRestApiUrl(`my/dataspaces/${id}`, 'dataspace'));
  }

  /**
   *  Check the status of deploying dataspace
   *  @params: Array of my dataspace ids
   */
  static checkDSStatus(payload: string[]) {
    const reqPayload = {
      'myDataspaceIds': payload
    }
    return axios.post(Util.getRestApiUrl(`my/dataspaces/status`, 'dataspace'), reqPayload);
  }
}
