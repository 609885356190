import axios from 'axios';
import store from '@/store';
import {HANDLE_TOKEN_EXPIRY} from '@/store/modules/auth/action-types';
import {UPDATE_SESSION_EXPIRY_TIME} from '@/store/modules/auth/mutation-types';
import {TOKEN} from '@/store/modules/auth/getter-types';

axios.interceptors.response.use(
  response => {
    store.commit(UPDATE_SESSION_EXPIRY_TIME);
    return response;
  },
  error => {
    if (error.response && error.response.status === 401 && store.getters[TOKEN]) {
      store.dispatch(HANDLE_TOKEN_EXPIRY);
    }
    return Promise.reject(error);
  });
