import {Module} from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';
import {manageOnboardingInt} from './OrgOnboardingInt';


export const getDefaultForm = () => {
  return [{
    title: 'onboarding.information',
    fields: {
      organizationInfo: {
        orgnaizationName: '',
        orgnaizationUnitName: '',
        commerceRegisterNumber: '',
        taxId: '',
        bpnNumber: '',
        orgAddress: {
          country: '',
          postCode: '',
          number: '',
          city: '',
          street: '',
        },
        orgContact: {
          name: '',
          phoneNumber: '',
          email: ''
        }
      }
    },
    isInvalid: true
  }
  ]
}
const getDefaultState = (): manageOnboardingInt => {
  return {
    activeStep: 0,
    isSubmitInprogress: false,
    steps: getDefaultForm(),
    organizationInfo: {
      orgnaizationName: "",
      orgnaizationUnitName: "",
      commerceRegisterNumber: "",
      taxId: "",
      bpnNumber: "",
      orgAddress: {
        country: "",
        postCode: "",
        number: "",
        city: "",
        street: "",
      },
      orgContact: {
        name: "",
        phoneNumber: "",
        email: "",
      },
    },
    isReqSuccess: false,
    countries: [],
    organisationPreFilledData: null,
    readOnlyOrgInfo: {},
  };
}
const INITIAL_STATE = getDefaultState();

const manageOrgOnboardingState: Module<manageOnboardingInt, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};

export default manageOrgOnboardingState;