import {Module} from 'vuex';
import {LOADING_STATE, PREVENT_LOADING} from './getter-types';
import {DECREMENT_LOADING_STATE, INCREMENT_LOADING_STATE, SET_PREVENT_LOADING} from './mutation-types';

interface ILoadingState {
  preventLoading: boolean;
  loading: string[];
}

const loadingState: Module<ILoadingState, {}> = {
  state: {
    preventLoading: false,
    loading: []
  },
  mutations: {
    [INCREMENT_LOADING_STATE](state, loading: string) {
      state.loading.push(loading);
    },
    [DECREMENT_LOADING_STATE](state) {
      state.loading.pop();
    },
    [SET_PREVENT_LOADING](state, value: boolean) {
      state.preventLoading = value;
    }
  },
  getters: {
    [LOADING_STATE]: state => state.loading.length > 0,
    [PREVENT_LOADING]: state => state.preventLoading
  }
};

export default loadingState;
