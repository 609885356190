import { Module } from 'vuex';
import { pageSize } from '@/common/constants/getVerifiedConstants';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import {
  IOrgProfileState
} from './IntOrgProfileState';

// initial state
const getDefaultState = () => {
  return {
    carlaKeycloakToken: "",
    isProgress: false,
    orgProfileInfo: null,
    requestChangeInProgress: false,
    requestInfo: {
      type: "",
      legalName: "",
      country: "",
      street: "",
      city: "",
      postalCode: "",
      vatid: "",
      eori: "",
      lei: "",
      local: "",
      did: "",
      exceptProfile: false
    },
    activeSteps: {
      companyInfo: false,
      uniqueId: false,
      did: false
    },
    VCFilterParams: {
      filterParam: "",
      page: { pageStart: 0, pageSize: pageSize },
      searchKeyword: "",
    },
    VCDetails: {
      content: [
        {
          legalPersonVCs: []
        }
      ],
    },
    fetchVCDetailsInProgress: false,
    appendVCs: false,
  }
}
const INITIAL_STATE = getDefaultState();

// org profile store
const OrgProfileState: Module<IOrgProfileState, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};

export default OrgProfileState;
