import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

export default class Util {
  /**
   * Get REST API URL
   * @param {string} url part to get full URL
   * @param {number} version Endpoint versions
   * @returns {string} full REST API URL
  */
  static getRestApiUrl(url: string, type: string) {
    let API_ENDPOINT = '';
    switch(type) {
      case 'org': 
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_ORG_USER_URL}`
        break;
      case 'dataspace': 
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_DATASPACE_URL}`;
        break;
      case 'plan':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_PLAN_URL}`;
        break;
      case 'cx-bridge':
        API_ENDPOINT = `${import.meta.env.VITE_APP_CX_BRIDGE_URL}`;
        break;
      case 'notification':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_NOTIFICATION_URL}`;
        break;
      case 'carla':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_VC_REQUEST_URL}`;
        break;
      case 'carla-revoke':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_VC_REVOKE_URL}`;
        break;
      case 'carla-revoke-check':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_VC_REVOKE_CHECK_URL}`;
        break;
      case 'carlaKeycloak':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_CARLA_KEYCLOAK_URL}`;
        break;
    }
    return `${API_ENDPOINT}/${url}`;
  }

  /**
   * Create two way mapper Getter <-> Mutation for computed property
   * @param {string} getter
   * @param {string} mutation
   * @returns {any} Mapper for computed property
   */
   static mapTwoWay<T>(getter: string, mutation: string) {
    return {
      get(): T {
        return store.getters[getter];
      },
      set(value: T) {
        store.commit(mutation, value);
      }
    };
  }

  public static generateUUID() {
    return uuidv4();
  }

  /**
   * Sort by field function
   * @param {string} field Field to sort for
   * @param {boolean} reverse Reverse sorting direction
   * @param {boolean} ignoreCase case for strings
   * @returns {(a: {[p: string]: {}}, b: {[p: string]: {}}) => (number | number)} Sort function
   */
  static sortByField<T extends object>(field: keyof T, reverse = false, ignoreCase = false) {
    return (a: T, b: T) => {
      const aField = ignoreCase ? String(a[field]).toLowerCase() : a[field];
      const bField = ignoreCase ? String(b[field]).toLowerCase() : b[field];

      return reverse ?
        Util.compareValues(aField, bField) :
        Util.compareValues(bField, aField);
    };
  }
  
  private static compareValues(a: any, b: any): number {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }
  
  // window scroll to top
  static scrollToTop(): void {
    window.scroll(0,0);
  }
}