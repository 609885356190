import { Module } from 'vuex';
import {
} from './getter-types';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import {
  IntNotificationState
} from './IntNotificationState';

// initial state
const getDefaultState = (): IntNotificationState => {
  return {
    notifications: []
  }
}
const INITIAL_STATE = getDefaultState();

// user notifications store
const NotificationStore: Module<IntNotificationState, {}> = {
  namespaced: true,
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};

export default NotificationStore;
