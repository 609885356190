/**
 * This service is use for user profile
 */

 import axios from 'axios';
 import Util from '../util';

 export default class UserService {
    /**
    * Get user profile
    * @returns object
    */
    static getUserInfo() {
      return axios.get(Util.getRestApiUrl('user', 'org'));
    }
    /**
     * Get loggedin user's org plans
     * @returns
     */
    static getUserPlans() {
      return axios.get(Util.getRestApiUrl('orgDevicePlan', 'plan'));
    }
 }