import {Module} from 'vuex';
import {INotification, NotificationType} from '../../notifications/INotification';
import {NOTIFICATION_LIST} from './getter-types';
import {
  ADD_SUCCESS_NOTIFICATION,
  UPDATE_NOTIFICATION_LIST,
  ADD_ERROR_NOTIFICATION,
  ADD_HINT_NOTIFICATION,
  ADD_WARNING_NOTIFICATION
} from './mutation-types';
import Util from '@/common/util';

interface INotificationsState {
  notifications: INotification[];
}

type RawNotification = string | INotification;

function getNotification(notification: RawNotification): INotification {
  const id = Util.generateUUID();
  return typeof notification === 'object' ?
    {id, ...notification} :
    {id, text: notification};
}

const notificationsState: Module<INotificationsState, {}> = {
  state: {
    notifications: []
  },
  getters: {
    [NOTIFICATION_LIST]: state => state.notifications
  },
  mutations: {
    [ADD_SUCCESS_NOTIFICATION](state, notification: RawNotification) {
      state.notifications.unshift({...getNotification(notification), type: NotificationType.SUCCESS});
    },
    [ADD_ERROR_NOTIFICATION](state, notification: RawNotification) {
      state.notifications.unshift({...getNotification(notification), type: NotificationType.ERROR});
    },
    [ADD_HINT_NOTIFICATION](state, notification: RawNotification) {
      state.notifications.unshift({...getNotification(notification), type: NotificationType.HINT});
    },
    [ADD_WARNING_NOTIFICATION](state, notification: RawNotification) {
      state.notifications.unshift({...getNotification(notification), type: NotificationType.WARNING});
    },
    [UPDATE_NOTIFICATION_LIST](state, value: INotification[]) {
      state.notifications = value;
    }
  }
};

export default notificationsState;
