{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "DIH - Organisationsregistrierung",
    "dihSubTitle": "Wert sicher aus Daten extrahieren",
    "profile": "Profil",
    "logout": "Abmelden"
  },
  "sideMenu": {
    "dashboard": "Dashboard",
    "connectors": "Anschlüsse",
    "myConnectors": "Meine Anschlüsse",
    "dataspaces": "Datenräume",
    "myDataspaces": "Meine Mitgliedschaften",
    "browseDataspaces": "Datenräume durchsuchen",
    "organizations": "Organisationen",
    "orgProfile": "Organisationsprofil",
    "create": "Erstellen",
    "delete": "Löschen",
    "collapse": "Seitenleiste einklappen",
    "getVerified": "Get verified"
  },
  "common": {
    "start": "Start",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "email": "Email",
    "phoneNumber": "Telefonnummer",
    "contactPerson": "Kontakt",
    "welcome": "Willkommen",
    "street": "Straße",
    "city": "Stadt",
    "country": "Land",
    "number": "Nummer",
    "next": "Nächste",
    "prev": "Vorherige",
    "alreadyAccount": "Haben Sie schon ein Konto?",
    "login": "Anmelden",
    "loginNow": "Jetzt anmelden",
    "register": "Registrieren",
    "home": "StartSeite",
    "submit": "Senden",
    "attention": "Achtung",
    "desc": "Beschreibung",
    "website": "Webseite",
    "contact": "Kontakt",
    "details": "Details",
    "listView": "Listenansicht",
    "gridView": "Rasteransicht",
    "firstPage": "Zur ersten Seite",
    "previousPage": "Zur vorigen Seite",
    "nextPage": "Zur nächsten Seite",
    "lastPage": "Zur letzten Seite"
  },
  "genericError": "Ach, da ist was schief gelaufen! Bitte erneut versuchen.",
  "validation": {
    "fieldRequired": "{field} erforderlich.",
    "fieldNumeric": "Das Feld {field} darf nur Zahlen enthalten",
    "fieldAlpha": "Das Feld {field} darf nur Buchstaben enthalten, _ und -",
    "fieldAlphanumeric": "Das Feld {field} darf nur Buchstaben, Zahlen enthalten _ und -",
    "fieldNumericDash": "Das Feld {field} darf nur Zahlen enthalten und -",
    "fieldAlphaDash": "Das Feld {field} darf nur Buchstaben enthalten und -",
    "exceptHTMLTagBrackets": "Das Feld {field} darf die folgenden Zeichen nicht enthalten: ^<>",
    "isMaxLength": "überschreitet die maximale Länge von zehn Zeichen.",
    "emailFormat": "E-Mail hat falsches Format.",
    "fileSizeError": "Die Dateigröße ist größer als {size} MB.",
    "fileExtensionError": "Diese Dateiendung ist nicht geeignet."
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Impressum",
    "imprintLink": "https://dih.telekom.com/de/impressum",
    "contact": "Kontakt",
    "contactLink": "https://dih.telekom.com/de/kontakt",
    "termsAndConditions": "AGB",
    "privacy": "Datenschutz",
    "telekom": "T-Systems International GmbH. Alle Rechte vorbehalten.",
    "note": "Telekom Data Intelligence Hub – Für Geschäftskunden",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Hilfe",
    "cookieSettings": "Cookie-Einstellungen",
    "life": "Erleben, was verbindet."
  },
  "onboarding": {
    "welcome": "Willkommen bei der Registrierung der DIH-Organisation",
    "welcomeDIH": "Willkommen bei Data Intelligence Hub",
    "informationForOrganization": "Ihre Informationen von der Organisation:",
    "orgRegNameLabel": "Registierte Name der Organisation/Einheit",
    "legalNameHint": "Bitte geben Sie die rechtsgültige Bezeichnung ihrer Organisation an",
    "addressHeading": "Adresse der Hauptstandort",
    "orgNameLabel": "Organisationsname",
    "orgUnitNameLabel": "Name der Organisationseinheit",
    "emailAddressLabel": "E-Mail-Addresse",
    "countryNameLabel": "Ländername",
    "bpnNumberLabel": "Geschäftspartner-Nummer",
    "contactPersonInfo": "Ansprechspartner",
    "fillInfoBelow": "Bitte füllen Sie die Informationen unten aus",
    "PLZCode": "PLZ/Postleitzahl",
    "verifyEmail": "E-Mail bestätigen",
    "verifyEmailMessage": "Vielen Dank für Ihre Registration bei DIH – Ihre Organisation ist bereits beim DIH registriert. Bitte überprüfen Sie Ihre E-Mail.",
    "taxId": "Steuernummer",
    "commerceRegisterNumber": "EORI-Nummer",
    "orgRepresentative": {
      "title": "Richten Sie das Konto Ihres Organisationsvertreters ein",
      "orgContactPerson": "Ansprechpartner der Organisation",
      "whatDefinesYou": "Was definiert Sie am besten?",
      "agreeText": "Ich bestätige, dass ich {1} von Data Intelligence Hub gelesen habe und befolgen werde",
      "confirmText": "Ich stimme der Speicherung und Verarbeitung meiner personenbezogenen Daten gemäß <a href='#'>the the Data Privacy Statement</a> zu."
    },
    "success": "Organisationsregistrierung erfolgreich",
    "underReview": "Ihre Organisation wird überprüft.",
    "verified": "Sie haben Ihre Organisation erfolgreich verifiziert",
    "steps": {
      "stepWizard": {
        "orgInfo": "Organisationsinfo",
        "selectConnection": "Wählen Sie Ihre Verbindung aus",
        "selectPackage": "Wählen Sie Ihren Plan",
        "checkout": "Zur Kasse"
      },
      "actionButton": {
        "back": "Zurück",
        "next": "Nächste",
        "order": "Bestellung mit Zahlungsverpflichtung",
        "checkout": "Zur Kasse"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Ihre Informationen von der Organisation",
          "orgName": "Offizieller Name der Organisation",
          "departmentName": "Name der Organisationseinheit",
          "dataSpaceAttributes": "Datenraumattribute",
          "orgUnit": "Name der Organisationseinheit",
          "crnNumber": "Handelsregisternummer",
          "taxId": "Steuer ID",
          "bpnNo": "Registrierungs- und Identifizierungsnummer für Wirtschaftsbeteiligte (EORI)",
          "addressInfo": "Rechnungsadresse",
          "countryName": "Ländername",
          "plzCode": "Postleitzahl",
          "number": "Nummer",
          "city": "Stadt",
          "street": "Strasse",
          "contactPersonLabel": "Ansprechspartner",
          "contactPerson": "Ansprechspartner",
          "fullName": "Vollständiger Name",
          "phoneNo": "Telefonnummerr",
          "emailAdd": "E-Mail-Addresse",
          "dataPrivacyCheck": "Ich stimme der Speicherung und Verarbeitung meiner personenbezogenen Daten gemäß die {dataprivacystatement} zu",
          "termsAndConditionsCheck": "Ich bestätige, dass ich die {termsandcondition} der Data Intelligence Hub gelesen habe und diese einhalten werde",
          "dataprivacystatement": "Datenschutzerklärung",
          "termsandcondition": "Allgemeinen Geschäftsbedingungen"
        }
      },
      "connection": {
        "sectionHeading": "Wählen Sie Ihre Verbindung aus",
        "sectionSubHeading": "Please select your connection type for the {dsName} dataspace",
        "textNote": "Es können nur Optionen ausgewählt werden, die mit Catena-X kompatibel sind"
      },
      "packages": {
        "sectionHeading": "Wählen Sie Ihren Plan",
        "sectionSubHeading": "Variationen der Datenplan",
        "dataPackage": "Datenpacket",
        "outgoindTraffic": "Ausgehender Datenverkehr",
        "storage": "Lagerung"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Cloud Infrastruktur"
      },
      "checkout": {
        "sectionHeading": "Ihr Verbindungspaket zu {orgName}",
        "setupCost": "Einrichtungskosten",
        "monthlyCost": "Monatsgebühr",
        "payg": "Pay-as-you-go",
        "pipSheet": "Produktinformationsblatt",
        "totalCost": "Gesamtkosten",
        "totalPayable": "Gesamtpreis",
        "EOMAmountPolicy": "Ich akzeptiere, dass der zu bezahlende Gesamtbetrag am Ende des Monats berechnet wird",
        "dataPrivacyCheck": "Ich stimme zu, die mit den oben bestellten Dienstleistungen verbundenen Kosten zu übernehmen",
        "termsAndConditionsCheck": "Ich stimme der Vertragslaufzeit und die Stornierungsbedingungen zu",
        "termsandcondition": "Allgemeinen Geschäftsbedingungen",
        "generalAgreement": "Ich stimme dem Abschluss eines Vertrages mit der T-Systems International GmbH auf Grundlage der vorstehenden Leistungsbeschreibung zu",
        "DPAAgreement": "Ich stimme der {dataprocessingagreement} (DPA) zu",
        "dataprocessingagreement": "Datenverarbeitungsvereinbarung",
        "dataPackage": "Datenpacket",
        "dataPlan": "Datenplan",
        "outgoindTraffic": "Ausgehender Datenverkehr",
        "storage": "Lagerung",
        "linksText": {
          "information": "Informationsblatt",
          "service": "Leistungsbeschreibung"
        }
      }
    },
    "statuses": {
      "complete": {
        "title": "Sie haben sich bereits registriert",
        "description": "Der Registrierungslink kann nicht erneut verwendet werden.",
        "buttonText": "Zur DIH Startseite"
      },
      "error": {
        "title": "Etwas ist schief gelaufen",
        "description": "Bitte starten Sie den Vorgang neu",
        "buttonText": "Neu starten"
      },
      "expired": {
        "title": "Leider ist Ihr Link abgelaufen",
        "description": "Bitte starten Sie den Prozess vom Catena-X-Portal neu.",
        "buttonText": "Zurück zu Catena-X"
      },
      "success": {
        "title": "Vielen Dank für die Registrierung",
        "description": "Wir prüfen Ihre Organisationsdetails. <br/> Die Informationen erhalten Sie per E-Mail.",
        "buttonText": "Besuchen Sie unsere Website"
      },
      "invalid": {
        "title": "Nicht berechtigt",
        "description": "Sie sind nicht berechtigt auf diese Seite zuzugreifen.",
        "buttonText": "Besuchen Sie unsere Website"
      },
      "dih-success": {
        "title": "Danke für Ihre Registrierung",
        "description": "Wir prüfen die Details Ihrer Organisation. <br/> Weitere Informationen erhalten Sie per E-Mail",
        "buttonText": "Besuchen Sie unsere Website"
      }
    }
  },
  "dashboard": {
    "pageTitle": "DIH Startseite",
    "pageSubtitle": "Dies ist Ihre Steuerzentrale für alles innerhalb von DIH."
  },
  "services": {
    "finishConfig": "Konfiguration abschließen",
    "open": "Öffnen",
    "viewDetails": "Details anzeigen"
  },
  "dataspaces": {
    "dataspaces": "Datenräume",
    "viewAllDataspaces": "Alle Datenräume anzeigen",
    "configure": {
      "pageTitle": "Datenraumverbindung konfigurieren",
      "basicInfo": "Grundinformationen",
      "certificate": "Zertifikat",
      "instructionCertrificate": "Bitte laden Sie das Zertifikat hoch, das Sie vom DS-Inhaber erhalten haben",
      "save": "Speichern"
    },
    "browse": {
      "browseDataspace": "Datenräume durchsuchen",
      "availableDS": "Verfügbare Datenräume",
      "howToConnect": "So verbinden Sie sich",
      "getConnected": "Verbinden Sie sich",
      "viewDetails": "Details anzeigen"
    },
    "user": {
      "myMemberships": "Meine Mitgliedschaften",
      "enterDS": "Datenraum anmelden",
      "registeredDS": "Ihre Datenräume"
    },
    "statuses": {
      "attentionRequired": "Wir bitten um Ihren Aufmerksamkeit",
      "comingSoon": "Wird bald kommen",
      "connected": "Verbunden",
      "disconnected": "Getrennt",
      "deploying": "In Bereitstellung",
      "deployed": "Bereitgestellt",
      "failed": "Fehlgeschlagen",
      "available": "Verfügbar",
      "success": "Erfolgreich"
    }
  },
  "organization": {
    "orgProfile": "Unternehmensprofil",
    "legalName": "Offizieller Name",
    "address": "Adresse",
    "contact": "Kontakt",
    "vatID": "USt ID",
    "requestChange": "Änderung anfordern",
    "verifiableCredentials": {
      "getVerified": "Get verified",
      "credentials": "Credentials",
      "requestCredential": "Request credential",
      "form": {
        "typePlaceholder": "Choose your type",
        "typeLabel": "Choose your type",
        "legalName": {
          "label": "Legal name of organization",
          "helpText": "Legally binding name"
        },
        "country": {
          "placeholder": "Select country",
          "label": "Country"
        },
        "region": {
          "placeholder": "Select region",
          "label": "Region"
        },
        "street": {
          "label": "Street & building"
        },
        "city": {
          "label": "City"
        },
        "postalCode": {
          "label": "Postal code"
        },
        "CRN": {
          "label": "Commercial Registration Number",
          "helpText": "HRB 56930",
          "tooltipText": "The Commercial Registration Number (CRN) is a unique identifier assigned to companies for their legal commercial registration. It is used to validate and verify the authenticity of a company's registration and financial transaction identifiers against trusted data sources."
        },
        "vatId": {
          "label": "VAT ID",
          "helpText": "DE 857 038 540",
          "tooltipText": "A VAT ID (Value-Added Tax Identification number) is a unique identifier assigned to businesses for tax purposes."
        },
        "eori": {
          "label": "EORI",
          "helpText": "DE 103 280 493 572",
          "tooltipText": "EORI (Economic Operator's Registration and Identification number) is a unique identifier assigned to economic operators engaged in international trade within the European Union (EU)."
        },
        "lei": {
          "label": "LEI",
          "helpText": "5299009D9BIL4D4UHT93",
          "tooltipText": "LEI (Legal Entity Identifier) is a unique identification code assigned to legal entities engaging in financial transactions involved in global financial markets."
        },
        "did": {
          "label": "Credential subject (DID)",
          "info": "If you have no DID please keep this field empty, it will be generated automatically."
        },
        "request": "Request",
        "consentPersonalData": "I hereby agree and confirm that all my personal information and data (‘personal data’) stated above are accurate.",
        "consentProcessing": "I hereby consent and authorise Gaia-X to process any of my data (including any personal sensitive data)."
      },
      "vcRequestSuccess": "Successfully signed VC",
      "selectAllButton": "Select All",
      "deselectAllButton": "Deselect All",
      "revokeButton": "Revoke",
      "searchboxPlaceholder": "Enter at least 3 characters",
      "credentialTile": {
        "revokeButton": "Revoke",
        "issuedBy": "Issued by",
        "issuedOn": "Issued on",
        "expiresOn": "Expires on",
        "expiredOn": "Expired on",
        "viewDetailsButton": "View Details"
      },
      "revokeModal": {
        "heading": "Revoke Confirmation",
        "message": "Are you sure you want to revoke the item?",
        "primaryButton": "Revoke",
        "secondaryButton": "Cancel"
      },
      "notificationText":"Sorry, we couldn't find any results matching your search criteria. Please refine your search and try again."
    }
  },
  "profile": {
    "profile": "Profil",
    "userProfile": "Benutzer Profil",
    "personalInfo": "Persönliche Informationen",
    "accountSecurity": "Konto Sicherheit",
    "yourInfomration": "Ihre Informationen",
    "fullName": "Vollständiger Name",
    "contact": "Kontakt",
    "language": "Sprache",
    "changePassword": "Passwort ändern"
  },
  "plansBenefits": {
    "communicationIntegrity": "Kommunikationsintegrität",
    "protectedConnection": "Geschützte Verbindung",
    "mutualAuth": "Gegenseitige Authentifizierung",
    "stateArtOfCrypto": "Stand der Technik der Kryptographie",
    "remoteAttestation": "Remote-Bestätigung",
    "platforInt": "Plattformintegrität",
    "configureAndApp": "Konfiguration & App-Integration",
    "dataUsageControl": "Kontrolle der Datennutzung",
    "definitionOfUsagePolicy": "Definition der Nutzungsrichtlinie",
    "from": "aus",
    "month": "Monat",
    "connectStarter": {
      "edc": "Eclipse Dataspace Connector",
      "gaixComplaint": "GAIA-X konform / IDSA zertifiziert",
      "basicUsageControl": "Grundlegende Datennutzungskontrolle",
      "uptoUser": "Bis zu 5 Benutzer",
      "simpleDataUpload": "Einfacher Datenupload mit 1 Adapter",
      "outgoingTraffic": "Bis zu 1 GB ausgehender Datenverkehr",
      "dataStorage": "Bis zu 100 GB Datenspeicher"
    },
    "connectComfort": {
      "everytingInConnectStarter": "Alles in Connect Starter",
      "advUsageControl": "Erweiterte Datennutzungskontrolle",
      "uptoUser": "Bis zu 200 Benutzer",
      "comfortDataUpload": "Komfortabler Datenupload mit über 150 Adaptern",
      "cloudSubscription": "Bringen Sie Ihr eigenes Cloud-Abonnement mit",
      "e2eSovereignity": "E-2-E Datenhoheit mit Google Sovereign Cloud",
      "outgoingTraffic": "Bis zu 100 GB ausgehender Datenverkehr",
      "dataStorage": "Bis zu 1 TB Datenspeicher"
    },
    "connectChoice": {
      "easySetup": "Einfache und schnelle automatische Einrichtung für das Catena-X-Netzwerk",
      "edc": "Eclipse Dataspace Connector",
      "sde": "Einfacher Datenaustauscher für das Catena-X-Netzwerk",
      "gaixComplaint": "GAIA-X konform / IDSA zertifiziert",
      "basicUsageControl": "Grundlegende Datennutzungskontrolle",
      "dataStorage": "Unbegrenzter Datenspeicher",
      "enhancedService": "Erweiterter Service der Telekom"
    }
  },
  "cookieSettings": {
    "collapsedTitle": "Datenschutzeinstellungen",
    "collapsedText": "Diese Website verwendet Cookies und ähnliche Technologien. Dies sind kleine Textdateien, die auf Ihrem Computer gespeichert und gelesen werden. Indem Sie auf „{0}“ klicken, akzeptieren Sie die Verarbeitung, die Erstellung von individuellen Benutzerprofilen über Websites und Partner hinweg und die Übermittlung Ihrer Daten an Dritte, die Ihre Daten teilweise in Ländern außerhalb der Europäischen Union verarbeiten (DSGVO Art 49). Weiteres finden Sie in Abschnitt 2 des {1}. Die Daten dienen der Analyse, dem Retargeting und der Ausspielung personalisierter Inhalte und Werbung auf Telekom-Seiten und Drittseiten. Weitere Informationen, auch Informationen zur Datenverarbeitung durch Drittanbieter und die Möglichkeit des Widerrufs, finden Sie in den Einstellungen und in unseren Datenschutzhinweisen. Hier können Sie nur mit den erforderlichen Tools {2}.",
    "extendedTitle": "Verwalten Sie Ihre Datenschutz-Einstellungen",
    "extendedText": "Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen wir Cookies ein. Dazu zählen Cookies für den Betrieb und die Optimierung der Seite als auch für Services, wie die Nutzung des Text- oder Video Chats als auch für an Ihrem online Nutzungsverhalten orientierte Werbung. So kann z.B. erkannt werden, wenn Sie unsere Seiten vom selben Gerät aus wiederholt besuchen. Wir möchten Ihnen die Wahl geben, welche Cookies Sie zulassen:",
    "policyLinkHref": "https://dih.telekom.com/de/datenschutzerklarung",
    "policyLinkCookieHref": "https://dih.telekom.net/datenschutzhinweise/#cookies",
    "policyLinkTextShort": "Datenschutzhinweises",
    "policyLinkText": "Datenschutzhinweis",
    "rejectLinkText": "weiter",
    "acceptButton": "Alle akzeptieren",
    "saveButton": "Auswahl bestätigen",
    "settingsButton": "Einstellungen ändern",
    "showMore": "Mehr erfahren",
    "showLess": "Weniger erfahren",
    "categoryTitleEssential": "Erforderliche Cookies",
    "categoryTitleAnalytics": "Analytische Cookies",
    "categoryTitleMarketing": "Marketing-Cookies",
    "categoryTitleThirdParty": "Dienste von anderen Unternehmen (eigenverantwortliche Drittanbieter)",
    "categoryTextEssential": "Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren und wesentliche Funktionen nutzen können. Sie ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online-Shop und den Zugriff auf gesicherte Bereiche der Webseite. Zudem dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns verwendet werden, um unseren Webauftritt stetig für Sie weiterzuentwickeln.",
    "categoryTextAnalytics": "Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen. Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise Analyse-Cookies, um die Zahl der individuellen Besucher einer Webseite oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick auf den Betrieb unserer Produkte zu erheben, als auch das Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu analysieren, wie Besucher mit der Webseite interagieren. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.",
    "categoryTextMarketing": "Diese Cookies und ähnliche Technologien werden eingesetzt, um Ihnen personalisierte und dadurch relevante werbliche Inhalte anzeigen zu können. Marketing-Cookies werden eingesetzt, um interessante Werbeinhalte anzuzeigen und die Wirksamkeit unserer Kampagnen zu messen. Dies geschieht nicht nur auf Webseiten der Telekom, sondern auch auf anderen Werbepartner-Seiten (Drittanbieter). Dies wird auch als Retargeting bezeichnet. Es dient zur Erstellung pseudonymer Inhalts- oder Anzeigenprofile, der Schaltung relevanter Werbung auf anderen Webseiten und um Erkenntnisse über Zielgruppen, die die Anzeigen und Inhalte betrachtet haben, abzuleiten. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich. Marketing- und Retargeting-Cookies helfen uns mögliche relevanten Werbeinhalte für Sie anzuzeigen. Durch das Unterdrücken von Marketing-Cookies sehen Sie auch weiterhin die gleiche Anzahl an Werbung, die aber möglicherweise weniger relevant für Sie ist.",
    "categoryTextThirdParty": "Auf Seiten der Telekom werden Drittanbieter Dienste eingebunden, die Ihren Services eigenverantwortlich erbringen. Dabei werden beim Besuch von Telekom Seiten Daten mittels Cookies oder ähnlicher Technologien erfasst und an Dritte übermittelt, zum Teil für Telekomeigene Zwecke. In welchem Umfang, zu welchen Zwecken und auf Basis welcher Rechtsgrundlage eine Weiterverarbeitung zu eigenen Zwecken des Drittanbieters erfolgt, entnehmen Sie bitte den Datenschutzhinweisen des Drittanbieters. Die Informationen zu den eigenverantwortlichen Drittanbietern finden Sie in den Datenschutzhinweisen."
  },
  "notifications": {
    "notifications": "Benachrichtigungen",
    "inbox": "Posteingang",
    "clearAll": "Alles löschen",
    "blankState": {
      "heading": "Noch keine Benachrichtigungen",
      "description": "Wenn Sie Benachrichtigungen erhalten, werden diese hier angezeigt",
      "actionButtonText": "Aktualisierung"
    }
  },
  "statusTile": {
    "noMembership": {
      "title": "Sie haben noch keine Mitgliedschaft",
      "subTitle": "Durchsuchen Sie Datenräume, um einem beizutreten",
      "getConnected": "Verbinden Sie sich"
    }
  }
}