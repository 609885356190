import {MutationTree} from 'vuex';
import {
  SET_ACTIVE_STEP,
  SET_STEP_IS_VALID,
  SET_IS_SUBMIT_INPROGRESS,
  SET_IS_REQUEST_SUCCESS,
  SET_COUNTRIES,
  SET_ORG_PRE_FILL_DATA,
  SET_ORG_INFO,
  SET_READONLY_ORG_INFO,
  RESET_FORM,
} from "./mutation-types";
import { getDefaultForm } from "./index";

import { manageOnboardingInt } from "./OrgOnboardingInt";

export const mutations: MutationTree<manageOnboardingInt> = {
  [SET_ORG_INFO](state: manageOnboardingInt, payload) {
    state.organizationInfo = payload;
  },
  [SET_ACTIVE_STEP](state: manageOnboardingInt, value: number) {
    state.activeStep = value;
  },
  [SET_STEP_IS_VALID](state: manageOnboardingInt, payload) {
    state.steps[payload.step].isInvalid = payload.isInvalid;
  },
  [SET_IS_SUBMIT_INPROGRESS](state: manageOnboardingInt, payload: boolean) {
    state.isSubmitInprogress = payload;
  },
  [SET_IS_REQUEST_SUCCESS](state: manageOnboardingInt, payload: boolean) {
    state.isReqSuccess = payload;
  },
  [SET_COUNTRIES](state: manageOnboardingInt, payload) {
    state.countries = payload;
  },
  [SET_ORG_PRE_FILL_DATA](state: manageOnboardingInt, payload) {
    state.organisationPreFilledData = payload;
  },
  [SET_READONLY_ORG_INFO](state: manageOnboardingInt, payload) {
    state.readOnlyOrgInfo = payload;
  },
  [RESET_FORM](state: manageOnboardingInt) {
    state.steps = getDefaultForm();
  },
};
