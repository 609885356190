import {Module} from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
export interface ICurrentRoute {
  title: string;
  routeName: string;
}
export interface IntUserProfileState {
  userInfo: object | null;
  isLoading: boolean;
  breadcrumbs: ICurrentRoute[];
}

// initial state
export const getDefaultState = (): IntUserProfileState => {
  return {
    isLoading: false,
    userInfo:  null,
    breadcrumbs: []
  }
}
const INITIAL_STATE = getDefaultState();

// org profile store
const UserProfileStore: Module<IntUserProfileState, {}> = {
  namespaced: true,
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};

export default UserProfileStore;
