import {GetterTree} from 'vuex';
import {
  GET_IS_LOADING,
  GET_USER_INFO,
  GET_PAGE_BREADCRUMBS
} from './getter-types';
import {
  IntUserProfileState
} from './index';

const getters: GetterTree<IntUserProfileState, {}> = {
  [GET_USER_INFO]: state => state.userInfo,
  [GET_IS_LOADING]: state => state.isLoading,
  [GET_PAGE_BREADCRUMBS]: state => state.breadcrumbs
}

export default getters;