export const countriesList = [
  {
    text: "Afghanistan",
    value: "AF",
    divisions: [
      {
        text: "Badakhshān",
        value: "AF-BDS"
      },
      {
        text: "Bādghīs",
        value: "AF-BDG"
      },
      {
        text: "Baghlān",
        value: "AF-BGL"
      },
      {
        text: "Balkh",
        value: "AF-BAL"
      },
      {
        text: "Bāmīān",
        value: "AF-BAM"
      },
      {
        text: "Farāh",
        value: "AF-FRA"
      },
      {
        text: "Fāryāb",
        value: "AF-FYB"
      },
      {
        text: "Ghaznī",
        value: "AF-GHA"
      },
      {
        text: "Ghowr",
        value: "AF-GHO"
      },
      {
        text: "Helmand",
        value: "AF-HEL"
      },
      {
        text: "Herāt",
        value: "AF-HER"
      },
      {
        text: "Jowzjān",
        value: "AF-JOW"
      },
      {
        text: "Kabul (Kābol)",
        value: "AF-KAB"
      },
      {
        text: "Kandahār",
        value: "AF-KAN"
      },
      {
        text: "Kāpīsā",
        value: "AF-KAP"
      },
      {
        text: "Konar (Kunar)",
        value: "AF-KNR"
      },
      {
        text: "Kondoz (Kunduz)",
        value: "AF-KDZ"
      },
      {
        text: "Laghmān",
        value: "AF-LAG"
      },
      {
        text: "Lowgar",
        value: "AF-LOW"
      },
      {
        text: "Nangrahār (Nangarhār)",
        value: "AF-NAN"
      },
      {
        text: "Nīmrūz",
        value: "AF-NIM"
      },
      {
        text: "Orūzgān (Urūzgā",
        value: "AF-ORU"
      },
      {
        text: "Paktīā",
        value: "AF-PIA"
      },
      {
        text: "Paktīkā",
        value: "AF-PKA"
      },
      {
        text: "Parwān",
        value: "AF-PAR"
      },
      {
        text: "Samangān",
        value: "AF-SAM"
      },
      {
        text: "Sar-e Pol",
        value: "AF-SAR"
      },
      {
        text: "Takhār",
        value: "AF-TAK"
      },
      {
        text: "Wardak (Wardag)",
        value: "AF-WAR"
      },
      {
        text: "Zābol (Zābul)",
        value: "AF-ZAB"
      }
    ]
  },
  {
    text: "Albania",
    value: "AL",
    divisions: [
      {
        text: "Berat",
        value: "AL-BR"
      },
      {
        text: "Bulqizë",
        value: "AL-BU"
      },
      {
        text: "Delvinë",
        value: "AL-DL"
      },
      {
        text: "Devoll",
        value: "AL-DV"
      },
      {
        text: "Dibër",
        value: "AL-DI"
      },
      {
        text: "Durrës",
        value: "AL-DR"
      },
      {
        text: "Elbasan",
        value: "AL-EL"
      },
      {
        text: "Fier",
        value: "AL-FR"
      },
      {
        text: "Gramsh",
        value: "AL-GR"
      },
      {
        text: "Gjirokastër",
        value: "AL-GJ"
      },
      {
        text: "Has",
        value: "AL-HA"
      },
      {
        text: "Kavajë",
        value: "AL-KA"
      },
      {
        text: "Kolonjë",
        value: "AL-ER"
      },
      {
        text: "Korcë",
        value: "AL-KO"
      },
      {
        text: "Krujë",
        value: "AL-KR"
      },
      {
        text: "Kucovë",
        value: "AL-KC"
      },
      {
        text: "Kukës",
        value: "AL-KU"
      },
      {
        text: "Laç",
        value: "AL-LA"
      },
      {
        text: "Lezhë",
        value: "AL-LE"
      },
      {
        text: "Librazhd",
        value: "AL-LB"
      },
      {
        text: "Lushnjë",
        value: "AL-LU"
      },
      {
        text: "Malësia e Madhe",
        value: "AL-MM"
      },
      {
        text: "Mallakastër",
        value: "AL-MK"
      },
      {
        text: "Mat",
        value: "AL-MT"
      },
      {
        text: "Mirditë",
        value: "AL-MR"
      },
      {
        text: "Peqin",
        value: "AL-PQ"
      },
      {
        text: "Përmet",
        value: "AL-PR"
      },
      {
        text: "Pogradec",
        value: "AL-PG"
      },
      {
        text: "Pukë",
        value: "AL-PU"
      },
      {
        text: "Sarandë",
        value: "AL-SR"
      },
      {
        text: "Skrapar",
        value: "AL-SK"
      },
      {
        text: "Shkodër",
        value: "AL-SH"
      },
      {
        text: "Tepelenë",
        value: "AL-TE"
      },
      {
        text: "Tiranë",
        value: "AL-TR"
      },
      {
        text: "Tropojë",
        value: "AL-TP"
      },
      {
        text: "Vlorë",
        value: "AL-VL"
      }
    ]
  },
  {
    text: "Algeria",
    value: "DZ",
    divisions: [
      {
        text: "Adrar",
        value: "DZ-01"
      },
      {
        text: "Aïn Defla",
        value: "DZ-44"
      },
      {
        text: "Aïn Témouchent",
        value: "DZ-46"
      },
      {
        text: "Alger",
        value: "DZ-16"
      },
      {
        text: "Batna",
        value: "DZ-05"
      },
      {
        text: "Béchar",
        value: "DZ-08"
      },
      {
        text: "Béjaïa",
        value: "DZ-06"
      },
      {
        text: "Biskra",
        value: "DZ-07"
      },
      {
        text: "Blida",
        value: "DZ-09"
      },
      {
        text: "Bordj Bou Arréridj",
        value: "DZ-34"
      },
      {
        text: "Bouira",
        value: "DZ-10"
      },
      {
        text: "Boumerdès",
        value: "DZ-35"
      },
      {
        text: "Chlef",
        value: "DZ-02"
      },
      {
        text: "Constantine",
        value: "DZ-25"
      },
      {
        text: "Djelfa",
        value: "DZ-17"
      },
      {
        text: "El Bayadh",
        value: "DZ-32"
      },
      {
        text: "El Oued",
        value: "DZ-39"
      },
      {
        text: "El Tarf",
        value: "DZ-36"
      },
      {
        text: "Ghardaïa",
        value: "DZ-47"
      },
      {
        text: "Guelma",
        value: "DZ-24"
      },
      {
        text: "Illizi",
        value: "DZ-33"
      },
      {
        text: "Jijel",
        value: "DZ-18"
      },
      {
        text: "Khenchela",
        value: "DZ-40"
      },
      {
        text: "Laghouat",
        value: "DZ-03"
      },
      {
        text: "Mascara",
        value: "DZ-29"
      },
      {
        text: "Médéa",
        value: "DZ-26"
      },
      {
        text: "Mila",
        value: "DZ-43"
      },
      {
        text: "Mostaganem",
        value: "DZ-27"
      },
      {
        text: "Msila",
        value: "DZ-28"
      },
      {
        text: "Naama",
        value: "DZ-45"
      },
      {
        text: "Oran",
        value: "DZ-31"
      },
      {
        text: "Ouargla",
        value: "DZ-30"
      },
      {
        text: "Oum el Bouaghi",
        value: "DZ-04"
      },
      {
        text: "Relizane",
        value: "DZ-48"
      },
      {
        text: "Saïda",
        value: "DZ-20"
      },
      {
        text: "Sétif",
        value: "DZ-19"
      },
      {
        text: "Sidi Bel Abbès",
        value: "DZ-22"
      },
      {
        text: "Skikda",
        value: "DZ-21"
      },
      {
        text: "Souk Ahras",
        value: "DZ-41"
      },
      {
        text: "Tamanghasset",
        value: "DZ-11"
      },
      {
        text: "Tébessa",
        value: "DZ-12"
      },
      {
        text: "Tiaret",
        value: "DZ-14"
      },
      {
        text: "Tindouf",
        value: "DZ-37"
      },
      {
        text: "Tipaza",
        value: "DZ-42"
      },
      {
        text: "Tissemsilt",
        value: "DZ-38"
      },
      {
        text: "Tizi Ouzou",
        value: "DZ-15"
      },
      {
        text: "Tlemcen",
        value: "DZ-13"
      }
    ]
  },
  {
    text: "American Samoa",
    value: "AS",
    divisions: [
      {
        text: "American Samoa",
        value: "AS-AS"
      }
    ]
  },
  {
    text: "Andorra",
    value: "AD",
    divisions: [
      {
        text: "Andorra",
        value: "AD-AD"
      }
    ]
  },
  {
    text: "Angola",
    value: "AO",
    divisions: [
      {
        text: "Bengo",
        value: "AO-BGO"
      },
      {
        text: "Benguela",
        value: "AO-BGU"
      },
      {
        text: "Bié",
        value: "AO-BIE"
      },
      {
        text: "Cabinda",
        value: "AO-CAB"
      },
      {
        text: "Cuando-Cubango",
        value: "AO-CCU"
      },
      {
        text: "Cuanza Norte",
        value: "AO-CNO"
      },
      {
        text: "Cuanza Sul",
        value: "AO-CUS"
      },
      {
        text: "Cunene",
        value: "AO-CNN"
      },
      {
        text: "Huambo",
        value: "AO-HUA"
      },
      {
        text: "Huíla",
        value: "AO-HUI"
      },
      {
        text: "Luanda",
        value: "AO-LUA"
      },
      {
        text: "Lunda Norte",
        value: "AO-LNO"
      },
      {
        text: "Lunda Sul",
        value: "AO-LSU"
      },
      {
        text: "Malange",
        value: "AO-MAL"
      },
      {
        text: "Moxico",
        value: "AO-MOX"
      },
      {
        text: "Namibe",
        value: "AO-NAM"
      },
      {
        text: "Uíge",
        value: "AO-UIG"
      },
      {
        text: "Zaïre",
        value: "AO-ZAI"
      }
    ]
  },
  {
    text: "Anguilla",
    value: "AI",
    divisions: [
      {
        text: "Anguilla",
        value: "AI-AI"
      }
    ]
  },
  {
    text: "Antarctica",
    value: "AQ",
    divisions: [
      {
        text: "Antarctica",
        value: "AQ-AQ"
      }
    ]
  },
  {
    text: "Antigua & Barbuda",
    value: "AG",
    divisions: [
      {
        text: "Antigua & Barbuda",
        value: "AG-AG"
      }
    ]
  },
  {
    text: "Argentina",
    value: "AR",
    divisions: [
      {
        text: "Capital federal",
        value: "AR-C"
      },
      {
        text: "Buenos Aires",
        value: "AR-B"
      },
      {
        text: "Catamarca",
        value: "AR-K"
      },
      {
        text: "Córdoba",
        value: "AR-X"
      },
      {
        text: "Corrientes",
        value: "AR-W"
      },
      {
        text: "Chaco",
        value: "AR-H"
      },
      {
        text: "Chubut",
        value: "AR-U"
      },
      {
        text: "Entre Ríos",
        value: "AR-E"
      },
      {
        text: "Formosa",
        value: "AR-P"
      },
      {
        text: "Jujuy",
        value: "AR-Y"
      },
      {
        text: "La Pampa",
        value: "AR-L"
      },
      {
        text: "La Rioja",
        value: "AR-F"
      },
      {
        text: "Mendoza",
        value: "AR-M"
      },
      {
        text: "Misiones",
        value: "AR-N"
      },
      {
        text: "Neuquén",
        value: "AR-Q"
      },
      {
        text: "Río Negro",
        value: "AR-R"
      },
      {
        text: "Salta",
        value: "AR-A"
      },
      {
        text: "San Juan",
        value: "AR-J"
      },
      {
        text: "San Luis",
        value: "AR-D"
      },
      {
        text: "Santa Cruz",
        value: "AR-Z"
      },
      {
        text: "Santa Fe",
        value: "AR-S"
      },
      {
        text: "Santiago del Estero",
        value: "AR-G"
      },
      {
        text: "Tierra del Fuego",
        value: "AR-V"
      },
      {
        text: "Tucumán",
        value: "AR-T"
      }
    ]
  },
  {
    text: "Armenia",
    value: "AM",
    divisions: [
      {
        text: "Erevan",
        value: "AM-ER"
      },
      {
        text: "Aragacotn",
        value: "AM-AG"
      },
      {
        text: "Ararat",
        value: "AM-AR"
      },
      {
        text: "Armavir",
        value: "AM-AV"
      },
      {
        text: "Geģark'unik'",
        value: "AM-GR"
      },
      {
        text: "Kotayk'",
        value: "AM-KT"
      },
      {
        text: "Loŕy",
        value: "AM-LO"
      },
      {
        text: "Širak",
        value: "AM-SH"
      },
      {
        text: "Syunik'",
        value: "AM-SU"
      },
      {
        text: "Tavuš",
        value: "AM-TV"
      },
      {
        text: "Vayoc Jor",
        value: "AM-VD"
      }
    ]
  },
  {
    text: "Aruba",
    value: "AW",
    divisions: [
      {
        text: "Aruba",
        value: "AW-AW"
      }
    ]
  },
  {
    text: "Australia",
    value: "AU",
    divisions: [
      {
        text: "New South Wales",
        value: "AU-NSW"
      },
      {
        text: "Queensland",
        value: "AU-QLD"
      },
      {
        text: "South Australia",
        value: "AU-SA"
      },
      {
        text: "Tasmania",
        value: "AU-TAS"
      },
      {
        text: "Victoria",
        value: "AU-VIC"
      },
      {
        text: "Western Australia",
        value: "AU-WA"
      },
      {
        text: "Australian Capital Territory",
        value: "AU-ACT"
      },
      {
        text: "Northern Territory",
        value: "AU-NT"
      }
    ]
  },
  {
    text: "Austria",
    value: "AT",
    divisions: [
      {
        text: "Burgenland",
        value: "AT-1"
      },
      {
        text: "Kärnten",
        value: "AT-2"
      },
      {
        text: "Niederösterreich",
        value: "AT-3"
      },
      {
        text: "Oberösterreich",
        value: "AT-4"
      },
      {
        text: "Salzburg",
        value: "AT-5"
      },
      {
        text: "Steiermark",
        value: "AT-6"
      },
      {
        text: "Tirol",
        value: "AT-7"
      },
      {
        text: "Vorarlberg",
        value: "AT-8"
      },
      {
        text: "Wien",
        value: "AT-9"
      }
    ]
  },
  {
    text: "Azerbaijan",
    value: "AZ",
    divisions: [
      {
        text: "Naxçivan",
        value: "AZ-MM"
      },
      {
        text: "Äli Bayramli",
        value: "AZ-AB"
      },
      {
        text: "Baki",
        value: "AZ-BA"
      },
      {
        text: "Gäncä",
        value: "AZ-GA"
      },
      {
        text: "Länkäran",
        value: "AZ-LA"
      },
      {
        text: "Mingäçevir",
        value: "AZ-MI"
      },
      {
        text: "Naftalan",
        value: "AZ-NA"
      },
      {
        text: "Şäki",
        value: "AZ-SA"
      },
      {
        text: "Sumqayit",
        value: "AZ-SM"
      },
      {
        text: "Şuşa",
        value: "AZ-SS"
      },
      {
        text: "Xankändi",
        value: "AZ-XA"
      },
      {
        text: "Yevlax",
        value: "AZ-YE"
      },
      {
        text: "Abşeron",
        value: "AZ-ABS"
      },
      {
        text: "Ağcabädi",
        value: "AZ-AGC"
      },
      {
        text: "Ağdam",
        value: "AZ-AGM"
      },
      {
        text: "Ağdas",
        value: "AZ-AGS"
      },
      {
        text: "Ağstafa",
        value: "AZ-AGA"
      },
      {
        text: "Ağsu",
        value: "AZ-AGU"
      },
      {
        text: "Astara",
        value: "AZ-AST"
      },
      {
        text: "Babäk",
        value: "AZ-BAB"
      },
      {
        text: "Balakän",
        value: "AZ-BAL"
      },
      {
        text: "Bärdä",
        value: "AZ-BAR"
      },
      {
        text: "Beyläqan",
        value: "AZ-BEY"
      },
      {
        text: "Biläsuvar",
        value: "AZ-BIL"
      },
      {
        text: "Cäbrayil",
        value: "AZ-CAB"
      },
      {
        text: "Cälilabad",
        value: "AZ-CAL"
      },
      {
        text: "Culfa",
        value: "AZ-CUL"
      },
      {
        text: "Daşkäsän",
        value: "AZ-DAS"
      },
      {
        text: "Däväçi",
        value: "AZ-DAV"
      },
      {
        text: "Füzuli",
        value: "AZ-FUZ"
      },
      {
        text: "Gädäbäy",
        value: "AZ-GAD"
      },
      {
        text: "Goranboy",
        value: "AZ-GOR"
      },
      {
        text: "Göyçay",
        value: "AZ-GOY"
      },
      {
        text: "Haciqabul",
        value: "AZ-HAC"
      },
      {
        text: "Imişli",
        value: "AZ-IMI"
      },
      {
        text: "Ismayilli",
        value: "AZ-ISM"
      },
      {
        text: "Kälbäcär",
        value: "AZ-KAL"
      },
      {
        text: "Kürdämir",
        value: "AZ-KUR"
      },
      {
        text: "Laçin",
        value: "AZ-LAC"
      },
      {
        text: "Länkäran",
        value: "AZ-LAN"
      },
      {
        text: "Lerik",
        value: "AZ-LER"
      },
      {
        text: "Masalli",
        value: "AZ-MAS"
      },
      {
        text: "Neftçala",
        value: "AZ-NEF"
      },
      {
        text: "Oğuz",
        value: "AZ-OGU"
      },
      {
        text: "Ordubad",
        value: "AZ-ORD"
      },
      {
        text: "Qäbälä",
        value: "AZ-QAB"
      },
      {
        text: "Qax",
        value: "AZ-QAX"
      },
      {
        text: "Qazax",
        value: "AZ-QAZ"
      },
      {
        text: "Qobustan",
        value: "AZ-QOB"
      },
      {
        text: "Quba",
        value: "AZ-QBA"
      },
      {
        text: "Qubadlı",
        value: "AZ-QBI"
      },
      {
        text: "Qusar",
        value: "AZ-QUS"
      },
      {
        text: "Saatli",
        value: "AZ-SAT"
      },
      {
        text: "Sabirabad",
        value: "AZ-SAB"
      },
      {
        text: "Sädäräk",
        value: "AZ-SAD"
      },
      {
        text: "Şahbuz",
        value: "AZ-SAH"
      },
      {
        text: "Şäki",
        value: "AZ-SAK"
      },
      {
        text: "Salyan",
        value: "AZ-SAL"
      },
      {
        text: "Şamaxı",
        value: "AZ-SMI"
      },
      {
        text: "Şämkir",
        value: "AZ-SKR"
      },
      {
        text: "Samux",
        value: "AZ-SMX"
      },
      {
        text: "Şärur",
        value: "AZ-SAR"
      },
      {
        text: "Siyäzän",
        value: "AZ-SIY"
      },
      {
        text: "Şuşa",
        value: "AZ-SUS"
      },
      {
        text: "Tärtär",
        value: "AZ-TAR"
      },
      {
        text: "Tovuz",
        value: "AZ-TOV"
      },
      {
        text: "Ucar",
        value: "AZ-UCA"
      },
      {
        text: "Xaçmaz",
        value: "AZ-XAC"
      },
      {
        text: "Xanlar",
        value: "AZ-XAN"
      },
      {
        text: "Xizi",
        value: "AZ-XIZ"
      },
      {
        text: "Xocalı",
        value: "AZ-XCI"
      },
      {
        text: "Xocavänd",
        value: "AZ-XVD"
      },
      {
        text: "Yardimli",
        value: "AZ-YAR"
      },
      {
        text: "Yevlax",
        value: "AZ-YEV"
      },
      {
        text: "Zängılan",
        value: "AZ-ZAN"
      },
      {
        text: "Zaqatala",
        value: "AZ-ZAQ"
      },
      {
        text: "Zärdab",
        value: "AZ-ZAR"
      }
    ]
  },
  {
    text: "Bahamas",
    value: "BS",
    divisions: [
      {
        text: "Acklins and Crooked Islands",
        value: "BS-AC"
      },
      {
        text: "Bimini",
        value: "BS-BI"
      },
      {
        text: "Cat Island",
        value: "BS-CI"
      },
      {
        text: "Exuma",
        value: "BS-EX"
      },
      {
        text: "Freeport",
        value: "BS-FP"
      },
      {
        text: "Fresh Creek",
        value: "BS-FC"
      },
      {
        text: "Governor's Harbour",
        value: "BS-GH"
      },
      {
        text: "Green Turtle Cay",
        value: "BS-GT"
      },
      {
        text: "Harbour Island",
        value: "BS-HI"
      },
      {
        text: "High Rock",
        value: "BS-HR"
      },
      {
        text: "Inagua",
        value: "BS-IN"
      },
      {
        text: "Kemps Bay",
        value: "BS-KB"
      },
      {
        text: "Long Island",
        value: "BS-LI"
      },
      {
        text: "Marsh Harbour",
        value: "BS-MH"
      },
      {
        text: "Mayaguana",
        value: "BS-MG"
      },
      {
        text: "New Providence",
        value: "BS-NP"
      },
      {
        text: "Nicholls Town and Berry Islands",
        value: "BS-NB"
      },
      {
        text: "Ragged Island",
        value: "BS-RI"
      },
      {
        text: "Rock Sound",
        value: "BS-RS"
      },
      {
        text: "Sandy Point",
        value: "BS-SP"
      },
      {
        text: "San Salvador and Rum Cay",
        value: "BS-SR"
      }
    ]
  },
  {
    text: "Bahrain",
    value: "BH",
    divisions: [
      {
        text: "Al Ḩadd",
        value: "BH-01"
      },
      {
        text: "Al Manāmah",
        value: "BH-03"
      },
      {
        text: "Al Minţaqah al Gharbīyah",
        value: "BH-10"
      },
      {
        text: "Al Minţaqah al Wusţa",
        value: "BH-07"
      },
      {
        text: "Al Minţaqah ash Shamālīyah",
        value: "BH-05"
      },
      {
        text: "Al Muḩarraq",
        value: "BH-02"
      },
      {
        text: "Ar Rifā‘",
        value: "BH-09"
      },
      {
        text: "Jidd Ḩafş",
        value: "BH-04"
      },
      {
        text: "Madīnat Ḩamad",
        value: "BH-12"
      },
      {
        text: "Madīnat ‘Īsá",
        value: "BH-08"
      },
      {
        text: "Minţaqat Juzur Ḩawār",
        value: "BH-11"
      },
      {
        text: "Sitrah",
        value: "BH-06"
      }
    ]
  },
  {
    text: "Bangladesh",
    value: "BD",
    divisions: [
      {
        text: "Barisal bibhag",
        value: "BD-1"
      },
      {
        text: "Barisal anchal",
        value: "BD-1B"
      },
      {
        text: "Patuakhali anchal",
        value: "BD-1Q"
      },
      {
        text: "Chittagong bibhag",
        value: "BD-2"
      },
      {
        text: "Bandarban anchal",
        value: "BD-2A"
      },
      {
        text: "Chittagong anchal",
        value: "BD-2D"
      },
      {
        text: "Chittagong Hill Tracts",
        value: "BD-2E"
      },
      {
        text: "Comilla anchal",
        value: "BD-2F"
      },
      {
        text: "Noakhali anchal",
        value: "BD-2O"
      },
      {
        text: "Sylhet anchal",
        value: "BD-2T"
      },
      {
        text: "Dhaka bibhag",
        value: "BD-3"
      },
      {
        text: "Dhaka anchal",
        value: "BD-3G"
      },
      {
        text: "Faridpur anchal",
        value: "BD-3I"
      },
      {
        text: "Jamalpur anchal",
        value: "BD-3J"
      },
      {
        text: "Mymensingh anchal",
        value: "BD-3N"
      },
      {
        text: "Tangail anchal",
        value: "BD-3U"
      },
      {
        text: "Khulna bibhag",
        value: "BD-4"
      },
      {
        text: "Jessore anchal",
        value: "BD-4K"
      },
      {
        text: "Khulna anchal",
        value: "BD-4L"
      },
      {
        text: "Khustia anchal",
        value: "BD-4M"
      },
      {
        text: "Rajshahi bibhag",
        value: "BD-5"
      },
      {
        text: "Bogra anchal",
        value: "BD-5C"
      },
      {
        text: "Dinajpur anchal",
        value: "BD-5H"
      },
      {
        text: "Pabna anchal",
        value: "BD-5P"
      },
      {
        text: "Rajshahi anchal",
        value: "BD-5R"
      },
      {
        text: "Rangpur anchal",
        value: "BD-5S"
      }
    ]
  },
  {
    text: "Barbados",
    value: "BB",
    divisions: [
      {
        text: "Barbados",
        value: "BB-BB"
      }
    ]
  },
  {
    text: "Belarus",
    value: "BY",
    divisions: [
      {
        text: "Brestskaya voblasts'",
        value: "BY-BR"
      },
      {
        text: "Homyel'skaya voblasts’",
        value: "BY-HO"
      },
      {
        text: "Hrodnenskaya voblasts'",
        value: "BY-HR"
      },
      {
        text: "Mahilyowskaya voblasts'",
        value: "BY-MA"
      },
      {
        text: "Minskaya voblasts'",
        value: "BY-MI"
      },
      {
        text: "Vitsyebskaya voblasts'",
        value: "BY-VI"
      }
    ]
  },
  {
    text: "Belgium",
    value: "BE",
    divisions: [
      {
        text: "Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)",
        value: "BE-BRU"
      },
      {
        text: "Vlaamse Gewest (nl)",
        value: "BE-VLG"
      },
      {
        text: "Antwerpen (nl)",
        value: "BE-VAN"
      },
      {
        text: "Limburg (nl)",
        value: "BE-VLI"
      },
      {
        text: "Oost-Vlaanderen (nl)",
        value: "BE-VOV"
      },
      {
        text: "Vlaams Brabant (nl)",
        value: "BE-VBR"
      },
      {
        text: "West-Vlaanderen (nl)",
        value: "BE-VWV"
      },
      {
        text: "Wallonne, Region (fr)",
        value: "BE-WAL"
      },
      {
        text: "Brabant Wallon (fr)",
        value: "BE-WBR"
      },
      {
        text: "Hainaut (fr)",
        value: "BE-WHT"
      },
      {
        text: "Liège (fr)",
        value: "BE-WLG"
      },
      {
        text: "Luxembourg (fr)",
        value: "BE-WLX"
      },
      {
        text: "Namur (fr)",
        value: "BE-WNA"
      }
    ]
  },
  {
    text: "Belize",
    value: "BZ",
    divisions: [
      {
        text: "Belize",
        value: "BZ-BZ"
      },
      {
        text: "Cayo",
        value: "BZ-CY"
      },
      {
        text: "Corozal",
        value: "BZ-CZL"
      },
      {
        text: "Orange Walk",
        value: "BZ-OW"
      },
      {
        text: "Stann Creek",
        value: "BZ-SC"
      },
      {
        text: "Toledo",
        value: "BZ-TOL"
      }
    ]
  },
  {
    text: "Benin",
    value: "BJ",
    divisions: [
      {
        text: "Atakora",
        value: "BJ-AK"
      },
      {
        text: "Atlantique",
        value: "BJ-AQ"
      },
      {
        text: "Borgou",
        value: "BJ-BO"
      },
      {
        text: "Mono",
        value: "BJ-MO"
      },
      {
        text: "Ouémé",
        value: "BJ-OU"
      },
      {
        text: "Zou",
        value: "BJ-ZO"
      }
    ]
  },
  {
    text: "Bermuda",
    value: "BM",
    divisions: [
      {
        text: "Bermuda",
        value: "BM-BM"
      }
    ]
  },
  {
    text: "Bhutan",
    value: "BT",
    divisions: [
      {
        text: "Bumthang",
        value: "BT-33"
      },
      {
        text: "Chhukha",
        value: "BT-12"
      },
      {
        text: "Dagana",
        value: "BT-22"
      },
      {
        text: "Gasa",
        value: "BT-GA"
      },
      {
        text: "Ha",
        value: "BT-13"
      },
      {
        text: "Lhuentse",
        value: "BT-44"
      },
      {
        text: "Monggar",
        value: "BT-42"
      },
      {
        text: "Paro",
        value: "BT-11"
      },
      {
        text: "Pemagatshel",
        value: "BT-43"
      },
      {
        text: "Punakha",
        value: "BT-23"
      },
      {
        text: "Samdrup Jongkha",
        value: "BT-45"
      },
      {
        text: "Samtse",
        value: "BT-14"
      },
      {
        text: "Sarpang",
        value: "BT-31"
      },
      {
        text: "Thimphu",
        value: "BT-15"
      },
      {
        text: "Trashigang",
        value: "BT-41"
      },
      {
        text: "Trashi Yangtse",
        value: "BT-TY"
      },
      {
        text: "Trongsa",
        value: "BT-32"
      },
      {
        text: "Tsirang",
        value: "BT-21"
      },
      {
        text: "Wangdue Phodrang",
        value: "BT-24"
      },
      {
        text: "Zhemgang",
        value: "BT-34"
      }
    ]
  },
  {
    text: "Bolivia",
    value: "BO",
    divisions: [
      {
        text: "Cochabamba",
        value: "BO-C"
      },
      {
        text: "Chuquisaca",
        value: "BO-H"
      },
      {
        text: "El Beni",
        value: "BO-B"
      },
      {
        text: "La Paz",
        value: "BO-L"
      },
      {
        text: "Oruro",
        value: "BO-O"
      },
      {
        text: "Pando",
        value: "BO-N"
      },
      {
        text: "Potosi",
        value: "BO-P"
      },
      {
        text: "Santa Cruz",
        value: "BO-S"
      },
      {
        text: "Tarija",
        value: "BO-T"
      }
    ]
  },
  {
    text: "Bosnia & Herzegovina",
    value: "BA",
    divisions: [
      {
        text: "Federacija Bosna i Hercegovina",
        value: "BA-BIH"
      },
      {
        text: "Republika Srpska",
        value: "BA-SRP"
      }
    ]
  },
  {
    text: "Botswana",
    value: "BW",
    divisions: [
      {
        text: "Central [Serowe-Palapye]",
        value: "BW-CE"
      },
      {
        text: "Chobe",
        value: "BW-CH"
      },
      {
        text: "Ghanzi",
        value: "BW-GH"
      },
      {
        text: "Kgalagadi",
        value: "BW-KG"
      },
      {
        text: "Kgatleng",
        value: "BW-KL"
      },
      {
        text: "Kweneng",
        value: "BW-KW"
      },
      {
        text: "Ngamiland [North-West]",
        value: "BW-NG"
      },
      {
        text: "North-East",
        value: "BW-NE"
      },
      {
        text: "South-East",
        value: "BW-SE"
      },
      {
        text: "Southern [Ngwaketse]",
        value: "BW-SO"
      }
    ]
  },
  {
    text: "Bouvet Island",
    value: "BV",
    divisions: [
      {
        text: "Bouvet Island",
        value: "BV-BV"
      }
    ]
  },
  {
    text: "Brazil",
    value: "BR",
    divisions: [
      {
        text: "Distrito Federal",
        value: "BR-DF"
      },
      {
        text: "Acre",
        value: "BR-AC"
      },
      {
        text: "Alagoas",
        value: "BR-AL"
      },
      {
        text: "Amapá",
        value: "BR-AP"
      },
      {
        text: "Amazonas",
        value: "BR-AM"
      },
      {
        text: "Bahia",
        value: "BR-BA"
      },
      {
        text: "Ceará",
        value: "BR-CE"
      },
      {
        text: "Espírito Santo",
        value: "BR-ES"
      },
      {
        text: "Goiás",
        value: "BR-GO"
      },
      {
        text: "Maranhāo",
        value: "BR-MA"
      },
      {
        text: "Mato Grosso",
        value: "BR-MT"
      },
      {
        text: "Mato Grosso do Sul",
        value: "BR-MS"
      },
      {
        text: "Minas Gerais",
        value: "BR-MG"
      },
      {
        text: "Pará",
        value: "BR-PA"
      },
      {
        text: "Paraíba",
        value: "BR-PB"
      },
      {
        text: "Paraná",
        value: "BR-PR"
      },
      {
        text: "Pernambuco",
        value: "BR-PE"
      },
      {
        text: "Piauí",
        value: "BR-PI"
      },
      {
        text: "Rio de Janeiro",
        value: "BR-RJ"
      },
      {
        text: "Rio Grande do Norte",
        value: "BR-RN"
      },
      {
        text: "Rio Grande do Sul",
        value: "BR-RS"
      },
      {
        text: "Rondônia",
        value: "BR-R0"
      },
      {
        text: "Roraima",
        value: "BR-RR"
      },
      {
        text: "Santa Catarina",
        value: "BR-SC"
      },
      {
        text: "São Paulo",
        value: "BR-SP"
      },
      {
        text: "Sergipe",
        value: "BR-SE"
      },
      {
        text: "Tocantins",
        value: "BR-TO"
      }
    ]
  },
  {
    text: "British Indian Ocean Territory",
    value: "IO",
    divisions: [
      {
        text: "British Indian Ocean Territory",
        value: "IO-IO"
      }
    ]
  },
  {
    text: "Brunei Darussalam",
    value: "BN",
    divisions: [
      {
        text: "Belait",
        value: "BN-BE"
      },
      {
        text: "Brunei-Muara",
        value: "BN-BM"
      },
      {
        text: "Temburong",
        value: "BN-TE"
      },
      {
        text: "Tutong",
        value: "BN-TU"
      }
    ]
  },
  {
    text: "Bulgaria",
    value: "BG",
    divisions: [
      {
        text: "Burgas",
        value: "BG-02"
      },
      {
        text: "Haskovo",
        value: "BG-09"
      },
      {
        text: "Loveč",
        value: "BG-04"
      },
      {
        text: "Montana",
        value: "BG-05"
      },
      {
        text: "Plovdiv",
        value: "BG-06"
      },
      {
        text: "Ruse",
        value: "BG-07"
      },
      {
        text: "Sofija",
        value: "BG-08"
      },
      {
        text: "Sofija-Grad",
        value: "BG-01"
      },
      {
        text: "Varna",
        value: "BG-03"
      }
    ]
  },
  {
    text: "Burkina Faso",
    value: "BF",
    divisions: [
      {
        text: "Balé",
        value: "BF-BAL"
      },
      {
        text: "Bam",
        value: "BF-BAM"
      },
      {
        text: "Banwa",
        value: "BF-BAN"
      },
      {
        text: "Bazèga",
        value: "BF-BAZ"
      },
      {
        text: "Bougouriba",
        value: "BF-BGR"
      },
      {
        text: "Boulgou",
        value: "BF-BLG"
      },
      {
        text: "Boulkiemdé",
        value: "BF-BLK"
      },
      {
        text: "Comoé",
        value: "BF-COM"
      },
      {
        text: "Ganzourgou",
        value: "BF-GAN"
      },
      {
        text: "Gnagna",
        value: "BF-GNA"
      },
      {
        text: "Gourma",
        value: "BF-GOU"
      },
      {
        text: "Houet",
        value: "BF-HOU"
      },
      {
        text: "Ioba",
        value: "BF-IOB"
      },
      {
        text: "Kadiogo",
        value: "BF-KAD"
      },
      {
        text: "Kénédougou",
        value: "BF-KEN"
      },
      {
        text: "Komondjari",
        value: "BF-KMD"
      },
      {
        text: "Kompienga",
        value: "BF-KMP"
      },
      {
        text: "Kossi",
        value: "BF-KOS"
      },
      {
        text: "Koulpélogo",
        value: "BF-KOP"
      },
      {
        text: "Kouritenga",
        value: "BF-KOT"
      },
      {
        text: "Kourwéogo",
        value: "BF-KOW"
      },
      {
        text: "Léraba",
        value: "BF-LER"
      },
      {
        text: "Loroum",
        value: "BF-LOR"
      },
      {
        text: "Mouhoun",
        value: "BF-MOU"
      },
      {
        text: "Nahouri",
        value: "BF-NAO"
      },
      {
        text: "Namentenga",
        value: "BF-NAM"
      },
      {
        text: "Nayala",
        value: "BF-NAY"
      },
      {
        text: "Noumbiel",
        value: "BF-NOU"
      },
      {
        text: "Oubritenga",
        value: "BF-OUB"
      },
      {
        text: "Oudalan",
        value: "BF-OUD"
      },
      {
        text: "Passoré",
        value: "BF-PAS"
      },
      {
        text: "Poni",
        value: "BF-PON"
      },
      {
        text: "Sanguié",
        value: "BF-SNG"
      },
      {
        text: "Sanmatenga",
        value: "BF-SMT"
      },
      {
        text: "Séno",
        value: "BF-SEN"
      },
      {
        text: "Sissili",
        value: "BF-SIS"
      },
      {
        text: "Soum",
        value: "BF-SOM"
      },
      {
        text: "Sourou",
        value: "BF-SOR"
      },
      {
        text: "Tapoa",
        value: "BF-TAP"
      },
      {
        text: "Tui",
        value: "BF-TUI"
      },
      {
        text: "Yagha",
        value: "BF-YAG"
      },
      {
        text: "Yatenga",
        value: "BF-YAT"
      },
      {
        text: "Ziro",
        value: "BF-ZIR"
      },
      {
        text: "Zondoma",
        value: "BF-ZON"
      },
      {
        text: "Zoundwéogo",
        value: "BF-ZOU"
      }
    ]
  },
  {
    text: "Burundi",
    value: "BI",
    divisions: [
      {
        text: "Bubanza",
        value: "BI-BB"
      },
      {
        text: "Bujumbura",
        value: "BI-BJ"
      },
      {
        text: "Bururi",
        value: "BI-BR"
      },
      {
        text: "Cankuzo",
        value: "BI-CA"
      },
      {
        text: "Cibitoke",
        value: "BI-CI"
      },
      {
        text: "Gitega",
        value: "BI-GI"
      },
      {
        text: "Karuzi",
        value: "BI-KR"
      },
      {
        text: "Kayanza",
        value: "BI-KY"
      },
      {
        text: "Kirundo",
        value: "BI-KI"
      },
      {
        text: "Makamba",
        value: "BI-MA"
      },
      {
        text: "Muramvya",
        value: "BI-MU"
      },
      {
        text: "Muyinga",
        value: "BI-MY"
      },
      {
        text: "Ngozi",
        value: "BI-NG"
      },
      {
        text: "Rutana",
        value: "BI-RT"
      },
      {
        text: "Ruyigi",
        value: "BI-RY"
      }
    ]
  },
  {
    text: "Cambodia",
    value: "KH",
    divisions: [
      {
        text: "Krong Kaeb [Krŏng Kêb]",
        value: "KH-23"
      },
      {
        text: "Krong Preah Sihanouk [Krŏng Preăh Sihanouk]",
        value: "KH-18"
      },
      {
        text: "Phnom Penh [Phnum Pénh]",
        value: "KH-12"
      },
      {
        text: "Baat Dambang [Bătdâmbâng]",
        value: "KH-2"
      },
      {
        text: "Banteay Mean Chey [Bântéay Méanchey]",
        value: "KH-1"
      },
      {
        text: "Kampong Chaam [Kâmpóng Cham]",
        value: "KH-3"
      },
      {
        text: "Kampong Chhnang [Kâmpóng Chhnăng]",
        value: "KH-4"
      },
      {
        text: "Kampong Spueu [Kâmpóng Spœ]",
        value: "KH-5"
      },
      {
        text: "Kampong Thum [Kâmpóng Thum]",
        value: "KH-6"
      },
      {
        text: "Kampot [Kâmpôt]",
        value: "KH-7"
      },
      {
        text: "Kandaal [Kândal]",
        value: "KH-8"
      },
      {
        text: "Kracheh [Krâchéh]",
        value: "KH-10"
      },
      {
        text: "Mond01 Kiri [Môndól Kiri]",
        value: "KH-11"
      },
      {
        text: "Otdar Mean Chey [Ŏtdâr Méanchey]",
        value: "KH-22"
      },
      {
        text: "Pousaat [Poŭthĭsăt]",
        value: "KH-15"
      },
      {
        text: "Preah Vihear [Preăh Vihéar]",
        value: "KH-13"
      },
      {
        text: "Prey Veaeng [Prey Vêng]",
        value: "KH-14"
      },
      {
        text: "Rotanak Kiri [Rôtânôkiri]",
        value: "KH-16"
      },
      {
        text: "Siem Reab [Siĕmréab]",
        value: "KH-17"
      },
      {
        text: "Stueng Traeng [Stœng Trêng]",
        value: "KH-19"
      },
      {
        text: "Svaay Rieng [Svay Riĕng]",
        value: "KH-20"
      },
      {
        text: "Taakaev [Takêv]",
        value: "KH-21"
      }
    ]
  },
  {
    text: "Cameroon",
    value: "CM",
    divisions: [
      {
        text: "Adamaoua",
        value: "CM-AD"
      },
      {
        text: "Centre",
        value: "CM-CE"
      },
      {
        text: "Est",
        value: "CM-ES"
      },
      {
        text: "Far North",
        value: "CM-EN"
      },
      {
        text: "Littoral",
        value: "CM-LT"
      },
      {
        text: "North",
        value: "CM-NO"
      },
      {
        text: "North-West",
        value: "CM-NW"
      },
      {
        text: "South",
        value: "CM-SU"
      },
      {
        text: "South-West",
        value: "CM-SW"
      },
      {
        text: "West",
        value: "CM-OU"
      }
    ]
  },
  {
    text: "Canada",
    value: "CA",
    divisions: [
      {
        text: "Alberta",
        value: "CA-AB"
      },
      {
        text: "British Columbia (Colombie-Britannique)",
        value: "CA-BC"
      },
      {
        text: "Manitoba",
        value: "CA-MB"
      },
      {
        text: "New Brunswick (Nouveau-Brunswick)",
        value: "CA-NB"
      },
      {
        text: "Newfoundland and Labrador (Terre-Neuve)",
        value: "CA-NL"
      },
      {
        text: "Nova Scotia (Nouvelle-Écosse)",
        value: "CA-NS"
      },
      {
        text: "Ontario",
        value: "CA-ON"
      },
      {
        text: "Prince Edward Island (Île-du-Prince-Édouard)",
        value: "CA-PE"
      },
      {
        text: "Quebec (Québec)",
        value: "CA-QC"
      },
      {
        text: "Saskatchewan",
        value: "CA-SK"
      },
      {
        text: "Northwest Territories (Territoires du Nord-Ouest)",
        value: "CA-NT"
      },
      {
        text: "Nunavut",
        value: "CA-NU"
      },
      {
        text: "Yukon Territory (Teritoire du Yukon)",
        value: "CA-YT"
      }
    ]
  },
  {
    text: "Cape Verde",
    value: "CV",
    divisions: [
      {
        text: "Ilhas de Barlavento",
        value: "CV-B"
      },
      {
        text: "Boa Vista",
        value: "CV-BV"
      },
      {
        text: "Paul",
        value: "CV-PA"
      },
      {
        text: "Porto Novo",
        value: "CV-PN"
      },
      {
        text: "Ribeira Grande",
        value: "CV-RG"
      },
      {
        text: "Sal",
        value: "CV-SL"
      },
      {
        text: "Sāo Nicolau",
        value: "CV-SN"
      },
      {
        text: "Sāo Vicente",
        value: "CV-SV"
      },
      {
        text: "Ilhas de Sotavento",
        value: "CV-S"
      },
      {
        text: "Brava",
        value: "CV-BR"
      },
      {
        text: "Fogo",
        value: "CV-FO"
      },
      {
        text: "Maio",
        value: "CV-MA"
      },
      {
        text: "Praia",
        value: "CV-PR"
      },
      {
        text: "Santa Catarina",
        value: "CV-CA"
      },
      {
        text: "Santa Cruz",
        value: "CV-CR"
      },
      {
        text: "Tarrafal",
        value: "CV-TA"
      }
    ]
  },
  {
    text: "Cayman Islands",
    value: "KY",
    divisions: [
      {
        text: "Cayman Islands",
        value: "KY-KY"
      }
    ]
  },
  {
    text: "Central African Republic",
    value: "CF",
    divisions: [
      {
        text: "Bangui",
        value: "CF-BGF"
      },
      {
        text: "Bamingui-Bangoran",
        value: "CF-BB"
      },
      {
        text: "Basse-Kotto",
        value: "CF-BK"
      },
      {
        text: "Haute-Kotto",
        value: "CF-HK"
      },
      {
        text: "Haut-Mbomou",
        value: "CF-HM"
      },
      {
        text: "Kémo",
        value: "CF-KG"
      },
      {
        text: "Lobaye",
        value: "CF-LB"
      },
      {
        text: "Mambéré-Kadéï",
        value: "CF-HS"
      },
      {
        text: "Mbomou",
        value: "CF-MB"
      },
      {
        text: "Nana-Grébizi",
        value: "CF-KB"
      },
      {
        text: "Nana-Mambéré",
        value: "CF-NM"
      },
      {
        text: "Ombella-Mpoko",
        value: "CF-MP"
      },
      {
        text: "Ouaka",
        value: "CF-UK"
      },
      {
        text: "Ouham",
        value: "CF-AC"
      },
      {
        text: "Ouham-Pendé",
        value: "CF-OP"
      },
      {
        text: "Sangha-Mbaéré",
        value: "CF-SE"
      },
      {
        text: "Vakaga",
        value: "CF-VK"
      }
    ]
  },
  {
    text: "Chad",
    value: "TD",
    divisions: [
      {
        text: "Batha",
        value: "TD-BA"
      },
      {
        text: "Biltine",
        value: "TD-BI"
      },
      {
        text: "Borkou-Ennedi-Tibesti",
        value: "TD-BET"
      },
      {
        text: "Chari-Baguirmi",
        value: "TD-CB"
      },
      {
        text: "Guéra",
        value: "TD-GR"
      },
      {
        text: "Kanem",
        value: "TD-KA"
      },
      {
        text: "Lac",
        value: "TD-LC"
      },
      {
        text: "Logone-Occidental",
        value: "TD-LO"
      },
      {
        text: "Logone-Oriental",
        value: "TD-LR"
      },
      {
        text: "Mayo-Kébbi",
        value: "TD-MK"
      },
      {
        text: "Moyen-Chari",
        value: "TD-MC"
      },
      {
        text: "Ouaddaï",
        value: "TD-OD"
      },
      {
        text: "Salamat",
        value: "TD-SA"
      },
      {
        text: "Tandjilé",
        value: "TD-TA"
      }
    ]
  },
  {
    text: "Chile",
    value: "CL",
    divisions: [
      {
        text: "Aisén del General Carlos Ibáñiez del Campo",
        value: "CL-AI"
      },
      {
        text: "Antofagasta",
        value: "CL-AN"
      },
      {
        text: "Araucanía",
        value: "CL-AR"
      },
      {
        text: "Atacama",
        value: "CL-AT"
      },
      {
        text: "Bío-Bío",
        value: "CL-BI"
      },
      {
        text: "Coquimbo",
        value: "CL-CO"
      },
      {
        text: "Libertador General Bernardo O'Higgins",
        value: "CL-LI"
      },
      {
        text: "Los Lagos",
        value: "CL-LL"
      },
      {
        text: "Magallanes",
        value: "CL-MA"
      },
      {
        text: "Maule",
        value: "CL-ML"
      },
      {
        text: "Regíon Metropolitana de Santiago",
        value: "CL-RM"
      },
      {
        text: "Tarapacá",
        value: "CL-TA"
      },
      {
        text: "Valparaiso",
        value: "CL-VS"
      }
    ]
  },
  {
    text: "China",
    value: "CN",
    divisions: [
      {
        text: "Beijing",
        value: "CN-11"
      },
      {
        text: "Chongqing",
        value: "CN-50"
      },
      {
        text: "Shanghai",
        value: "CN-31"
      },
      {
        text: "Tianjin",
        value: "CN-12"
      },
      {
        text: "Anhui",
        value: "CN-34"
      },
      {
        text: "Fujian",
        value: "CN-35"
      },
      {
        text: "Gansu",
        value: "CN-62"
      },
      {
        text: "Guangdong",
        value: "CN-44"
      },
      {
        text: "Guizhou",
        value: "CN-52"
      },
      {
        text: "Hainan",
        value: "CN-46"
      },
      {
        text: "Hebei",
        value: "CN-13"
      },
      {
        text: "Heilongjiang",
        value: "CN-23"
      },
      {
        text: "Henan",
        value: "CN-41"
      },
      {
        text: "Hubei",
        value: "CN-42"
      },
      {
        text: "Hunan",
        value: "CN-43"
      },
      {
        text: "Jiangsu",
        value: "CN-32"
      },
      {
        text: "Jiangxi",
        value: "CN-36"
      },
      {
        text: "Jilin",
        value: "CN-22"
      },
      {
        text: "Liaoning",
        value: "CN-21"
      },
      {
        text: "Qinghai",
        value: "CN-63"
      },
      {
        text: "Shaanxi",
        value: "CN-61"
      },
      {
        text: "Shandong",
        value: "CN-37"
      },
      {
        text: "Shanxi",
        value: "CN-14"
      },
      {
        text: "Sichuan",
        value: "CN-51"
      },
      {
        text: "Taiwan",
        value: "CN-71"
      },
      {
        text: "Yunnan",
        value: "CN-53"
      },
      {
        text: "Zhejiang",
        value: "CN-33"
      },
      {
        text: "Guangxi",
        value: "CN-45"
      },
      {
        text: "Nei Monggol",
        value: "CN-15"
      },
      {
        text: "Ningxia",
        value: "CN-64"
      },
      {
        text: "Xinjiang",
        value: "CN-65"
      },
      {
        text: "Xizang",
        value: "CN-54"
      },
      {
        text: "Hong Kong",
        value: "CN-91"
      }
    ]
  },
  {
    text: "Christmas Island",
    value: "CX",
    divisions: [
      {
        text: "Christmas Island",
        value: "CX-CX"
      }
    ]
  },
  {
    text: "Cocos (Keeling) Islands",
    value: "CC",
    divisions: [
      {
        text: "Cocos (Keeling) Islands",
        value: "CC-CC"
      }
    ]
  },
  {
    text: "Colombia",
    value: "CO",
    divisions: [
      {
        text: "Distrito Capital de Santa Fe de Bogota",
        value: "CO-DC"
      },
      {
        text: "Amazonas",
        value: "CO-AMA"
      },
      {
        text: "Antioguia",
        value: "CO-ANT"
      },
      {
        text: "Arauca",
        value: "CO-ARA"
      },
      {
        text: "Atlántico",
        value: "CO-ATL"
      },
      {
        text: "Bolívar",
        value: "CO-BOL"
      },
      {
        text: "Boyacá",
        value: "CO-BOY"
      },
      {
        text: "Caldas",
        value: "CO-CAL"
      },
      {
        text: "Caquetá",
        value: "CO-CAQ"
      },
      {
        text: "Casanare",
        value: "CO-CAS"
      },
      {
        text: "Cauca",
        value: "CO-CAU"
      },
      {
        text: "Cesar",
        value: "CO-CES"
      },
      {
        text: "Córdoba",
        value: "CO-COR"
      },
      {
        text: "Cundinamarca",
        value: "CO-CUN"
      },
      {
        text: "Chocó",
        value: "CO-CHO"
      },
      {
        text: "Guainía",
        value: "CO-GUA"
      },
      {
        text: "Guaviare",
        value: "CO-GUV"
      },
      {
        text: "Huila",
        value: "CO-HUI"
      },
      {
        text: "La Guajira",
        value: "CO-LAG"
      },
      {
        text: "Magdalena",
        value: "CO-MAG"
      },
      {
        text: "Meta",
        value: "CO-MET"
      },
      {
        text: "Nariño",
        value: "CO-NAR"
      },
      {
        text: "Norte de Santander",
        value: "CO-NSA"
      },
      {
        text: "Putumayo",
        value: "CO-PUT"
      },
      {
        text: "Quindío",
        value: "CO-QUI"
      },
      {
        text: "Risaralda",
        value: "CO-RIS"
      },
      {
        text: "San Andrés, Providencia y Santa Catalina",
        value: "CO-SAP"
      },
      {
        text: "Santander",
        value: "CO-SAN"
      },
      {
        text: "Sucre",
        value: "CO-SUC"
      },
      {
        text: "Tolima",
        value: "CO-TOL"
      },
      {
        text: "Valle del Cauca",
        value: "CO-VAC"
      },
      {
        text: "Vaupés",
        value: "CO-VAU"
      },
      {
        text: "Vichada",
        value: "CO-VID"
      }
    ]
  },
  {
    text: "Comoros",
    value: "KM",
    divisions: [
      {
        text: "Anjouan (Ndzouani)",
        value: "KM-A"
      },
      {
        text: "Grande Comore (Ngazidja)",
        value: "KM-G"
      },
      {
        text: "Mohéli (Moili)",
        value: "KM-M"
      }
    ]
  },
  {
    text: "Congo",
    value: "CG",
    divisions: [
      {
        text: "Brazzaville",
        value: "CG-BZV"
      },
      {
        text: "Bouenza",
        value: "CG-11"
      },
      {
        text: "Cuvette",
        value: "CG-8"
      },
      {
        text: "Cuvette-Ouest",
        value: "CG-15"
      },
      {
        text: "Kouilou",
        value: "CG-5"
      },
      {
        text: "Lékoumou",
        value: "CG-2"
      },
      {
        text: "Likouala",
        value: "CG-7"
      },
      {
        text: "Niari",
        value: "CG-9"
      },
      {
        text: "Plateaux",
        value: "CG-14"
      },
      {
        text: "Pool",
        value: "CG-12"
      },
      {
        text: "Sangha",
        value: "CG-13"
      }
    ]
  },
  {
    text: "Zaire",
    value: "CD",
    divisions: [
      {
        text: "Kinshasa",
        value: "CD-KN"
      },
      {
        text: "Bandundu",
        value: "CD-BN"
      },
      {
        text: "Bas-Congo",
        value: "CD-BC"
      },
      {
        text: "Équateur",
        value: "CD-EQ"
      },
      {
        text: "Haut-Congo",
        value: "CD-HC"
      },
      {
        text: "Kasai-Occidental",
        value: "CD-KW"
      },
      {
        text: "Kasai-Oriental",
        value: "CD-KE"
      },
      {
        text: "Katanga",
        value: "CD-KA"
      },
      {
        text: "Maniema",
        value: "CD-MA"
      },
      {
        text: "Nord-Kivu",
        value: "CD-NK"
      },
      {
        text: "Sud-Kivu",
        value: "CD-SK"
      }
    ]
  },
  {
    text: "Cook Islands",
    value: "CK",
    divisions: [
      {
        text: "Cook Islands",
        value: "CK-CK"
      }
    ]
  },
  {
    text: "Costa Rica",
    value: "CR",
    divisions: [
      {
        text: "Alajuela",
        value: "CR-A"
      },
      {
        text: "Cartago",
        value: "CR-C"
      },
      {
        text: "Guanacaste",
        value: "CR-G"
      },
      {
        text: "Heredia",
        value: "CR-H"
      },
      {
        text: "Limón",
        value: "CR-L"
      },
      {
        text: "Puntarenas",
        value: "CR-P"
      },
      {
        text: "San José",
        value: "CR-SJ"
      }
    ]
  },
  {
    text: "Cote D'ivoire (Ivory Coast)",
    value: "CI",
    divisions: [
      {
        text: "18 Montagnes (Région des)",
        value: "CI-06"
      },
      {
        text: "Agnébi (Région de l')",
        value: "CI-16"
      },
      {
        text: "Bas-Sassandra (Région du)",
        value: "CI-09"
      },
      {
        text: "Denguélé (Région du)",
        value: "CI-10"
      },
      {
        text: "Haut-Sassandra (Région du)",
        value: "CI-02"
      },
      {
        text: "Lacs (Région des)",
        value: "CI-07"
      },
      {
        text: "Lagunes (Région des)",
        value: "CI-01"
      },
      {
        text: "Marahoué (Région de la)",
        value: "CI-12"
      },
      {
        text: "Moyen-Comoé (Région du)",
        value: "CI-05"
      },
      {
        text: "Nzi-Comoé (Région)",
        value: "CI-11"
      },
      {
        text: "Savanes (Région des)",
        value: "CI-03"
      },
      {
        text: "Sud-Bandama (Région du)",
        value: "CI-15"
      },
      {
        text: "Sud-Comoé (Région du)",
        value: "CI-13"
      },
      {
        text: "Vallée du Bandama (Région de la)",
        value: "CI-04"
      },
      {
        text: "Worodougou (Région du)",
        value: "CI-14"
      },
      {
        text: "Zanzan (Région du)",
        value: "CI-08"
      }
    ]
  },
  {
    text: "Croatia (Hrvatska)",
    value: "HR",
    divisions: [
      {
        text: "Bjelovarsko-bilogorska županija",
        value: "HR-07"
      },
      {
        text: "Brodsko-posavska županija",
        value: "HR-12"
      },
      {
        text: "Dubrovačko-neretvanska županija",
        value: "HR-19"
      },
      {
        text: "Istarska županija",
        value: "HR-18"
      },
      {
        text: "Karlovačka županija",
        value: "HR-04"
      },
      {
        text: "Koprivničkco-križevačka županija",
        value: "HR-06"
      },
      {
        text: "Krapinsko-zagorska županija",
        value: "HR-02"
      },
      {
        text: "Ličko-senjska županija",
        value: "HR-09"
      },
      {
        text: "Medjimurska županija",
        value: "HR-20"
      },
      {
        text: "Osječko-baranjska županija",
        value: "HR-14"
      },
      {
        text: "Požeško-slavonska županija",
        value: "HR-11"
      },
      {
        text: "Primorsko-goranska županija",
        value: "HR-08"
      },
      {
        text: "Sisaško-moslavačka županija",
        value: "HR-03"
      },
      {
        text: "Splitsko-dalmatinska županija",
        value: "HR-17"
      },
      {
        text: "Šibensko-kninska županija",
        value: "HR-15"
      },
      {
        text: "Varaždinska županija",
        value: "HR-05"
      },
      {
        text: "Virovitičko-podravska županija",
        value: "HR-10"
      },
      {
        text: "Vukovarsko-srijemska županija",
        value: "HR-16"
      },
      {
        text: "Zadarska županija",
        value: "HR-13"
      },
      {
        text: "Zagrebačka županija",
        value: "HR-01"
      }
    ]
  },
  {
    text: "Cuba",
    value: "CU",
    divisions: [
      {
        text: "Camagüey",
        value: "CU-09"
      },
      {
        text: "Ciudad de La Habana",
        value: "CU-03"
      },
      {
        text: "Granma",
        value: "CU-12"
      },
      {
        text: "Guantánamo",
        value: "CU-14"
      },
      {
        text: "Holguín",
        value: "CU-11"
      },
      {
        text: "La Habana",
        value: "CU-02"
      },
      {
        text: "Las Tunas",
        value: "CU-10"
      },
      {
        text: "Matanzas",
        value: "CU-04"
      },
      {
        text: "Pinar del Río",
        value: "CU-01"
      },
      {
        text: "Sancti Spíritus",
        value: "CU-07"
      },
      {
        text: "Santiago de Cuba",
        value: "CU-13"
      },
      {
        text: "Villa Clara",
        value: "CU-05"
      },
      {
        text: "Isla de la Juventud",
        value: "CU-99"
      }
    ]
  },
  {
    text: "Cyprus",
    value: "CY",
    divisions: [
      {
        text: "Ammochostos (Mağusa)",
        value: "CY-04"
      },
      {
        text: "Keryneia (Girne)",
        value: "CY-06"
      },
      {
        text: "Larnaka",
        value: "CY-03"
      },
      {
        text: "Lefkosia (Lefkoşa)",
        value: "CY-01"
      },
      {
        text: "Lemesos (Leymosun)",
        value: "CY-02"
      },
      {
        text: "Pafos (Baf)",
        value: "CY-05"
      }
    ]
  },
  {
    text: "Czech Republic",
    value: "CZ",
    divisions: [
      {
        text: "Praha",
        value: "CZ-PRG"
      },
      {
        text: "Jihočeský kraj",
        value: "CZ-CJC"
      },
      {
        text: "Jihomoravský kraj",
        value: "CZ-CJM"
      },
      {
        text: "Severočeský kraj",
        value: "CZ-CSC"
      },
      {
        text: "Severomoravský kraj",
        value: "CZ-CSM"
      },
      {
        text: "Středočeský kraj",
        value: "CZ-CST"
      },
      {
        text: "Východočeský kraj",
        value: "CZ-CVC"
      },
      {
        text: "Západočeský kraj",
        value: "CZ-CZC"
      }
    ]
  },
  {
    text: "Denmark",
    value: "DK",
    divisions: [
      {
        text: "Frederiksberg",
        value: "DK-147"
      },
      {
        text: "Kǿbenhavn",
        value: "DK-101"
      },
      {
        text: "Bornholm",
        value: "DK-040"
      },
      {
        text: "Frederiksborg",
        value: "DK-020"
      },
      {
        text: "Fyn",
        value: "DK-042"
      },
      {
        text: "Kǿbenhavn",
        value: "DK-015"
      },
      {
        text: "Nordjylland",
        value: "DK-080"
      },
      {
        text: "Ribe",
        value: "DK-055"
      },
      {
        text: "Ringkǿbing",
        value: "DK-065"
      },
      {
        text: "Roskilde",
        value: "DK-025"
      },
      {
        text: "Storstrǿm",
        value: "DK-035"
      },
      {
        text: "Sǿnderjylland",
        value: "DK-050"
      },
      {
        text: "Vejle",
        value: "DK-060"
      },
      {
        text: "Vestsjælland",
        value: "DK-030"
      },
      {
        text: "Viborg",
        value: "DK-076"
      },
      {
        text: "Århus",
        value: "DK-070"
      }
    ]
  },
  {
    text: "Djibouti",
    value: "DJ",
    divisions: [
      {
        text: "Ali Sabieh",
        value: "DJ-AS"
      },
      {
        text: "Dikhil",
        value: "DJ-DI"
      },
      {
        text: "Djibouti",
        value: "DJ-DJ"
      },
      {
        text: "Obock",
        value: "DJ-OB"
      },
      {
        text: "Tadjoura",
        value: "DJ-TA"
      }
    ]
  },
  {
    text: "Dominica",
    value: "DM",
    divisions: [
      {
        text: "Dominica",
        value: "DM-DM"
      }
    ]
  },
  {
    text: "Dominican Republic",
    value: "DO",
    divisions: [
      {
        text: "Distrito National (Santo Domingo)",
        value: "DO-DN"
      },
      {
        text: "Azua",
        value: "DO-AZ"
      },
      {
        text: "Bahoruco",
        value: "DO-BR"
      },
      {
        text: "Barahona",
        value: "DO-BH"
      },
      {
        text: "Dajabón",
        value: "DO-DA"
      },
      {
        text: "Duarte",
        value: "DO-DU"
      },
      {
        text: "El Seibo",
        value: "DO-SE"
      },
      {
        text: "La Estrelleta [Elías Piña]",
        value: "DO-EP"
      },
      {
        text: "Hato Mayor",
        value: "DO-HM"
      },
      {
        text: "Independencia",
        value: "DO-IN"
      },
      {
        text: "La Altagracia",
        value: "DO-AL"
      },
      {
        text: "La Romana",
        value: "DO-RO"
      },
      {
        text: "La Vega",
        value: "DO-VE"
      },
      {
        text: "María Trinidad Sánchez",
        value: "DO-MT"
      },
      {
        text: "Monseñor Nouel",
        value: "DO-MN"
      },
      {
        text: "Monte Cristi",
        value: "DO-MC"
      },
      {
        text: "Monte Plata",
        value: "DO-MP"
      },
      {
        text: "Pedernales",
        value: "DO-PN"
      },
      {
        text: "Peravia",
        value: "DO-PR"
      },
      {
        text: "Puerto Plata",
        value: "DO-PP"
      },
      {
        text: "Salcedo",
        value: "DO-SC"
      },
      {
        text: "Samaná",
        value: "DO-SM"
      },
      {
        text: "Sanchez Ramírez",
        value: "DO-SZ"
      },
      {
        text: "San Cristóbal",
        value: "DO-CR"
      },
      {
        text: "San Juan",
        value: "DO-JU"
      },
      {
        text: "San Pedro de Macorís",
        value: "DO-PM"
      },
      {
        text: "Santiago",
        value: "DO-ST"
      },
      {
        text: "Santiago Rodríguez",
        value: "DO-SR"
      },
      {
        text: "Valverde",
        value: "DO-VA"
      }
    ]
  },
  {
    text: "East Timor",
    value: "TP",
    divisions: [
      {
        text: "East Timor",
        value: "TP-TP"
      }
    ]
  },
  {
    text: "Ecuador",
    value: "EC",
    divisions: [
      {
        text: "Azuay",
        value: "EC-A"
      },
      {
        text: "Bolívar",
        value: "EC-B"
      },
      {
        text: "Cañar",
        value: "EC-F"
      },
      {
        text: "Carchi",
        value: "EC-C"
      },
      {
        text: "Cotopaxi",
        value: "EC-X"
      },
      {
        text: "Chimborazo",
        value: "EC-H"
      },
      {
        text: "El Oro",
        value: "EC-O"
      },
      {
        text: "Esmeraldas",
        value: "EC-E"
      },
      {
        text: "Galápagos",
        value: "EC-W"
      },
      {
        text: "Guayas",
        value: "EC-G"
      },
      {
        text: "Imbabura",
        value: "EC-I"
      },
      {
        text: "Loja",
        value: "EC-L"
      },
      {
        text: "Los Ríos",
        value: "EC-R"
      },
      {
        text: "Manabí",
        value: "EC-M"
      },
      {
        text: "Morona-Santiago",
        value: "EC-S"
      },
      {
        text: "Napo",
        value: "EC-N"
      },
      {
        text: "Pastaza",
        value: "EC-Y"
      },
      {
        text: "Pichincha",
        value: "EC-P"
      },
      {
        text: "Sucumbíos",
        value: "EC-U"
      },
      {
        text: "Tungurahua",
        value: "EC-T"
      },
      {
        text: "Zarnora-Chinchipe",
        value: "EC-Z"
      }
    ]
  },
  {
    text: "Egypt",
    value: "EG",
    divisions: [
      {
        text: "Ad Daqahlīyah",
        value: "EG-DK"
      },
      {
        text: "Al Baḩr al Aḩmar",
        value: "EG-BA"
      },
      {
        text: "Al Buḩayrah",
        value: "EG-BH"
      },
      {
        text: "Al Fayyūm",
        value: "EG-FYM"
      },
      {
        text: "Al Gharbīyah",
        value: "EG-GH"
      },
      {
        text: "Al Iskandarīyah",
        value: "EG-ALX"
      },
      {
        text: "Al Ismā‘īlīyah",
        value: "EG-IS"
      },
      {
        text: "Al Jīzah",
        value: "EG-GZ"
      },
      {
        text: "Al Minūfīyah",
        value: "EG-MNF"
      },
      {
        text: "Al Minyā",
        value: "EG-MN"
      },
      {
        text: "Al Qāhirah",
        value: "EG-C"
      },
      {
        text: "Al Qalyūbīyah",
        value: "EG-KB"
      },
      {
        text: "Al Wādī al Jadīd",
        value: "EG-WAD"
      },
      {
        text: "Ash Sharqīyah",
        value: "EG-SHR"
      },
      {
        text: "As Suways",
        value: "EG-SUZ"
      },
      {
        text: "Aswān",
        value: "EG-ASN"
      },
      {
        text: "Asyūţ",
        value: "EG-AST"
      },
      {
        text: "Banī Suwayf",
        value: "EG-BNS"
      },
      {
        text: "Būr Sa‘īd",
        value: "EG-PTS"
      },
      {
        text: "Dumyāţ",
        value: "EG-DT"
      },
      {
        text: "Janūb Sīnā'",
        value: "EG-JS"
      },
      {
        text: "Kafr ash Shaykh",
        value: "EG-KFS"
      },
      {
        text: "Maţrūḩ",
        value: "EG-MT"
      },
      {
        text: "Qinā",
        value: "EG-KN"
      },
      {
        text: "Shamāl Sīnā'",
        value: "EG-SIN"
      },
      {
        text: "Sūhāj",
        value: "EG-SHG"
      }
    ]
  },
  {
    text: "El Salvador",
    value: "SV",
    divisions: [
      {
        text: "Ahuachapán",
        value: "SV-AH"
      },
      {
        text: "Cabañas",
        value: "SV-CA"
      },
      {
        text: "Cuscatlán",
        value: "SV-CU"
      },
      {
        text: "Chalatenango",
        value: "SV-CH"
      },
      {
        text: "La Libertad",
        value: "SV-LI"
      },
      {
        text: "La Paz",
        value: "SV-PA"
      },
      {
        text: "La Unión",
        value: "SV-UN"
      },
      {
        text: "Morazán",
        value: "SV-MO"
      },
      {
        text: "San Miguel",
        value: "SV-SM"
      },
      {
        text: "San Salvador",
        value: "SV-SS"
      },
      {
        text: "Santa Ana",
        value: "SV-SA"
      },
      {
        text: "San Vicente",
        value: "SV-SV"
      },
      {
        text: "Sonsonate",
        value: "SV-SO"
      },
      {
        text: "Usulután",
        value: "SV-SU"
      }
    ]
  },
  {
    text: "Equatorial Guinea",
    value: "GQ",
    divisions: [
      {
        text: "Región Continental",
        value: "GQ-C"
      },
      {
        text: "Centro Sur",
        value: "GQ-CS"
      },
      {
        text: "Kie-Ntem",
        value: "GQ-KN"
      },
      {
        text: "Litoral",
        value: "GQ-LI"
      },
      {
        text: "Wele-Nzás",
        value: "GQ-WN"
      },
      {
        text: "Región Insular",
        value: "GQ-I"
      },
      {
        text: "Annobón",
        value: "GQ-AN"
      },
      {
        text: "Bioko Norte",
        value: "GQ-BN"
      },
      {
        text: "Bioko Sur",
        value: "GQ-BS"
      }
    ]
  },
  {
    text: "Eritrea",
    value: "ER",
    divisions: [
      {
        text: "Akele Guzai [Akalä Guzay]",
        value: "ER-AG"
      },
      {
        text: "Asmara [Asmära]",
        value: "ER-AS"
      },
      {
        text: "Barka",
        value: "ER-BA"
      },
      {
        text: "Denkalia [Dänkali]",
        value: "ER-DE"
      },
      {
        text: "Gash-Setit [Gaš enna Sätit]",
        value: "ER-GS"
      },
      {
        text: "Hamasien [Hamasén]",
        value: "ER-HA"
      },
      {
        text: "Sahel",
        value: "ER-SA"
      },
      {
        text: "Semhar [Sämhar]",
        value: "ER-SM"
      },
      {
        text: "Senhit [Sänhet]",
        value: "ER-SN"
      },
      {
        text: "Seraye [Särayé]",
        value: "ER-SR"
      }
    ]
  },
  {
    text: "Estonia",
    value: "EE",
    divisions: [
      {
        text: "Harjumaa",
        value: "EE-37"
      },
      {
        text: "Hiiumaa",
        value: "EE-39"
      },
      {
        text: "Ida-Virumaa",
        value: "EE-44"
      },
      {
        text: "Jōgevamaa",
        value: "EE-49"
      },
      {
        text: "Järvamaa",
        value: "EE-51"
      },
      {
        text: "Läänemaa",
        value: "EE-57"
      },
      {
        text: "Lääne-Virumaa",
        value: "EE-59"
      },
      {
        text: "Pōlvamaa",
        value: "EE-65"
      },
      {
        text: "Pärnumaa",
        value: "EE-67"
      },
      {
        text: "Raplamaa",
        value: "EE-70"
      },
      {
        text: "Saaremaa",
        value: "EE-74"
      },
      {
        text: "Tartumaa",
        value: "EE-78"
      },
      {
        text: "Valgamaa",
        value: "EE-82"
      },
      {
        text: "Viljandimaa",
        value: "EE-84"
      },
      {
        text: "Vōrumaa",
        value: "EE-86"
      }
    ]
  },
  {
    text: "Ethiopia",
    value: "ET",
    divisions: [
      {
        text: "Addis Ababa [Addis Abeba]",
        value: "ET-AA"
      },
      {
        text: "Afar",
        value: "ET-AF"
      },
      {
        text: "Amara [Amhara]",
        value: "ET-AM"
      },
      {
        text: "Benshangul-Gumaz [Bénishangul]",
        value: "ET-BE"
      },
      {
        text: "Gambela Peoples [Gambéla]",
        value: "ET-GA"
      },
      {
        text: "Harari People [Harer]",
        value: "ET-HA"
      },
      {
        text: "Oromia [Oromo]",
        value: "ET-OR"
      },
      {
        text: "Somali",
        value: "ET-SO"
      },
      {
        text: "Southern Nations, Nationalities and Peoples",
        value: "ET-SN"
      },
      {
        text: "Tigrai [Tegré]",
        value: "ET-TI"
      }
    ]
  },
  {
    text: "Falkland Islands (Malvinas)",
    value: "FK",
    divisions: [
      {
        text: "Falkland Islands (Malvinas)",
        value: "FK-FK"
      }
    ]
  },
  {
    text: "Faroe Islands",
    value: "FO",
    divisions: [
      {
        text: "Faroe Islands",
        value: "FO-FO"
      }
    ]
  },
  {
    text: "Fiji",
    value: "FJ",
    divisions: [
      {
        text: "Central",
        value: "FJ-C"
      },
      {
        text: "Eastern",
        value: "FJ-E"
      },
      {
        text: "Northern",
        value: "FJ-N"
      },
      {
        text: "Western",
        value: "FJ-W"
      },
      {
        text: "Rotuma",
        value: "FJ-R"
      }
    ]
  },
  {
    text: "Finland",
    value: "FI",
    divisions: [
      {
        text: "Ahvenanmaan lääni",
        value: "FI-AL"
      },
      {
        text: "Etelä-Suomen lääni",
        value: "FI-ES"
      },
      {
        text: "Itä-Suomen lääni",
        value: "FI-IS"
      },
      {
        text: "Lapin lääni",
        value: "FI-LL"
      },
      {
        text: "Länsi-Suomen lääni",
        value: "FI-LS"
      },
      {
        text: "Oulun lääni",
        value: "FI-OL"
      }
    ]
  },
  {
    text: "France",
    value: "FR",
    divisions: [
      {
        text: "Alsace",
        value: "FR-A"
      },
      {
        text: "Bas-Rhin",
        value: "FR-67"
      },
      {
        text: "Haut-Rhin",
        value: "FR-68"
      },
      {
        text: "Aquitaine",
        value: "FR-B"
      },
      {
        text: "Deux-Sèvres",
        value: "FR-79"
      },
      {
        text: "Dordogne",
        value: "FR-24"
      },
      {
        text: "Gironde",
        value: "FR-33"
      },
      {
        text: "Landes",
        value: "FR-40"
      },
      {
        text: "Lot-et-Garonne",
        value: "FR-47"
      },
      {
        text: "Pyrénées-Atlantiques",
        value: "FR-64"
      },
      {
        text: "Auvergne",
        value: "FR-C"
      },
      {
        text: "Allier",
        value: "FR-03"
      },
      {
        text: "Cantal",
        value: "FR-15"
      },
      {
        text: "Haute-Loire",
        value: "FR-43"
      },
      {
        text: "Puy-de-Dôme",
        value: "FR-63"
      },
      {
        text: "Basse-Normandie",
        value: "FR-P"
      },
      {
        text: "Calvados",
        value: "FR-14"
      },
      {
        text: "Manche",
        value: "FR-50"
      },
      {
        text: "Orne",
        value: "FR-61"
      },
      {
        text: "Bourgogne",
        value: "FR-D"
      },
      {
        text: "Côte-d'Or",
        value: "FR-21"
      },
      {
        text: "Nièvre",
        value: "FR-58"
      },
      {
        text: "Saône-et-Loire",
        value: "FR-71"
      },
      {
        text: "Yonne",
        value: "FR-89"
      },
      {
        text: "Bretagne",
        value: "FR-E"
      },
      {
        text: "Cotes-d'Armor",
        value: "FR-22"
      },
      {
        text: "Finistère",
        value: "FR-29"
      },
      {
        text: "Ille-et-Vilaine",
        value: "FR-35"
      },
      {
        text: "Morbihan",
        value: "FR-56"
      },
      {
        text: "Centre",
        value: "FR-F"
      },
      {
        text: "Cher",
        value: "FR-18"
      },
      {
        text: "Eure-et-Loir",
        value: "FR-28"
      },
      {
        text: "Indre",
        value: "FR-36"
      },
      {
        text: "Indre-et-Loire",
        value: "FR-37"
      },
      {
        text: "Loir-et-Cher",
        value: "FR-41"
      },
      {
        text: "Loiret",
        value: "FR-45"
      },
      {
        text: "Champagne-Ardenne",
        value: "FR-G"
      },
      {
        text: "Ardennes",
        value: "FR-08"
      },
      {
        text: "Aube",
        value: "FR-10"
      },
      {
        text: "Haute-Marne",
        value: "FR-52"
      },
      {
        text: "Marne",
        value: "FR-51"
      },
      {
        text: "Corse",
        value: "FR-H"
      },
      {
        text: "Corse-du-Sud",
        value: "FR-2A"
      },
      {
        text: "Haute-Corse",
        value: "FR-2B"
      },
      {
        text: "Franche-Comté",
        value: "FR-I"
      },
      {
        text: "Doubs",
        value: "FR-25"
      },
      {
        text: "Haute-Saône",
        value: "FR-70"
      },
      {
        text: "Jura",
        value: "FR-39"
      },
      {
        text: "Territoire de Belfort",
        value: "FR-90"
      },
      {
        text: "Haute-Normandie",
        value: "FR-Q"
      },
      {
        text: "Eure",
        value: "FR-27"
      },
      {
        text: "Seine-Maritime",
        value: "FR-76"
      },
      {
        text: "Île-de-France",
        value: "FR-J"
      },
      {
        text: "Essonne",
        value: "FR-91"
      },
      {
        text: "Hauts-de-Seine",
        value: "FR-92"
      },
      {
        text: "Paris",
        value: "FR-75"
      },
      {
        text: "Seine-et-Marne",
        value: "FR-77"
      },
      {
        text: "Seine-Saint-Denis",
        value: "FR-93"
      },
      {
        text: "Val-de-Marne",
        value: "FR-94"
      },
      {
        text: "Val-d'Oise",
        value: "FR-95"
      },
      {
        text: "Yvelines",
        value: "FR-78"
      },
      {
        text: "Languedoc-Roussillon",
        value: "FR-K"
      },
      {
        text: "Aude",
        value: "FR-11"
      },
      {
        text: "Gard",
        value: "FR-30"
      },
      {
        text: "Hérault",
        value: "FR-34"
      },
      {
        text: "Lozère",
        value: "FR-48"
      },
      {
        text: "Pyrénées-Orientales",
        value: "FR-66"
      },
      {
        text: "Limousin",
        value: "FR-L"
      },
      {
        text: "Corrèze",
        value: "FR-19"
      },
      {
        text: "Creuse",
        value: "FR-23"
      },
      {
        text: "Haute-Vienne",
        value: "FR-87"
      },
      {
        text: "Lorraine",
        value: "FR-M"
      },
      {
        text: "Meurthe-et-Moselle",
        value: "FR-54"
      },
      {
        text: "Meuse",
        value: "FR-55"
      },
      {
        text: "Moselle",
        value: "FR-57"
      },
      {
        text: "Vosges",
        value: "FR-88"
      },
      {
        text: "Midi-Pyrénées",
        value: "FR-N"
      },
      {
        text: "Ariège",
        value: "FR-09"
      },
      {
        text: "Aveyron",
        value: "FR-12"
      },
      {
        text: "Gers",
        value: "FR-32"
      },
      {
        text: "Haute-Garonne",
        value: "FR-31"
      },
      {
        text: "Hautes-Pyrénées",
        value: "FR-65"
      },
      {
        text: "Lot",
        value: "FR-46"
      },
      {
        text: "Tarn",
        value: "FR-81"
      },
      {
        text: "Tarn-et-Garonne",
        value: "FR-82"
      },
      {
        text: "Nord-Pas-de-Calais",
        value: "FR-O"
      },
      {
        text: "Nord",
        value: "FR-59"
      },
      {
        text: "Pas-de-Calais",
        value: "FR-62"
      },
      {
        text: "Pays de la Loire",
        value: "FR-R"
      },
      {
        text: "Loire-Atlantique",
        value: "FR-44"
      },
      {
        text: "Maine-et-Loire",
        value: "FR-49"
      },
      {
        text: "Mayenne",
        value: "FR-53"
      },
      {
        text: "Sarthe",
        value: "FR-72"
      },
      {
        text: "Vendée",
        value: "FR-85"
      },
      {
        text: "Picardie",
        value: "FR-S"
      },
      {
        text: "Aisne",
        value: "FR-02"
      },
      {
        text: "Oise",
        value: "FR-60"
      },
      {
        text: "Somme",
        value: "FR-80"
      },
      {
        text: "Poitou-Charentes",
        value: "FR-T"
      },
      {
        text: "Charente",
        value: "FR-16"
      },
      {
        text: "Charente-Maritime",
        value: "FR-17"
      },
      {
        text: "Vienne",
        value: "FR-86"
      },
      {
        text: "Provence-Alpes-Côte d'Azur",
        value: "FR-U"
      },
      {
        text: "Alpes-de-Haute-Provence",
        value: "FR-04"
      },
      {
        text: "Alpes-Maritimes",
        value: "FR-06"
      },
      {
        text: "Bauches-du-Rhône",
        value: "FR-13"
      },
      {
        text: "Hautes-Alpes",
        value: "FR-05"
      },
      {
        text: "Var",
        value: "FR-83"
      },
      {
        text: "Vaucluse",
        value: "FR-84"
      },
      {
        text: "Rhône-Alpes",
        value: "FR-V"
      },
      {
        text: "Ain",
        value: "FR-01"
      },
      {
        text: "Ardèche",
        value: "FR-07"
      },
      {
        text: "Drôme",
        value: "FR-26"
      },
      {
        text: "Haute-Savoie",
        value: "FR-74"
      },
      {
        text: "Isère",
        value: "FR-38"
      },
      {
        text: "Loire",
        value: "FR-42"
      },
      {
        text: "Rhône",
        value: "FR-69"
      },
      {
        text: "Savoie",
        value: "FR-73"
      },
      {
        text: "Guadeloupe",
        value: "FR-GP"
      },
      {
        text: "Guyane (francaise)",
        value: "FR-GF"
      },
      {
        text: "Martinique",
        value: "FR-MQ"
      },
      {
        text: "Réunion",
        value: "FR-RE"
      },
      {
        text: "Mayotte",
        value: "FR-YT"
      },
      {
        text: "Saint-Pierre-et-Miquelon",
        value: "FR-PM"
      },
      {
        text: "Nouvelle-Calédonie",
        value: "FR-NC"
      },
      {
        text: "Polynésie française",
        value: "FR-PF"
      },
      {
        text: "Terres Australes",
        value: "FR-TF"
      },
      {
        text: "Wallis et Futuna",
        value: "FR-WF"
      }
    ]
  },
  {
    text: "French Guiana",
    value: "GF",
    divisions: [
      {
        text: "French Guiana",
        value: "GF-GF"
      }
    ]
  },
  {
    text: "French Polynesia",
    value: "PF",
    divisions: [
      {
        text: "French Polynesia",
        value: "PF-PF"
      }
    ]
  },
  {
    text: "French Southern Territories",
    value: "TF",
    divisions: [
      {
        text: "French Southern Territories",
        value: "TF-TF"
      }
    ]
  },
  {
    text: "Gabon",
    value: "GA",
    divisions: [
      {
        text: "Estuaire",
        value: "GA-1"
      },
      {
        text: "Haut-Ogooué",
        value: "GA-2"
      },
      {
        text: "Moyen-Ogooué",
        value: "GA-3"
      },
      {
        text: "Ngounié",
        value: "GA-4"
      },
      {
        text: "Nyanga",
        value: "GA-5"
      },
      {
        text: "Ogooué-Ivindo",
        value: "GA-6"
      },
      {
        text: "Ogooué-Lolo",
        value: "GA-7"
      },
      {
        text: "Ogooué-Maritime",
        value: "GA-8"
      },
      {
        text: "Woleu-Ntem",
        value: "GA-9"
      }
    ]
  },
  {
    text: "Gambia",
    value: "GM",
    divisions: [
      {
        text: "Banjul",
        value: "GM-B"
      },
      {
        text: "Lower River",
        value: "GM-L"
      },
      {
        text: "MacCarthy Island",
        value: "GM-M"
      },
      {
        text: "North Bank",
        value: "GM-N"
      },
      {
        text: "Upper River",
        value: "GM-U"
      },
      {
        text: "Western",
        value: "GM-W"
      }
    ]
  },
  {
    text: "Georgia",
    value: "GE",
    divisions: [
      {
        text: "Ap'khazet'is Avtonomiuri Respublika [Abkhazia]",
        value: "GE-AB"
      },
      {
        text: "Acharis Avtonomiuri Respublika [Ajaria]",
        value: "GE-AJ"
      },
      {
        text: "Bat'umi",
        value: "GE-BUS"
      },
      {
        text: "Chiat'ura",
        value: "GE-CHI"
      },
      {
        text: "Gagra",
        value: "GE-GAG"
      },
      {
        text: "Gori",
        value: "GE-GOR"
      },
      {
        text: "K'ut'aisi",
        value: "GE-KUT"
      },
      {
        text: "P'ot'i",
        value: "GE-PTI"
      },
      {
        text: "Rust'avi",
        value: "GE-RUS"
      },
      {
        text: "Sokhumi",
        value: "GE-SUI"
      },
      {
        text: "T'bilisi",
        value: "GE-TBS"
      },
      {
        text: "Tqibuli",
        value: "GE-TQI"
      },
      {
        text: "Tqvarch'eli",
        value: "GE-TQV"
      },
      {
        text: "Tsqalmbo",
        value: "GE-TSQ"
      },
      {
        text: "Zugdidi",
        value: "GE-ZUG"
      },
      {
        text: "Abashis Raioni",
        value: "GE-01"
      },
      {
        text: "Adigenis Raioni",
        value: "GE-02"
      },
      {
        text: "Akhalgoris Raioni",
        value: "GE-03"
      },
      {
        text: "Akhalk'alak'is Raioni",
        value: "GE-04"
      },
      {
        text: "Akhalts'ikhis Raioni",
        value: "GE-05"
      },
      {
        text: "Akhmetis Raioni",
        value: "GE-06"
      },
      {
        text: "Ambrolauris Raioni",
        value: "GE-07"
      },
      {
        text: "Aspindzis Raioni",
        value: "GE-08"
      },
      {
        text: "Baghdat'is Raioni",
        value: "GE-09"
      },
      {
        text: "Bolnisis Raioni",
        value: "GE-10"
      },
      {
        text: "Borjomis Raioni",
        value: "GE-11"
      },
      {
        text: "Ch'khorotsqus Raioni",
        value: "GE-12"
      },
      {
        text: "Ch'okhatauris Raioni",
        value: "GE-13"
      },
      {
        text: "Dedop'listsqaros Raioni",
        value: "GE-14"
      },
      {
        text: "Dmanisis Raioni",
        value: "GE-15"
      },
      {
        text: "Dushet'is Raioni",
        value: "GE-16"
      },
      {
        text: "Galis Raioni",
        value: "GE-17"
      },
      {
        text: "Gardabnis Raioni",
        value: "GE-18"
      },
      {
        text: "Goris Raioni",
        value: "GE-19"
      },
      {
        text: "Gudaut'is Raioni",
        value: "GE-20"
      },
      {
        text: "Gulrip'shis Raioni",
        value: "GE-21"
      },
      {
        text: "Gurjaanis Raioni",
        value: "GE-22"
      },
      {
        text: "Javis Raioni",
        value: "GE-23"
      },
      {
        text: "K'arelis Raioni",
        value: "GE-24"
      },
      {
        text: "Kaspis Raioni",
        value: "GE-25"
      },
      {
        text: "K'edis Raioni",
        value: "GE-26"
      },
      {
        text: "Kharagaulis Raioni",
        value: "GE-27"
      },
      {
        text: "Khashuris Raioni",
        value: "GE-28"
      },
      {
        text: "Khelvach'auris Raioni",
        value: "GE-29"
      },
      {
        text: "Khobis Raioni",
        value: "GE-30"
      },
      {
        text: "Khonis Raioni",
        value: "GE-31"
      },
      {
        text: "Khulos Raioni",
        value: "GE-32"
      },
      {
        text: "K'obuletis Raioni",
        value: "GE-33"
      },
      {
        text: "Lagodekhis Raioni",
        value: "GE-34"
      },
      {
        text: "Lanch'khut'is Raioni",
        value: "GE-35"
      },
      {
        text: "Lentekhis Raioni",
        value: "GE-36"
      },
      {
        text: "Marneulis Raioni",
        value: "GE-37"
      },
      {
        text: "Martvilis Raioni",
        value: "GE-38"
      },
      {
        text: "Mestiis Raioni",
        value: "GE-39"
      },
      {
        text: "Mts'khet'is Raioni",
        value: "GE-40"
      },
      {
        text: "Ninotsmindis Raioni",
        value: "GE-41"
      },
      {
        text: "Och'amch'iris Raioni",
        value: "GE-42"
      },
      {
        text: "Onis Raioni",
        value: "GE-43"
      },
      {
        text: "Ozurget'is Raioni",
        value: "GE-44"
      },
      {
        text: "Qazbegis Raioni",
        value: "GE-45"
      },
      {
        text: "Qvarlis Raioni",
        value: "GE-46"
      },
      {
        text: "Sach'kheris Raioni",
        value: "GE-47"
      },
      {
        text: "Sagarejos Raioni",
        value: "GE-48"
      },
      {
        text: "Samtrediis Raioni",
        value: "GE-49"
      },
      {
        text: "Senakis Raioni",
        value: "GE-50"
      },
      {
        text: "Shuakhevis Raioni",
        value: "GE-51"
      },
      {
        text: "Sighnaghis Raioni",
        value: "GE-52"
      },
      {
        text: "Sokhumis Raioni",
        value: "GE-53"
      },
      {
        text: "T'elavis Raioni",
        value: "GE-54"
      },
      {
        text: "T'erjolis Raioni",
        value: "GE-55"
      },
      {
        text: "T'et'ritsqaros Raioni",
        value: "GE-56"
      },
      {
        text: "T'ianet'is Raioni",
        value: "GE-57"
      },
      {
        text: "Ts'ageris Raioni",
        value: "GE-58"
      },
      {
        text: "Tsalenjikhis Raioni",
        value: "GE-59"
      },
      {
        text: "Tsalkis Raioni",
        value: "GE-60"
      },
      {
        text: "Vanis Raioni",
        value: "GE-61"
      },
      {
        text: "Zestap'onis Raioni",
        value: "GE-62"
      },
      {
        text: "Zugdidis Raioni",
        value: "GE-63"
      }
    ]
  },
  {
    text: "Germany",
    value: "DE",
    divisions: [
      {
        text: "Baden-Württemberg",
        value: "DE-BW"
      },
      {
        text: "Bayern",
        value: "DE-BY"
      },
      {
        text: "Berlin",
        value: "DE-BE"
      },
      {
        text: "Brandenburg",
        value: "DE-BB"
      },
      {
        text: "Bremen",
        value: "DE-HB"
      },
      {
        text: "Hamburg",
        value: "DE-HH"
      },
      {
        text: "Hessen",
        value: "DE-HE"
      },
      {
        text: "Mecklenburg-Vorpommern",
        value: "DE-MV"
      },
      {
        text: "Niedersachsen",
        value: "DE-NI"
      },
      {
        text: "Nordrhein-Westfalen",
        value: "DE-NW"
      },
      {
        text: "Rheinland-Pfalz",
        value: "DE-RP"
      },
      {
        text: "Saarland",
        value: "DE-SL"
      },
      {
        text: "Sachsen",
        value: "DE-SN"
      },
      {
        text: "Sachsen-Anhalt",
        value: "DE-ST"
      },
      {
        text: "Schleswig-Holstein",
        value: "DE-SH"
      },
      {
        text: "Thüringen",
        value: "DE-TH"
      }
    ]
  },
  {
    text: "Ghana",
    value: "GH",
    divisions: [
      {
        text: "Ashanti",
        value: "GH-AH"
      },
      {
        text: "Brong-Ahafo",
        value: "GH-BA"
      },
      {
        text: "Central",
        value: "GH-CP"
      },
      {
        text: "Eastern",
        value: "GH-EP"
      },
      {
        text: "Greater Accra",
        value: "GH-AA"
      },
      {
        text: "Northern",
        value: "GH-NP"
      },
      {
        text: "Upper East",
        value: "GH-UE"
      },
      {
        text: "Upper West",
        value: "GH-UW"
      },
      {
        text: "Volta",
        value: "GH-TV"
      },
      {
        text: "Western",
        value: "GH-WP"
      }
    ]
  },
  {
    text: "Gibraltar",
    value: "GI",
    divisions: [
      {
        text: "Gibraltar",
        value: "GI-GI"
      }
    ]
  },
  {
    text: "United Kingdom",
    value: "GB",
    divisions: [
      {
        text: "Channel Islands",
        value: "GB-CHA"
      },
      {
        text: "Guernsey [Guernesey]",
        value: "GB-GSY"
      },
      {
        text: "Jersey",
        value: "GB-JSY"
      },
      {
        text: "England",
        value: "GB-ENG"
      },
      {
        text: "Barking and Dagenham",
        value: "GB-BDG"
      },
      {
        text: "Barnet",
        value: "GB-BNE"
      },
      {
        text: "Barnsley",
        value: "GB-BNS"
      },
      {
        text: "Bath and North East Somerset",
        value: "GB-BAS"
      },
      {
        text: "Bedfordshire",
        value: "GB-BDF"
      },
      {
        text: "Bexley",
        value: "GB-BEX"
      },
      {
        text: "Birmingham (City of)",
        value: "GB-BIR"
      },
      {
        text: "Blackburn with Darwen",
        value: "GB-BBD"
      },
      {
        text: "Blackpool",
        value: "GB-BPL"
      },
      {
        text: "Bolton",
        value: "GB-BOL"
      },
      {
        text: "Bournemouth",
        value: "GB-BMH"
      },
      {
        text: "Bracknell Forest",
        value: "GB-BRC"
      },
      {
        text: "Bradford (City of)",
        value: "GB-BRD"
      },
      {
        text: "Brent",
        value: "GB-BEN"
      },
      {
        text: "Brighton and Hove",
        value: "GB-BNH"
      },
      {
        text: "Bristol, City of",
        value: "GB-BST"
      },
      {
        text: "Bromley",
        value: "GB-BRY"
      },
      {
        text: "Buckinghamshire",
        value: "GB-BKM"
      },
      {
        text: "Bury",
        value: "GB-BUR"
      },
      {
        text: "Calderdale",
        value: "GB-CLD"
      },
      {
        text: "Cambridgeshire",
        value: "GB-CAM"
      },
      {
        text: "Camden",
        value: "GB-CMD"
      },
      {
        text: "Cheshire",
        value: "GB-CHS"
      },
      {
        text: "Cornwall",
        value: "GB-CON"
      },
      {
        text: "Coventry (City of)",
        value: "GB-COV"
      },
      {
        text: "Croydon",
        value: "GB-CRY"
      },
      {
        text: "Cumbria",
        value: "GB-CMA"
      },
      {
        text: "Darlington",
        value: "GB-DAL"
      },
      {
        text: "Derby (City of)",
        value: "GB-DER"
      },
      {
        text: "Derbyshire",
        value: "GB-DBY"
      },
      {
        text: "Devon",
        value: "GB-DEV"
      },
      {
        text: "Doncaster",
        value: "GB-DNC"
      },
      {
        text: "Dorset",
        value: "GB-DOR"
      },
      {
        text: "Dudley",
        value: "GB-DUD"
      },
      {
        text: "Durharn",
        value: "GB-DUR"
      },
      {
        text: "Ealing",
        value: "GB-EAL"
      },
      {
        text: "East Riding of Yorkshire",
        value: "GB-ERY"
      },
      {
        text: "East Sussex",
        value: "GB-ESX"
      },
      {
        text: "Enfield",
        value: "GB-ENF"
      },
      {
        text: "Essex",
        value: "GB-ESS"
      },
      {
        text: "Gateshead",
        value: "GB-GAT"
      },
      {
        text: "Gloucestershire",
        value: "GB-GLS"
      },
      {
        text: "Greenwich",
        value: "GB-GRE"
      },
      {
        text: "Hackney",
        value: "GB-HCK"
      },
      {
        text: "Haiton",
        value: "GB-HAL"
      },
      {
        text: "Hammersmith and Fulham",
        value: "GB-HMF"
      },
      {
        text: "Hampshire",
        value: "GB-HAM"
      },
      {
        text: "Haringey",
        value: "GB-HRY"
      },
      {
        text: "Harrow",
        value: "GB-HRW"
      },
      {
        text: "Hartlepool",
        value: "GB-HPL"
      },
      {
        text: "Havering",
        value: "GB-HAV"
      },
      {
        text: "Herefordshire, County of",
        value: "GB-HEF"
      },
      {
        text: "Hertfordshire",
        value: "GB-HRT"
      },
      {
        text: "Hillingdon",
        value: "GB-HIL"
      },
      {
        text: "Hounslow",
        value: "GB-HNS"
      },
      {
        text: "Isle of Wight",
        value: "GB-IOW"
      },
      {
        text: "Isles of Scilly",
        value: "GB-IOS"
      },
      {
        text: "Islington",
        value: "GB-ISL"
      },
      {
        text: "Kensington and Chelsea",
        value: "GB-KEC"
      },
      {
        text: "Kent",
        value: "GB-KEN"
      },
      {
        text: "Kingston upon Hull, City of",
        value: "GB-KHL"
      },
      {
        text: "Kingston upon Thames",
        value: "GB-KTT"
      },
      {
        text: "Kirklees",
        value: "GB-KIR"
      },
      {
        text: "Knowsley",
        value: "GB-KWL"
      },
      {
        text: "Lambeth",
        value: "GB-LBH"
      },
      {
        text: "Lancashire",
        value: "GB-LAN"
      },
      {
        text: "Leeds (City of)",
        value: "GB-LDS"
      },
      {
        text: "Leitester (City of)",
        value: "GB-LCE"
      },
      {
        text: "Leicestershire",
        value: "GB-LEC"
      },
      {
        text: "Lewisham",
        value: "GB-LEW"
      },
      {
        text: "Lincolnshire",
        value: "GB-LIN"
      },
      {
        text: "Liverpool (City of)",
        value: "GB-LIV"
      },
      {
        text: "London, City of",
        value: "GB-LND"
      },
      {
        text: "Luton",
        value: "GB-LUT"
      },
      {
        text: "Manchester (City of)",
        value: "GB-MAN"
      },
      {
        text: "Medway",
        value: "GB-MDW"
      },
      {
        text: "Merton",
        value: "GB-MRT"
      },
      {
        text: "Middlesbrough",
        value: "GB-MDB"
      },
      {
        text: "Milton Keynes",
        value: "GB-MIK"
      },
      {
        text: "Newcastle upon Tyne (City of)",
        value: "GB-NET"
      },
      {
        text: "Newham",
        value: "GB-NWM"
      },
      {
        text: "Norfolk",
        value: "GB-NFK"
      },
      {
        text: "North East Lincolnshire",
        value: "GB-NEL"
      },
      {
        text: "North Lincolnshire",
        value: "GB-NLN"
      },
      {
        text: "North Somerset",
        value: "GB-NSM"
      },
      {
        text: "North Tyneside",
        value: "GB-NTY"
      },
      {
        text: "North Yorkshire",
        value: "GB-NYK"
      },
      {
        text: "Northamptonshire",
        value: "GB-NTH"
      },
      {
        text: "Northumberland",
        value: "GB-NBL"
      },
      {
        text: "Nottingham (City of)",
        value: "GB-NGM"
      },
      {
        text: "Nottinghamshire",
        value: "GB-NTT"
      },
      {
        text: "Oldham",
        value: "GB-OLD"
      },
      {
        text: "Oxfordshire",
        value: "GB-OXF"
      },
      {
        text: "Peterborough",
        value: "GB-PTE"
      },
      {
        text: "Plymouth (City of)",
        value: "GB-PLY"
      },
      {
        text: "Poole",
        value: "GB-POL"
      },
      {
        text: "Portsmouth (City of)",
        value: "GB-POR"
      },
      {
        text: "Reading",
        value: "GB-RDG"
      },
      {
        text: "Redbridge",
        value: "GB-RDB"
      },
      {
        text: "Redcar and Cleveland",
        value: "GB-RCC"
      },
      {
        text: "Richmond upon Thames",
        value: "GB-RIC"
      },
      {
        text: "Rochdale",
        value: "GB-RCH"
      },
      {
        text: "Rotherharn",
        value: "GB-ROT"
      },
      {
        text: "Rutland",
        value: "GB-RUT"
      },
      {
        text: "St. Helens",
        value: "GB-SHN"
      },
      {
        text: "Salford (City of)",
        value: "GB-SLF"
      },
      {
        text: "Sandweil",
        value: "GB-SAW"
      },
      {
        text: "Sefton",
        value: "GB-SFT"
      },
      {
        text: "Sheffield (City of)",
        value: "GB-SHF"
      },
      {
        text: "Shropshire",
        value: "GB-SHR"
      },
      {
        text: "Slough",
        value: "GB-SLG"
      },
      {
        text: "Solihull",
        value: "GB-SOL"
      },
      {
        text: "Somerset",
        value: "GB-SOM"
      },
      {
        text: "South Gloucestershire",
        value: "GB-SGC"
      },
      {
        text: "South Tyneside",
        value: "GB-STY"
      },
      {
        text: "Southampton (City of)",
        value: "GB-STH"
      },
      {
        text: "Southend-on-Sea",
        value: "GB-SOS"
      },
      {
        text: "Southwark",
        value: "GB-SWK"
      },
      {
        text: "Staffordshire",
        value: "GB-STS"
      },
      {
        text: "Stockport",
        value: "GB-SKP"
      },
      {
        text: "Stockton-On-Tees",
        value: "GB-STT"
      },
      {
        text: "Stoke-on-Trent (City of)",
        value: "GB-STE"
      },
      {
        text: "Suffolk",
        value: "GB-SFK"
      },
      {
        text: "Sunderland (City of)",
        value: "GB-SND"
      },
      {
        text: "Surrey",
        value: "GB-SRY"
      },
      {
        text: "Sutton",
        value: "GB-STN"
      },
      {
        text: "Swindon",
        value: "GB-SWD"
      },
      {
        text: "Tameside",
        value: "GB-TAM"
      },
      {
        text: "Telford and Wrekin",
        value: "GB-TFW"
      },
      {
        text: "Thurrock",
        value: "GB-THR"
      },
      {
        text: "Torbay",
        value: "GB-TOB"
      },
      {
        text: "Tower Hamlets",
        value: "GB-TWH"
      },
      {
        text: "Trafford",
        value: "GB-TRF"
      },
      {
        text: "Wakefield (City of)",
        value: "GB-WKF"
      },
      {
        text: "Walsall",
        value: "GB-WLL"
      },
      {
        text: "Waltham Forest",
        value: "GB-WFT"
      },
      {
        text: "Wandsworth",
        value: "GB-WND"
      },
      {
        text: "Warrington",
        value: "GB-WRT"
      },
      {
        text: "Warwickshire",
        value: "GB-WAR"
      },
      {
        text: "West Berkshire",
        value: "GB-WBK"
      },
      {
        text: "West Sussex",
        value: "GB-WSX"
      },
      {
        text: "Westminster (City of)",
        value: "GB-WSM"
      },
      {
        text: "Wigan",
        value: "GB-WGN"
      },
      {
        text: "Wiltshire",
        value: "GB-WIL"
      },
      {
        text: "Windsor and Maidenhead",
        value: "GB-WNM"
      },
      {
        text: "Wirral",
        value: "GB-WRL"
      },
      {
        text: "Wokingham",
        value: "GB-WOK"
      },
      {
        text: "Wolverhampton",
        value: "GB-WLV"
      },
      {
        text: "Worcestershire",
        value: "GB-WOR"
      },
      {
        text: "York (City of)",
        value: "GB-YOR"
      },
      {
        text: "Isle of Man",
        value: "GB-IOM"
      },
      {
        text: "Northern Ireland",
        value: "GB-NIR"
      },
      {
        text: "Antrim",
        value: "GB-ANT"
      },
      {
        text: "Ards",
        value: "GB-ARD"
      },
      {
        text: "Armagh",
        value: "GB-ARM"
      },
      {
        text: "Ballymena",
        value: "GB-BLA"
      },
      {
        text: "Ballymoney",
        value: "GB-BLY"
      },
      {
        text: "Banbridge",
        value: "GB-BNB"
      },
      {
        text: "Belfast (City of)",
        value: "GB-BFS"
      },
      {
        text: "Carrickfergus",
        value: "GB-CKF"
      },
      {
        text: "Castlereagh",
        value: "GB-CSR"
      },
      {
        text: "Coleraine",
        value: "GB-CLR"
      },
      {
        text: "Cookstown",
        value: "GB-CKT"
      },
      {
        text: "Craigavon",
        value: "GB-CGV"
      },
      {
        text: "Derry (City of)",
        value: "GB-DRY"
      },
      {
        text: "Down",
        value: "GB-DOW"
      },
      {
        text: "Dungannon",
        value: "GB-DGN"
      },
      {
        text: "Fermanagh",
        value: "GB-FER"
      },
      {
        text: "Larne",
        value: "GB-LRN"
      },
      {
        text: "Limavady",
        value: "GB-LMV"
      },
      {
        text: "Lisburn",
        value: "GB-LSB"
      },
      {
        text: "Magherafelt",
        value: "GB-MFT"
      },
      {
        text: "Moyle",
        value: "GB-MYL"
      },
      {
        text: "Newry and Mourne",
        value: "GB-NYM"
      },
      {
        text: "Newtownabbey",
        value: "GB-NTA"
      },
      {
        text: "North Down",
        value: "GB-NDN"
      },
      {
        text: "Omagh",
        value: "GB-OMH"
      },
      {
        text: "Strabane",
        value: "GB-STB"
      },
      {
        text: "Scotland",
        value: "GB-SCT"
      },
      {
        text: "Aberdeen City",
        value: "GB-ABE"
      },
      {
        text: "Aberdeenshire",
        value: "GB-ABD"
      },
      {
        text: "Angus",
        value: "GB-ANS"
      },
      {
        text: "Argyll and Bute",
        value: "GB-AGB"
      },
      {
        text: "Clackmannanshire",
        value: "GB-CLK"
      },
      {
        text: "Dumfries and Galloway",
        value: "GB-DGY"
      },
      {
        text: "Dundee City",
        value: "GB-DND"
      },
      {
        text: "East Ayrshire",
        value: "GB-EAY"
      },
      {
        text: "East Dunbartonshire",
        value: "GB-EDU"
      },
      {
        text: "East Lothian",
        value: "GB-ELN"
      },
      {
        text: "East Renfrewshire",
        value: "GB-ERW"
      },
      {
        text: "Edinburgh, City of",
        value: "GB-EDH"
      },
      {
        text: "Eilean Siar",
        value: "GB-ELS"
      },
      {
        text: "Falkirk",
        value: "GB-FAL"
      },
      {
        text: "Fife",
        value: "GB-FIF"
      },
      {
        text: "Glasgow City",
        value: "GB-GLG"
      },
      {
        text: "Highland",
        value: "GB-HLD"
      },
      {
        text: "Inverclyde",
        value: "GB-IVC"
      },
      {
        text: "Midlothian",
        value: "GB-MLN"
      },
      {
        text: "Moray",
        value: "GB-MRY"
      },
      {
        text: "North Ayrshire",
        value: "GB-NAY"
      },
      {
        text: "North Lanarkshire",
        value: "GB-NLK"
      },
      {
        text: "Orkney Islands",
        value: "GB-ORK"
      },
      {
        text: "Perth and Kinross",
        value: "GB-PKN"
      },
      {
        text: "Renfrewshire",
        value: "GB-RFW"
      },
      {
        text: "Scottish Borders, The",
        value: "GB-SCB"
      },
      {
        text: "Shetland Islands",
        value: "GB-ZET"
      },
      {
        text: "South Ayrshire",
        value: "GB-SAY"
      },
      {
        text: "South Lanarkshire",
        value: "GB-SLK"
      },
      {
        text: "Stirling",
        value: "GB-STG"
      },
      {
        text: "West Dunbartonshire",
        value: "GB-WDU"
      },
      {
        text: "West Lothian",
        value: "GB-WLN"
      },
      {
        text: "Wales [Cymru]",
        value: "GB-WLS"
      },
      {
        text: "Blaenau Gwent",
        value: "GB-BGW"
      },
      {
        text: "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
        value: "GB-BGE"
      },
      {
        text: "Caerphilly [Caerffili GB-CAF]",
        value: "GB-CAY"
      },
      {
        text: "Cardiff (City of) [Caerdydd GB-CRD]",
        value: "GB-CRF"
      },
      {
        text: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
        value: "GB-CMN"
      },
      {
        text: "Ceredigion [Sir Ceredigion]",
        value: "GB-CGN"
      },
      {
        text: "Conwy",
        value: "GB-CWY"
      },
      {
        text: "Denbighshire [Sir Ddinbych GB-DDB]",
        value: "GB-DEN"
      },
      {
        text: "Flintshire [Sir y Fflint GB-FFL]",
        value: "GB-FLN"
      },
      {
        text: "Gwynedd",
        value: "GB-GWN"
      },
      {
        text: "Isle of Anglesey [Sir Ynys Man GB-YNM]",
        value: "GB-AGY"
      },
      {
        text: "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
        value: "GB-MTY"
      },
      {
        text: "Monmouthshire [Sir Fynwy GB-FYN]",
        value: "GB-MON"
      },
      {
        text: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
        value: "GB-NTL"
      },
      {
        text: "Newport [Casnewydd GB-CNW]",
        value: "GB-NWP"
      },
      {
        text: "Pembrokeshire [Sir Benfro CB-BNF]",
        value: "GB-PEM"
      },
      {
        text: "Powys",
        value: "GB-POW"
      },
      {
        text: "Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]",
        value: "GB-RCT"
      },
      {
        text: "Swansea (City of) [Abertawe GB-ATA]",
        value: "GB-SWA"
      },
      {
        text: "Torfaen [Tor-faen]",
        value: "GB-TOF"
      },
      {
        text: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
        value: "GB-VGL"
      },
      {
        text: "Wrexham [Wrecsam GB-WRC]",
        value: "GB-WRX"
      }
    ]
  },
  {
    text: "Greece",
    value: "GR",
    divisions: [
      {
        text: "Anatoliki Makedonia kai Thraki",
        value: "GR-I"
      },
      {
        text: "Drama",
        value: "GR-52"
      },
      {
        text: "Evros",
        value: "GR-71"
      },
      {
        text: "Kavalla",
        value: "GR-55"
      },
      {
        text: "Rodopi",
        value: "GR-73"
      },
      {
        text: "Xanthi",
        value: "GR-72"
      },
      {
        text: "Attiki",
        value: "GR-IX"
      },
      {
        text: "Attiki",
        value: "GR-A1"
      },
      {
        text: "Dytiki Ellada",
        value: "GR-VII"
      },
      {
        text: "Achaïa",
        value: "GR-13"
      },
      {
        text: "Aitolia-Akarnania",
        value: "GR-01"
      },
      {
        text: "Ileia",
        value: "GR-14"
      },
      {
        text: "Dytiki Makedonia",
        value: "GR-III"
      },
      {
        text: "Florina",
        value: "GR-63"
      },
      {
        text: "Grevena",
        value: "GR-51"
      },
      {
        text: "Kastoria",
        value: "GR-56"
      },
      {
        text: "Kozani",
        value: "GR-58"
      },
      {
        text: "Ionioi Nisoi",
        value: "GR-VI"
      },
      {
        text: "Kefallinia",
        value: "GR-23"
      },
      {
        text: "Kerkyra",
        value: "GR-22"
      },
      {
        text: "Lefkas",
        value: "GR-24"
      },
      {
        text: "Zakynthos",
        value: "GR-21"
      },
      {
        text: "Ipeiros",
        value: "GR-IV"
      },
      {
        text: "Arta",
        value: "GR-31"
      },
      {
        text: "Ioannina",
        value: "GR-33"
      },
      {
        text: "Preveza",
        value: "GR-34"
      },
      {
        text: "Thesprotia",
        value: "GR-32"
      },
      {
        text: "Kentriki Makedonia",
        value: "GR-II"
      },
      {
        text: "Chalkidiki",
        value: "GR-64"
      },
      {
        text: "Imathia",
        value: "GR-53"
      },
      {
        text: "Kilkis",
        value: "GR-57"
      },
      {
        text: "Pella",
        value: "GR-59"
      },
      {
        text: "Pieria",
        value: "GR-61"
      },
      {
        text: "Serrai",
        value: "GR-62"
      },
      {
        text: "Thessaloniki",
        value: "GR-54"
      },
      {
        text: "Kriti",
        value: "GR-XIII"
      },
      {
        text: "Chania",
        value: "GR-94"
      },
      {
        text: "Irakleion",
        value: "GR-91"
      },
      {
        text: "Lasithion",
        value: "GR-92"
      },
      {
        text: "Rethymnon",
        value: "GR-93"
      },
      {
        text: "Notio Aigaio",
        value: "GR-XII"
      },
      {
        text: "Dodekanisos",
        value: "GR-81"
      },
      {
        text: "Kyklades",
        value: "GR-82"
      },
      {
        text: "Peloponnisos",
        value: "GR-X"
      },
      {
        text: "Argolis",
        value: "GR-11"
      },
      {
        text: "Arkadia",
        value: "GR-12"
      },
      {
        text: "Korinthia",
        value: "GR-15"
      },
      {
        text: "Lakonia",
        value: "GR-16"
      },
      {
        text: "Messinia",
        value: "GR-17"
      },
      {
        text: "Sterea Ellada",
        value: "GR-VIII"
      },
      {
        text: "Evrytania",
        value: "GR-05"
      },
      {
        text: "Evvoia",
        value: "GR-04"
      },
      {
        text: "Fokis",
        value: "GR-07"
      },
      {
        text: "Fthiotis",
        value: "GR-06"
      },
      {
        text: "Voiotia",
        value: "GR-03"
      },
      {
        text: "Thessalia",
        value: "GR-V"
      },
      {
        text: "Karditsa",
        value: "GR-41"
      },
      {
        text: "Larisa",
        value: "GR-42"
      },
      {
        text: "Magnisia",
        value: "GR-43"
      },
      {
        text: "Trikala",
        value: "GR-44"
      },
      {
        text: "Voreio Aigaio",
        value: "GR-XI"
      },
      {
        text: "Chios",
        value: "GR-85"
      },
      {
        text: "Lesvos",
        value: "GR-83"
      },
      {
        text: "Samos",
        value: "GR-84"
      }
    ]
  },
  {
    text: "Greenland",
    value: "GL",
    divisions: [
      {
        text: "Greenland",
        value: "GL-GL"
      }
    ]
  },
  {
    text: "Grenada",
    value: "GD",
    divisions: [
      {
        text: "Grenada",
        value: "GD-GD"
      }
    ]
  },
  {
    text: "Guadeloupe",
    value: "GP",
    divisions: [
      {
        text: "Guadeloupe",
        value: "GP-GP"
      }
    ]
  },
  {
    text: "Guam",
    value: "GU",
    divisions: [
      {
        text: "Guam",
        value: "GU-GU"
      }
    ]
  },
  {
    text: "Guatemala",
    value: "GT",
    divisions: [
      {
        text: "Alta Verapaz",
        value: "GT-AV"
      },
      {
        text: "Baja Verapaz",
        value: "GT-BV"
      },
      {
        text: "Chimaltenango",
        value: "GT-CM"
      },
      {
        text: "Chiquimula",
        value: "GT-CQ"
      },
      {
        text: "El Progreso",
        value: "GT-PR"
      },
      {
        text: "Escuintla",
        value: "GT-ES"
      },
      {
        text: "Guatemala",
        value: "GT-GU"
      },
      {
        text: "Huehuetenango",
        value: "GT-HU"
      },
      {
        text: "Izabal",
        value: "GT-IZ"
      },
      {
        text: "Jalapa",
        value: "GT-JA"
      },
      {
        text: "Jutiapa",
        value: "GT-JU"
      },
      {
        text: "Petén",
        value: "GT-PE"
      },
      {
        text: "Quezaltenango",
        value: "GT-QZ"
      },
      {
        text: "Quiché",
        value: "GT-QC"
      },
      {
        text: "Retalhuleu",
        value: "GT-RE"
      },
      {
        text: "Sacatepéquez",
        value: "GT-SA"
      },
      {
        text: "San Marcos",
        value: "GT-SM"
      },
      {
        text: "Santa Rosa",
        value: "GT-SR"
      },
      {
        text: "Sololá",
        value: "GT-SO"
      },
      {
        text: "Suchitepéquez",
        value: "GT-SU"
      },
      {
        text: "Totonicapán",
        value: "GT-TO"
      },
      {
        text: "Zacapa",
        value: "GT-ZA"
      }
    ]
  },
  {
    text: "Guinea",
    value: "GN",
    divisions: [
      {
        text: "Bake, Gouvernorat de",
        value: "GN-B"
      },
      {
        text: "Boffa",
        value: "GN-BF"
      },
      {
        text: "Boké",
        value: "GN-BK"
      },
      {
        text: "Fria",
        value: "GN-FR"
      },
      {
        text: "Gaoual",
        value: "GN-GA"
      },
      {
        text: "Koundara",
        value: "GN-KD"
      },
      {
        text: "Conakry, Gouvernorat de",
        value: "GN-C"
      },
      {
        text: "Faranah, Gouvernorat de",
        value: "GN-F"
      },
      {
        text: "Dabola",
        value: "GN-DB"
      },
      {
        text: "Dinguiraye",
        value: "GN-DI"
      },
      {
        text: "Faranah",
        value: "GN-FA"
      },
      {
        text: "Kissidougou",
        value: "GN-KS"
      },
      {
        text: "Kankan, Gouvernorat de",
        value: "GN-K"
      },
      {
        text: "Kankan",
        value: "GN-KA"
      },
      {
        text: "Kérouané",
        value: "GN-KE"
      },
      {
        text: "Kouroussa",
        value: "GN-KO"
      },
      {
        text: "Mandiana",
        value: "GN-MD"
      },
      {
        text: "Siguiri",
        value: "GN-SI"
      },
      {
        text: "Kindia, Gouvernorat de",
        value: "GN-D"
      },
      {
        text: "Coyah",
        value: "GN-CO"
      },
      {
        text: "Dubréka",
        value: "GN-DU"
      },
      {
        text: "Forécariah",
        value: "GN-FO"
      },
      {
        text: "Kindia",
        value: "GN-KD"
      },
      {
        text: "Télimélé",
        value: "GN-TE"
      },
      {
        text: "Labé, Gouvernorat de",
        value: "GN-L"
      },
      {
        text: "Koubia",
        value: "GN-KB"
      },
      {
        text: "Labé",
        value: "GN-LA"
      },
      {
        text: "Lélouma",
        value: "GN-LE"
      },
      {
        text: "Mali",
        value: "GN-ML"
      },
      {
        text: "Tougué",
        value: "GN-TO"
      },
      {
        text: "Mamou, Gouvernorat de",
        value: "GN-M"
      },
      {
        text: "Dalaba",
        value: "GN-DL"
      },
      {
        text: "Mamou",
        value: "GN-MM"
      },
      {
        text: "Pita",
        value: "GN-PI"
      },
      {
        text: "Nzérékoré, Gouvernorat de",
        value: "GN-N"
      },
      {
        text: "Beyla",
        value: "GN-BE"
      },
      {
        text: "Guékédou",
        value: "GN-GU"
      },
      {
        text: "Lola",
        value: "GN-LO"
      },
      {
        text: "Macenta",
        value: "GN-MC"
      },
      {
        text: "Nzérékoré",
        value: "GN-NZ"
      },
      {
        text: "Yomou",
        value: "GN-YO"
      }
    ]
  },
  {
    text: "Guinea-Bissau",
    value: "GW",
    divisions: [
      {
        text: "Bissau",
        value: "GW-BS"
      },
      {
        text: "Bafatá",
        value: "GW-BA"
      },
      {
        text: "Biombo",
        value: "GW-BM"
      },
      {
        text: "Bolama",
        value: "GW-BL"
      },
      {
        text: "Cacheu",
        value: "GW-CA"
      },
      {
        text: "Gabú",
        value: "GW-GA"
      },
      {
        text: "Oio",
        value: "GW-OI"
      },
      {
        text: "Quinara",
        value: "GW-QU"
      }
    ]
  },
  {
    text: "Guyana",
    value: "GY",
    divisions: [
      {
        text: "Barima-Waini",
        value: "GY-BA"
      },
      {
        text: "Cuyuni-Mazaruni",
        value: "GY-CU"
      },
      {
        text: "Demerara-Mahaica",
        value: "GY-DE"
      },
      {
        text: "East Berbice-Corentyne",
        value: "GY-EB"
      },
      {
        text: "Essequibo Islands-West Demerara",
        value: "GY-ES"
      },
      {
        text: "Mahaica-Berbice",
        value: "GY-MA"
      },
      {
        text: "Pomeroon-Supenaam",
        value: "GY-PM"
      },
      {
        text: "Potaro-Siparuni",
        value: "GY-PT"
      },
      {
        text: "Upper Demerara-Berbice",
        value: "GY-UD"
      },
      {
        text: "Upper Takutu-Upper Essequibo",
        value: "GY-UT"
      }
    ]
  },
  {
    text: "Haiti",
    value: "HT",
    divisions: [
      {
        text: "Artibonite",
        value: "HT-AR"
      },
      {
        text: "Centre",
        value: "HT-CE"
      },
      {
        text: "Grande-Anse",
        value: "HT-GA"
      },
      {
        text: "Nord",
        value: "HT-ND"
      },
      {
        text: "Nord-Est",
        value: "HT-NE"
      },
      {
        text: "Nord-Ouest",
        value: "HT-NO"
      },
      {
        text: "Ouest",
        value: "HT-OU"
      },
      {
        text: "Sud",
        value: "HT-SD"
      },
      {
        text: "Sud-Est",
        value: "HT-SE"
      }
    ]
  },
  {
    text: "Heard & McDonald Islands",
    value: "HM",
    divisions: [
      {
        text: "Heard & McDonald Islands",
        value: "HM-HM"
      }
    ]
  },
  {
    text: "Vatican City (Holy See)",
    value: "VA",
    divisions: [
      {
        text: "Vatican City (Holy See)",
        value: "VA-VA"
      }
    ]
  },
  {
    text: "Honduras",
    value: "HN",
    divisions: [
      {
        text: "Atlántida",
        value: "HN-AT"
      },
      {
        text: "Colón",
        value: "HN-CL"
      },
      {
        text: "Comayagua",
        value: "HN-CM"
      },
      {
        text: "Copán",
        value: "HN-CP"
      },
      {
        text: "Cortés",
        value: "HN-CR"
      },
      {
        text: "Choluteca",
        value: "HN-CH"
      },
      {
        text: "El Paraíso",
        value: "HN-EP"
      },
      {
        text: "Francisco Morazán",
        value: "HN-FM"
      },
      {
        text: "Gracias a Dios",
        value: "HN-GD"
      },
      {
        text: "Intibucá",
        value: "HN-IN"
      },
      {
        text: "Islas de la Bahía",
        value: "HN-IB"
      },
      {
        text: "La Paz",
        value: "HN-LP"
      },
      {
        text: "Lempira",
        value: "HN-LE"
      },
      {
        text: "Ocotepeque",
        value: "HN-OC"
      },
      {
        text: "Olancho",
        value: "HN-OL"
      },
      {
        text: "Santa Bárbara",
        value: "HN-SB"
      },
      {
        text: "Valle",
        value: "HN-VA"
      },
      {
        text: "Yoro",
        value: "HN-YO"
      }
    ]
  },
  {
    text: "Hong Kong",
    value: "HK",
    divisions: [
      {
        text: "Hong Kong",
        value: "HK-HK"
      }
    ]
  },
  {
    text: "Hungary",
    value: "HU",
    divisions: [
      {
        text: "Budapest",
        value: "HU-BU"
      },
      {
        text: "Bács-Kiskun",
        value: "HU-BK"
      },
      {
        text: "Baranya",
        value: "HU-BA"
      },
      {
        text: "Békés",
        value: "HU-BE"
      },
      {
        text: "Borsod-Abaúj-Zemplén",
        value: "HU-BZ"
      },
      {
        text: "Csongrád",
        value: "HU-CS"
      },
      {
        text: "Fejér",
        value: "HU-FE"
      },
      {
        text: "Gyór-Moson-Sopron",
        value: "HU-GS"
      },
      {
        text: "Hajdú-Bihar",
        value: "HU-HB"
      },
      {
        text: "Heves",
        value: "HU-HE"
      },
      {
        text: "Jasz-Nagykun-Szolnok",
        value: "HU-JN"
      },
      {
        text: "Komárom-Esztergom",
        value: "HU-KE"
      },
      {
        text: "Nógrád",
        value: "HU-NO"
      },
      {
        text: "Pest",
        value: "HU-PE"
      },
      {
        text: "Somogy",
        value: "HU-SO"
      },
      {
        text: "Szabolcs-Szatmár-Bereg",
        value: "HU-SZ"
      },
      {
        text: "Tolna",
        value: "HU-TO"
      },
      {
        text: "Vas",
        value: "HU-VA"
      },
      {
        text: "Veszprém",
        value: "HU-VE"
      },
      {
        text: "Zala",
        value: "HU-ZA"
      },
      {
        text: "Békéscsaba",
        value: "HU-BC"
      },
      {
        text: "Debrecen",
        value: "HU-DE"
      },
      {
        text: "Dunaújváros",
        value: "HU-DU"
      },
      {
        text: "Eger",
        value: "HU-EG"
      },
      {
        text: "Gyór",
        value: "HU-GY"
      },
      {
        text: "Hódmezóvásárhely",
        value: "HU-HV"
      },
      {
        text: "Kaposvár",
        value: "HU-KV"
      },
      {
        text: "Kecskemét",
        value: "HU-KM"
      },
      {
        text: "Miskolc",
        value: "HU-MI"
      },
      {
        text: "Nagykanizsa",
        value: "HU-NK"
      },
      {
        text: "Nyíregyháza",
        value: "HU-NY"
      },
      {
        text: "Pécs",
        value: "HU-PS"
      },
      {
        text: "Salgótarján",
        value: "HU-ST"
      },
      {
        text: "Sopron",
        value: "HU-SN"
      },
      {
        text: "Szeged",
        value: "HU-SD"
      },
      {
        text: "Székesfehérvár",
        value: "HU-SF"
      },
      {
        text: "Szekszárd",
        value: "HU-SS"
      },
      {
        text: "Szolnok",
        value: "HU-SK"
      },
      {
        text: "Szombathely",
        value: "HU-SH"
      },
      {
        text: "Tatabánya",
        value: "HU-TB"
      },
      {
        text: "Veszprém",
        value: "HU-VM"
      },
      {
        text: "Zalaegerszeg",
        value: "HU-ZE"
      }
    ]
  },
  {
    text: "Iceland",
    value: "IS",
    divisions: [
      {
        text: "Austurland",
        value: "IS-7"
      },
      {
        text: "Höfudborgarsvædi utan Reykjavíkur",
        value: "IS-1"
      },
      {
        text: "Nordurland eystra",
        value: "IS-6"
      },
      {
        text: "Nordurland vestra",
        value: "IS-5"
      },
      {
        text: "Reykjavīk",
        value: "IS-0"
      },
      {
        text: "Sudurland",
        value: "IS-8"
      },
      {
        text: "Sudurnes",
        value: "IS-2"
      },
      {
        text: "Vestfirdir",
        value: "IS-4"
      },
      {
        text: "Vesturland",
        value: "IS-3"
      }
    ]
  },
  {
    text: "India",
    value: "IN",
    divisions: [
      {
        text: "Andhra Pradesh",
        value: "IN-AP"
      },
      {
        text: "Arunachal Pradesh",
        value: "IN-AR"
      },
      {
        text: "Assam",
        value: "IN-AS"
      },
      {
        text: "Bihar",
        value: "IN-BR"
      },
      {
        text: "Goa",
        value: "IN-GA"
      },
      {
        text: "Gujarat",
        value: "IN-GJ"
      },
      {
        text: "Haryana",
        value: "IN-HR"
      },
      {
        text: "Himachal Pradesh",
        value: "IN-HP"
      },
      {
        text: "Jammu and Kashmir",
        value: "IN-JK"
      },
      {
        text: "Karnataka",
        value: "IN-KA"
      },
      {
        text: "Kerala",
        value: "IN-KL"
      },
      {
        text: "Madhya Pradesh",
        value: "IN-MP"
      },
      {
        text: "Maharashtra",
        value: "IN-MH"
      },
      {
        text: "Manipur",
        value: "IN-MN"
      },
      {
        text: "Meghalaya",
        value: "IN-ML"
      },
      {
        text: "Mizoram",
        value: "IN-MZ"
      },
      {
        text: "Nagaland",
        value: "IN-NL"
      },
      {
        text: "Orissa",
        value: "IN-OR"
      },
      {
        text: "Punjab",
        value: "IN-PB"
      },
      {
        text: "Rajasthan",
        value: "IN-RJ"
      },
      {
        text: "Sikkim",
        value: "IN-SK"
      },
      {
        text: "Tamil Nadu",
        value: "IN-TN"
      },
      {
        text: "Tripura",
        value: "IN-TR"
      },
      {
        text: "Uttar Pradesh",
        value: "IN-UP"
      },
      {
        text: "West Bengal",
        value: "IN-WB"
      },
      {
        text: "Andaman and Nicobar Islands",
        value: "IN-AN"
      },
      {
        text: "Chandigarh",
        value: "IN-CH"
      },
      {
        text: "Dadra and Nagar Haveli",
        value: "IN-DN"
      },
      {
        text: "Daman and Diu",
        value: "IN-DD"
      },
      {
        text: "Delhi",
        value: "IN-DL"
      },
      {
        text: "Lakshadweep",
        value: "IN-LD"
      },
      {
        text: "Pondicherry",
        value: "IN-PY"
      }
    ]
  },
  {
    text: "Indonesia",
    value: "ID",
    divisions: [
      {
        text: "Irian Jaya",
        value: "ID-IJU"
      },
      {
        text: "Irian Jaya",
        value: "ID-IJ"
      },
      {
        text: "Jawa",
        value: "ID-JWU"
      },
      {
        text: "Jawa Barat",
        value: "ID-JB"
      },
      {
        text: "Jawa Tengah",
        value: "ID-JT"
      },
      {
        text: "Jawa Timur",
        value: "ID-JI"
      },
      {
        text: "Jakarta Raya",
        value: "ID-JK"
      },
      {
        text: "Yogyakarta",
        value: "ID-YO"
      },
      {
        text: "Kalimantan",
        value: "ID-KAU"
      },
      {
        text: "Kalimantan Barat",
        value: "ID-KB"
      },
      {
        text: "Kalimantan Selatan",
        value: "ID-KS"
      },
      {
        text: "Kalimantan Tengah",
        value: "ID-KT"
      },
      {
        text: "Kalimantan Timur",
        value: "ID-KI"
      },
      {
        text: "Maluku",
        value: "ID-MAU"
      },
      {
        text: "Maluku",
        value: "ID-MA"
      },
      {
        text: "Nusa Tenggara",
        value: "ID-NUU"
      },
      {
        text: "Bali",
        value: "ID-BA"
      },
      {
        text: "Nusa Tenggara Barat",
        value: "ID-NB"
      },
      {
        text: "Nusa Tenggara Timur",
        value: "ID-NT"
      },
      {
        text: "Timor Timur",
        value: "ID-TT"
      },
      {
        text: "Sulawesi",
        value: "ID-SLU"
      },
      {
        text: "Sulawesi Selatan",
        value: "ID-SN"
      },
      {
        text: "Sulawesi Tengah",
        value: "ID-ST"
      },
      {
        text: "Sulawesi Tenggara",
        value: "ID-SG"
      },
      {
        text: "Sulawesi Utara",
        value: "ID-SA"
      },
      {
        text: "Sumatera",
        value: "ID-SMU"
      },
      {
        text: "Bengkulu",
        value: "ID-BE"
      },
      {
        text: "Jambi",
        value: "ID-JA"
      },
      {
        text: "Lampung",
        value: "ID-LA"
      },
      {
        text: "Riau",
        value: "ID-RI"
      },
      {
        text: "Sumatera Barat",
        value: "ID-SB"
      },
      {
        text: "Sumatera Selatan",
        value: "ID-SS"
      },
      {
        text: "Sumatera Utara",
        value: "ID-SU"
      },
      {
        text: "Aceh",
        value: "ID-AC"
      }
    ]
  },
  {
    text: "Iran",
    value: "IR",
    divisions: [
      {
        text: "Ardabīl",
        value: "IR-03"
      },
      {
        text: "Āzarbāyjān-e-Gharbī",
        value: "IR-02"
      },
      {
        text: "Āzarbāyjān-e-Sharqī",
        value: "IR-01"
      },
      {
        text: "Būshehr",
        value: "IR-06"
      },
      {
        text: "Chahār Maḩāll vā Bakhtīārī",
        value: "IR-08"
      },
      {
        text: "Eşfahān",
        value: "IR-04"
      },
      {
        text: "Fārs",
        value: "IR-14"
      },
      {
        text: "Gīlān",
        value: "IR-19"
      },
      {
        text: "Hamadān",
        value: "IR-24"
      },
      {
        text: "Hormozgān",
        value: "IR-23"
      },
      {
        text: "Īlām",
        value: "IR-05"
      },
      {
        text: "Kermān",
        value: "IR-15"
      },
      {
        text: "Kermānshāhān",
        value: "IR-17"
      },
      {
        text: "Khorāsān",
        value: "IR-09"
      },
      {
        text: "Khūzestān",
        value: "IR-10"
      },
      {
        text: "Kohkīlūyeh va Būyer Aḩmadī",
        value: "IR-18"
      },
      {
        text: "Kordestān",
        value: "IR-16"
      },
      {
        text: "Lorestān",
        value: "IR-20"
      },
      {
        text: "Markazī",
        value: "IR-22"
      },
      {
        text: "Māzandarān",
        value: "IR-21"
      },
      {
        text: "Qom",
        value: "IR-26"
      },
      {
        text: "Semnān",
        value: "IR-12"
      },
      {
        text: "Sīstān va Balūchestān",
        value: "IR-13"
      },
      {
        text: "Tehrān",
        value: "IR-07"
      },
      {
        text: "Yazd",
        value: "IR-25"
      },
      {
        text: "Zanjān",
        value: "IR-11"
      }
    ]
  },
  {
    text: "Iraq",
    value: "IQ",
    divisions: [
      {
        text: "Al Anbār",
        value: "IQ-AN"
      },
      {
        text: "Al Başrah",
        value: "IQ-BA"
      },
      {
        text: "Al Muthanná",
        value: "IQ-MU"
      },
      {
        text: "Al Qādisīyah",
        value: "IQ-QA"
      },
      {
        text: "An Najaf",
        value: "IQ-NA"
      },
      {
        text: "Arbīl",
        value: "IQ-AR"
      },
      {
        text: "As Sulaymānīyah",
        value: "IQ-SU"
      },
      {
        text: "At Ta'mīm",
        value: "IQ-TS"
      },
      {
        text: "Bābil",
        value: "IQ-BB"
      },
      {
        text: "Baghdād",
        value: "IQ-BG"
      },
      {
        text: "Dahūk",
        value: "IQ-DA"
      },
      {
        text: "Dhī Qār",
        value: "IQ-DQ"
      },
      {
        text: "Diyālá",
        value: "IQ-DI"
      },
      {
        text: "Karbalā'",
        value: "IQ-KA"
      },
      {
        text: "Maysān",
        value: "IQ-MA"
      },
      {
        text: "Nīnawá",
        value: "IQ-NI"
      },
      {
        text: "Şalāḩ ad Dīn",
        value: "IQ-SD"
      },
      {
        text: "Wāsiţ",
        value: "IQ-WA"
      }
    ]
  },
  {
    text: "Ireland",
    value: "IE",
    divisions: [
      {
        text: "Connaught (Connacht)",
        value: "IE-CP"
      },
      {
        text: "Galway (Gaillimh)",
        value: "IE-G"
      },
      {
        text: "Leitrim (Liatroim)",
        value: "IE-LM"
      },
      {
        text: "Mayo (Maigh Eo)",
        value: "IE-MO"
      },
      {
        text: "Roscommon (Ros Comáin)",
        value: "IE-RN"
      },
      {
        text: "Sligo (Sligeach)",
        value: "IE-SO"
      },
      {
        text: "Leinster (Laighin)",
        value: "IE-LP"
      },
      {
        text: "Carlow (Ceatharlach)",
        value: "IE-CW"
      },
      {
        text: "Dublin (Baile Átha Cliath)",
        value: "IE-D"
      },
      {
        text: "Kildare (Cill Dara)",
        value: "IE-KE"
      },
      {
        text: "Kilkenny (Cill Chainnigh)",
        value: "IE-KK"
      },
      {
        text: "Laois (Laois)",
        value: "IE-LS"
      },
      {
        text: "Longford (An Longfort)",
        value: "IE-LD"
      },
      {
        text: "Louth (Lú)",
        value: "IE-LH"
      },
      {
        text: "Meath (An Mhí)",
        value: "IE-MH"
      },
      {
        text: "Offaly (Uíbh Fhailí)",
        value: "IE-OY"
      },
      {
        text: "Westmeath (An Iarmhí)",
        value: "IE-WH"
      },
      {
        text: "Wexford (Loch Garman)",
        value: "IE-WX"
      },
      {
        text: "Wicklow (Cill Mhantáin)",
        value: "IE-WW"
      },
      {
        text: "Munster (An Mhumhain)",
        value: "IE-M"
      },
      {
        text: "Ulster (Ulaidh)",
        value: "IE-UP"
      },
      {
        text: "Cavan (An Cabhán)",
        value: "IE-CN"
      },
      {
        text: "Donegal (Dún na nGall)",
        value: "IE-DL"
      },
      {
        text: "Monaghan (Muineachán)",
        value: "IE-MN"
      }
    ]
  },
  {
    text: "Israel",
    value: "IL",
    divisions: [
      {
        text: "HaDarom (El Janūbī)",
        value: "IL-D"
      },
      {
        text: "HaMerkaz (El Awsat)",
        value: "IL-M"
      },
      {
        text: "HaZafon (Esh Shamālī)",
        value: "IL-2"
      },
      {
        text: "Hefa (Heifā)",
        value: "IL-HA"
      },
      {
        text: "Tel-Aviv (Tell Abīb)",
        value: "IL-TA"
      },
      {
        text: "Yerushalayim (Al Quds)",
        value: "IL-JM"
      }
    ]
  },
  {
    text: "Italy",
    value: "IT",
    divisions: [
      {
        text: "Abruzzo",
        value: "IT-65"
      },
      {
        text: "Chieti",
        value: "IT-CH"
      },
      {
        text: "L'Aquila",
        value: "IT-AQ"
      },
      {
        text: "Pescara",
        value: "IT-PE"
      },
      {
        text: "Teramo",
        value: "IT-TE"
      },
      {
        text: "Basilicata",
        value: "IT-77"
      },
      {
        text: "Matera",
        value: "IT-MT"
      },
      {
        text: "Potenza",
        value: "IT-PZ"
      },
      {
        text: "Calabria",
        value: "IT-78"
      },
      {
        text: "Catanzaro",
        value: "IT-CZ"
      },
      {
        text: "Cosenza",
        value: "IT-CS"
      },
      {
        text: "Crotone",
        value: "IT-KR"
      },
      {
        text: "Reggio Calabria",
        value: "IT-RC"
      },
      {
        text: "Vibo Valentia",
        value: "IT-W"
      },
      {
        text: "Campania",
        value: "IT-72"
      },
      {
        text: "Avellino",
        value: "IT-AV"
      },
      {
        text: "Benevento",
        value: "IT-BN"
      },
      {
        text: "Caserta",
        value: "IT-CE"
      },
      {
        text: "Napoli",
        value: "IT-NA"
      },
      {
        text: "Salerno",
        value: "IT-SA"
      },
      {
        text: "Emilia-Romagna",
        value: "IT-45"
      },
      {
        text: "Bologna",
        value: "IT-BO"
      },
      {
        text: "Ferrara",
        value: "IT-FE"
      },
      {
        text: "Forlì",
        value: "IT-FO"
      },
      {
        text: "Modena",
        value: "IT-MO"
      },
      {
        text: "Parma",
        value: "IT-PR"
      },
      {
        text: "Piacenza",
        value: "IT-PC"
      },
      {
        text: "Ravenna",
        value: "IT-RA"
      },
      {
        text: "Reggio Emilia",
        value: "IT-RE"
      },
      {
        text: "Rimini",
        value: "IT-RN"
      },
      {
        text: "Friuli-Venezia Giulia",
        value: "IT-36"
      },
      {
        text: "Gorizia",
        value: "IT-GO"
      },
      {
        text: "Pordenone",
        value: "IT-PN"
      },
      {
        text: "Trieste",
        value: "IT-TS"
      },
      {
        text: "Udine",
        value: "IT-UD"
      },
      {
        text: "Lazio",
        value: "IT-62"
      },
      {
        text: "Frosinone",
        value: "IT-FR"
      },
      {
        text: "Latina",
        value: "IT-LT"
      },
      {
        text: "Rieti",
        value: "IT-RI"
      },
      {
        text: "Roma",
        value: "IT-RM"
      },
      {
        text: "Viterbo",
        value: "IT-VT"
      },
      {
        text: "Liguria",
        value: "IT-42"
      },
      {
        text: "Genova",
        value: "IT-GE"
      },
      {
        text: "Imperia",
        value: "IT-IM"
      },
      {
        text: "La Spezia",
        value: "IT-SP"
      },
      {
        text: "Savona",
        value: "IT-SV"
      },
      {
        text: "Lombardia",
        value: "IT-25"
      },
      {
        text: "Bergamo",
        value: "IT-BG"
      },
      {
        text: "Brescia",
        value: "IT-BS"
      },
      {
        text: "Como",
        value: "IT-CO"
      },
      {
        text: "Cremona",
        value: "IT-CR"
      },
      {
        text: "Lecco",
        value: "IT-LC"
      },
      {
        text: "Lodi",
        value: "IT-LO"
      },
      {
        text: "Mantova",
        value: "IT-MN"
      },
      {
        text: "Milano",
        value: "IT-MI"
      },
      {
        text: "Pavia",
        value: "IT-PV"
      },
      {
        text: "Sondrio",
        value: "IT-SO"
      },
      {
        text: "Varese",
        value: "IT-VA"
      },
      {
        text: "Marche",
        value: "IT-57"
      },
      {
        text: "Ancona",
        value: "IT-AN"
      },
      {
        text: "Ascoli Piceno",
        value: "IT-AP"
      },
      {
        text: "Macerata",
        value: "IT-MC"
      },
      {
        text: "Pesaro",
        value: "IT-PS"
      },
      {
        text: "Molise",
        value: "IT-67"
      },
      {
        text: "Campobasso",
        value: "IT-CB"
      },
      {
        text: "Isernia",
        value: "IT-IS"
      },
      {
        text: "Piemonte",
        value: "IT-21"
      },
      {
        text: "Alessandria",
        value: "IT-AL"
      },
      {
        text: "Asti",
        value: "IT-AT"
      },
      {
        text: "Biella",
        value: "IT-BI"
      },
      {
        text: "Cuneo",
        value: "IT-CN"
      },
      {
        text: "Novara",
        value: "IT-NO"
      },
      {
        text: "Torino",
        value: "IT-TO"
      },
      {
        text: "Verbano-Cusio-Ossola",
        value: "IT-VB"
      },
      {
        text: "Vercelli",
        value: "IT-VC"
      },
      {
        text: "Puglia",
        value: "IT-75"
      },
      {
        text: "Bari",
        value: "IT-BA"
      },
      {
        text: "Brindisi",
        value: "IT-BR"
      },
      {
        text: "Foggia",
        value: "IT-FG"
      },
      {
        text: "Lecce",
        value: "IT-LE"
      },
      {
        text: "Taranto",
        value: "IT-TA"
      },
      {
        text: "Sardegna",
        value: "IT-88"
      },
      {
        text: "Cagliari",
        value: "IT-CA"
      },
      {
        text: "Nuoro",
        value: "IT-NU"
      },
      {
        text: "Oristano",
        value: "IT-OR"
      },
      {
        text: "Sassari",
        value: "IT-SS"
      },
      {
        text: "Sicilia",
        value: "IT-82"
      },
      {
        text: "Agrigento",
        value: "IT-AG"
      },
      {
        text: "Caltanissetta",
        value: "IT-CL"
      },
      {
        text: "Catania",
        value: "IT-CT"
      },
      {
        text: "Enna",
        value: "IT-EN"
      },
      {
        text: "Mesaina",
        value: "IT-ME"
      },
      {
        text: "Palermo",
        value: "IT-PA"
      },
      {
        text: "Ragusa",
        value: "IT-RG"
      },
      {
        text: "Siracusa",
        value: "IT-SR"
      },
      {
        text: "Trapani",
        value: "IT-TP"
      },
      {
        text: "Toscana",
        value: "IT-52"
      },
      {
        text: "Arezzo",
        value: "IT-AR"
      },
      {
        text: "Firenze",
        value: "IT-FI"
      },
      {
        text: "Grosseto",
        value: "IT-GR"
      },
      {
        text: "Livorno",
        value: "IT-LI"
      },
      {
        text: "Lucca",
        value: "IT-LU"
      },
      {
        text: "Massa",
        value: "IT-MS"
      },
      {
        text: "Pisa",
        value: "IT-PI"
      },
      {
        text: "Pistoia",
        value: "IT-PT"
      },
      {
        text: "Prato",
        value: "IT-PO"
      },
      {
        text: "Siena",
        value: "IT-SI"
      },
      {
        text: "Trentino-Alte Adige (Trentino-Südtirol)",
        value: "IT-32"
      },
      {
        text: "Bolzano (Bozen)",
        value: "IT-BZ"
      },
      {
        text: "Trento",
        value: "IT-TN"
      },
      {
        text: "Umbria",
        value: "IT-55"
      },
      {
        text: "Perugia",
        value: "IT-PG"
      },
      {
        text: "Terni",
        value: "IT-TR"
      },
      {
        text: "Valle d'Aosta (Vallée d'Aoste)",
        value: "IT-23"
      },
      {
        text: "Aosta (Aoste)",
        value: "IT-AO"
      },
      {
        text: "Veneto",
        value: "IT-34"
      },
      {
        text: "Belluno",
        value: "IT-BL"
      },
      {
        text: "Padova",
        value: "IT-PD"
      },
      {
        text: "Rovigo",
        value: "IT-RO"
      },
      {
        text: "Treviso",
        value: "IT-TV"
      },
      {
        text: "Venezia",
        value: "IT-VE"
      },
      {
        text: "Verona",
        value: "IT-VR"
      },
      {
        text: "Vicenza",
        value: "IT-VI"
      }
    ]
  },
  {
    text: "Jamaica",
    value: "JM",
    divisions: [
      {
        text: "Clarendon",
        value: "JM-13"
      },
      {
        text: "Hanover",
        value: "JM-09"
      },
      {
        text: "Kingston",
        value: "JM-01"
      },
      {
        text: "Manchester",
        value: "JM-12"
      },
      {
        text: "Portland",
        value: "JM-04"
      },
      {
        text: "Saint Andrew",
        value: "JM-02"
      },
      {
        text: "Saint Ann",
        value: "JM-06"
      },
      {
        text: "Saint Catherine",
        value: "JM-14"
      },
      {
        text: "Saint Elizabeth",
        value: "JM-11"
      },
      {
        text: "Saint James",
        value: "JM-08"
      },
      {
        text: "Saint Mary",
        value: "JM-05"
      },
      {
        text: "Saint Thomas",
        value: "JM-03"
      },
      {
        text: "Trelawny",
        value: "JM-07"
      },
      {
        text: "Westmoreland",
        value: "JM-10"
      }
    ]
  },
  {
    text: "Japan",
    value: "JP",
    divisions: [
      {
        text: "Aiti [Aichi]",
        value: "JP-23"
      },
      {
        text: "Akita",
        value: "JP-05"
      },
      {
        text: "Aomori",
        value: "JP-02"
      },
      {
        text: "Ehime",
        value: "JP-38"
      },
      {
        text: "Gihu [Gifu]",
        value: "JP-21"
      },
      {
        text: "Gunma",
        value: "JP-10"
      },
      {
        text: "Hirosima [Hiroshima]",
        value: "JP-34"
      },
      {
        text: "Hokkaidô [Hokkaido]",
        value: "JP-01"
      },
      {
        text: "Hukui [Fukui]",
        value: "JP-18"
      },
      {
        text: "Hukuoka [Fukuoka]",
        value: "JP-40"
      },
      {
        text: "Hukusima [Fukushima]",
        value: "JP-07"
      },
      {
        text: "Hyôgo [Hyogo]",
        value: "JP-28"
      },
      {
        text: "Ibaraki",
        value: "JP-08"
      },
      {
        text: "Isikawa [Ishikawa]",
        value: "JP-17"
      },
      {
        text: "Iwate",
        value: "JP-03"
      },
      {
        text: "Kagawa",
        value: "JP-37"
      },
      {
        text: "Kagosima [Kagoshima]",
        value: "JP-46"
      },
      {
        text: "Kanagawa",
        value: "JP-14"
      },
      {
        text: "Kôti [Kochi]",
        value: "JP-39"
      },
      {
        text: "Kumamoto",
        value: "JP-43"
      },
      {
        text: "Kyôto [Kyoto]",
        value: "JP-26"
      },
      {
        text: "Mie",
        value: "JP-24"
      },
      {
        text: "Miyagi",
        value: "JP-04"
      },
      {
        text: "Miyazaki",
        value: "JP-45"
      },
      {
        text: "Nagano",
        value: "JP-20"
      },
      {
        text: "Nagasaki",
        value: "JP-42"
      },
      {
        text: "Nara",
        value: "JP-29"
      },
      {
        text: "Niigata",
        value: "JP-15"
      },
      {
        text: "Ôita [Oita]",
        value: "JP-44"
      },
      {
        text: "Okayama",
        value: "JP-33"
      },
      {
        text: "Okinawa",
        value: "JP-47"
      },
      {
        text: "Ôsaka [Osaka]",
        value: "JP-27"
      },
      {
        text: "Saga",
        value: "JP-41"
      },
      {
        text: "Saitama",
        value: "JP-11"
      },
      {
        text: "Siga [Shiga]",
        value: "JP-25"
      },
      {
        text: "Sizuoka [Shizuoka]",
        value: "JP-22"
      },
      {
        text: "Tiba [Chiba]",
        value: "JP-12"
      },
      {
        text: "Totigi [Tochigi]",
        value: "JP-09"
      },
      {
        text: "Tokusima [Tokushima]",
        value: "JP-36"
      },
      {
        text: "Tôkyô [Tokyo]",
        value: "JP-13"
      },
      {
        text: "Tottori",
        value: "JP-31"
      },
      {
        text: "Toyama",
        value: "JP-16"
      },
      {
        text: "Wakayama",
        value: "JP-30"
      },
      {
        text: "Yamagata",
        value: "JP-06"
      },
      {
        text: "Yamaguti [Yamaguchi]",
        value: "JP-35"
      },
      {
        text: "Yamanasi [Yamanashi]",
        value: "JP-19"
      }
    ]
  },
  {
    text: "Jordan",
    value: "JO",
    divisions: [
      {
        text: "‘Ajlūn",
        value: "JO-AJ"
      },
      {
        text: "Al 'Aqaba",
        value: "JO-AQ"
      },
      {
        text: "Al Balqā'",
        value: "JO-BA"
      },
      {
        text: "Al Karak",
        value: "JO-KA"
      },
      {
        text: "Al Mafraq",
        value: "JO-MA"
      },
      {
        text: "‘Ammān",
        value: "JO-AM"
      },
      {
        text: "Aţ Ţafīlah",
        value: "JO-AT"
      },
      {
        text: "Az Zarqā'",
        value: "JO-AZ"
      },
      {
        text: "Irbid",
        value: "JO-IR"
      },
      {
        text: "Jarash",
        value: "JO-JA"
      },
      {
        text: "Ma‘ān",
        value: "JO-MN"
      },
      {
        text: "Mādaba",
        value: "JO-MD"
      }
    ]
  },
  {
    text: "Kazakhstan",
    value: "KZ",
    divisions: [
      {
        text: "Almaty",
        value: "KZ-ALA"
      },
      {
        text: "Bayqonyr (Baykonyr)",
        value: "KZ-BAY"
      },
      {
        text: "Almaty oblysy (Almatinskaya oblast')",
        value: "KZ-ALM"
      },
      {
        text: "Aqmola oblysy (Akmolinskaya oblast')",
        value: "KZ-AKM"
      },
      {
        text: "Aqtöbe oblysy (Aktyubinskaya oblast')",
        value: "KZ-AKT"
      },
      {
        text: "Atyraü oblysy (Atyrauskaya oblast')",
        value: "KZ-ATY"
      },
      {
        text: "Batys Kazakstan oblysy (Zapadno-Kazakhstanskaya oblast')",
        value: "KZ-ZAP"
      },
      {
        text: "Mangghystaū oblysy (Mangystauskaya oblast')",
        value: "KZ-MAN"
      },
      {
        text: "Ongtüstik Kazakstan oblysy (Yuzhno-Kazakhstanskaya oblast')",
        value: "KZ-YUZ"
      },
      {
        text: "Pavlodar oblysy (Pavlodarskaya oblast')",
        value: "KZ-PAV"
      },
      {
        text: "Qaraghandy oblysy (Karagandinskaya oblast')",
        value: "KZ-KAR"
      },
      {
        text: "Qostanay oblysy (Kustanayskaya oblast')",
        value: "KZ-KUS"
      },
      {
        text: "Qyzylorda oblysy (Kzylordinskaya oblast')",
        value: "KZ-KZY"
      },
      {
        text: "Shyghys Kazakstan oblysy (Vostochno-Kazakhstanskaya oblast')",
        value: "KZ-VOS"
      },
      {
        text: "Soltüstik Kazakstan oblysy (Severo-Kazakhstanskaya oblast')",
        value: "KZ-SEV"
      },
      {
        text: "Zhambyl oblysy (Zhambylskaya Oblast')",
        value: "KZ-ZHA"
      }
    ]
  },
  {
    text: "Kenya",
    value: "KE",
    divisions: [
      {
        text: "Nairobi Municipality",
        value: "KE-110"
      },
      {
        text: "Central (Kati)",
        value: "KE-200"
      },
      {
        text: "Coast (Pwani)",
        value: "KE-300"
      },
      {
        text: "Eastern (Mashariki)",
        value: "KE-400"
      },
      {
        text: "North-Eastern (Kaskazini Mashariki)",
        value: "KE-500"
      },
      {
        text: "Nyanza",
        value: "KE-600"
      },
      {
        text: "Rift Valley",
        value: "KE-700"
      },
      {
        text: "Western (Magharibi)",
        value: "KE-900"
      }
    ]
  },
  {
    text: "Kiribati",
    value: "KI",
    divisions: [
      {
        text: "Gilbert Islands",
        value: "KI-G"
      },
      {
        text: "Line Islands",
        value: "KI-L"
      },
      {
        text: "Phoenix Islands",
        value: "KI-P"
      }
    ]
  },
  {
    text: "Korea (North)",
    value: "KP",
    divisions: [
      {
        text: "Kaesong-si",
        value: "KP-KAE"
      },
      {
        text: "Nampo-si",
        value: "KP-NAM"
      },
      {
        text: "Pyongyang-si",
        value: "KP-PYO"
      },
      {
        text: "Chagang-do",
        value: "KP-CHA"
      },
      {
        text: "Hamgyongbuk-do",
        value: "KP-HAB"
      },
      {
        text: "Hamgyongnam-do",
        value: "KP-HAN"
      },
      {
        text: "Hwanghaebuk-do",
        value: "KP-HWB"
      },
      {
        text: "Hwanghaenam-do",
        value: "KP-HWN"
      },
      {
        text: "Kangwon-do",
        value: "KP-KAN"
      },
      {
        text: "Pyonganbuk-do",
        value: "KP-PYB"
      },
      {
        text: "Pyongannam-do",
        value: "KP-PYN"
      },
      {
        text: "Yanggang-do",
        value: "KP-YAN"
      }
    ]
  },
  {
    text: "Korea (South)",
    value: "KR",
    divisions: [
      {
        text: "Seoul Teugbyeolsi [ Seoul-T’ŭkpyŏlshi]",
        value: "KR-11"
      },
      {
        text: "Busan Gwang'yeogsi [Pusan-Kwangyŏkshi]",
        value: "KR-26"
      },
      {
        text: "Daegu Gwang'yeogsi [Taegu-Kwangyŏkshi)",
        value: "KR-27"
      },
      {
        text: "Daejeon Gwang'yeogsi [Taejŏn-Kwangyŏkshi]",
        value: "KR-30"
      },
      {
        text: "Gwangju Gwang'yeogsi [Kwangju-Kwangyŏkshi]",
        value: "KR-29"
      },
      {
        text: "Incheon Gwang'yeogsi [Inchŏn-Kwangyŏkshi]",
        value: "KR-28"
      },
      {
        text: "Ulsan Gwang'yeogsi [Ulsan-Kwangyŏkshi]",
        value: "KR-31"
      },
      {
        text: "Chungcheongbugdo [Ch'ungch'ŏngbuk-do]",
        value: "KR-43"
      },
      {
        text: "Chungcheongnamdo [Ch'ungch'ŏngnam-do]",
        value: "KR-44"
      },
      {
        text: "Gang'weondo [Kang-won-do]",
        value: "KR-42"
      },
      {
        text: "Gyeonggido [Kyŏnggi-do]",
        value: "KR-41"
      },
      {
        text: "Gyeongsangbugdo [Kyŏngsangbuk-do]",
        value: "KR-47"
      },
      {
        text: "Gyeongsangnamdo [Kyŏngsangnam-do]",
        value: "KR-48"
      },
      {
        text: "Jejudo [Cheju-do]",
        value: "KR-49"
      },
      {
        text: "Jeonrabugdo [Chŏllabuk-do)",
        value: "KR-45"
      },
      {
        text: "Jeonranamdo [Chŏllanam-do]",
        value: "KR-46"
      }
    ]
  },
  {
    text: "Kuwait",
    value: "KW",
    divisions: [
      {
        text: "Al Aḩmadi",
        value: "KW-AH"
      },
      {
        text: "Al Farwānīyah",
        value: "KW-FA"
      },
      {
        text: "Al Jahrah",
        value: "KW-JA"
      },
      {
        text: "Al Kuwayt",
        value: "KW-KU"
      },
      {
        text: "Ḩawallī",
        value: "KW-HA"
      }
    ]
  },
  {
    text: "Kyrgyzstan",
    value: "KG",
    divisions: [
      {
        text: "Chu (Chuyskaya oblast')",
        value: "KG-C"
      },
      {
        text: "Jalal-Abad (Dzhalal-Abadskaya oblast')",
        value: "KG-J"
      },
      {
        text: "Naryn (Narynskaya Oblast’)",
        value: "KG-N"
      },
      {
        text: "Osh (Oshskaya oblast')",
        value: "KG-O"
      },
      {
        text: "Talas (Talasskaya oblast')",
        value: "KG-T"
      },
      {
        text: "Ysyk-Köl (Issyk-Kul'skaya oblast')",
        value: "KG-Y"
      }
    ]
  },
  {
    text: "Laos",
    value: "LA",
    divisions: [
      {
        text: "Vientiane",
        value: "LA-VT"
      },
      {
        text: "Attapu [Attopeu]",
        value: "LA-AT"
      },
      {
        text: "Bokèo",
        value: "LA-BK"
      },
      {
        text: "Bolikhamxai [Borikhane]",
        value: "LA-BL"
      },
      {
        text: "Champasak [Champassak]",
        value: "LA-CH"
      },
      {
        text: "Houaphan",
        value: "LA-HO"
      },
      {
        text: "Khammouan",
        value: "LA-KH"
      },
      {
        text: "Louang Namtha",
        value: "LA-LM"
      },
      {
        text: "Louangphabang [Louang Prabang]",
        value: "LA-LP"
      },
      {
        text: "Oudômxai [Oudomsai]",
        value: "LA-OU"
      },
      {
        text: "Phôngsali [Phong Saly]",
        value: "LA-PH"
      },
      {
        text: "Salavan [Saravane]",
        value: "LA-SL"
      },
      {
        text: "Savannakhét",
        value: "LA-SV"
      },
      {
        text: "Vientiane",
        value: "LA-VI"
      },
      {
        text: "Xaignabouli [Sayaboury]",
        value: "LA-XA"
      },
      {
        text: "Xékong [Sékong]",
        value: "LA-XE"
      },
      {
        text: "Xiangkhoang [Xieng Khouang]",
        value: "LA-XI"
      }
    ]
  },
  {
    text: "Latvia",
    value: "LV",
    divisions: [
      {
        text: "Aizkraukles Aprinkis",
        value: "LV-AI"
      },
      {
        text: "Alūksnes Aprinkis",
        value: "LV-AL"
      },
      {
        text: "Balvu Aprinkis",
        value: "LV-BL"
      },
      {
        text: "Bauskas Aprinkis",
        value: "LV-BU"
      },
      {
        text: "Cēsu Aprinkis",
        value: "LV-CE"
      },
      {
        text: "Daugavpils Aprinkis",
        value: "LV-DA"
      },
      {
        text: "Dobeles Aprinkis",
        value: "LV-DO"
      },
      {
        text: "Gulbenes Aprinkis",
        value: "LV-GU"
      },
      {
        text: "Jelgavas Aprinkis",
        value: "LV-JL"
      },
      {
        text: "Jēkabpils Aprinkis",
        value: "LV-JK"
      },
      {
        text: "Krāslavas Aprinkis",
        value: "LV-KR"
      },
      {
        text: "Kuldīgas Aprinkis",
        value: "LV-KU"
      },
      {
        text: "Limbažu Aprinkis",
        value: "LV-LM"
      },
      {
        text: "Liepājas Aprinkis",
        value: "LV-LE"
      },
      {
        text: "Ludzas Aprinkis",
        value: "LV-LU"
      },
      {
        text: "Madonas Aprinkis",
        value: "LV-MA"
      },
      {
        text: "Ogres Aprinkis",
        value: "LV-OG"
      },
      {
        text: "Preilu Aprinkis",
        value: "LV-PR"
      },
      {
        text: "Rēzeknes Aprinkis",
        value: "LV-RE"
      },
      {
        text: "Rīgas Aprinkis",
        value: "LV-RI"
      },
      {
        text: "Saldus Aprinkis",
        value: "LV-SA"
      },
      {
        text: "Talsu Aprinkis",
        value: "LV-TA"
      },
      {
        text: "Tukuma Aprinkis",
        value: "LV-TU"
      },
      {
        text: "Valkas Aprinkis",
        value: "LV-VK"
      },
      {
        text: "Valmieras Aprinkis",
        value: "LV-VM"
      },
      {
        text: "Ventspils Aprinkis",
        value: "LV-VE"
      },
      {
        text: "Daugavpils",
        value: "LV-DGV"
      },
      {
        text: "Jelgava",
        value: "LV-JEL"
      },
      {
        text: "Jūrmala",
        value: "LV-JUR"
      },
      {
        text: "Liepāja",
        value: "LV-LPX"
      },
      {
        text: "Rēzekne",
        value: "LV-REZ"
      },
      {
        text: "Rīga",
        value: "LV-RIX"
      },
      {
        text: "Ventspils",
        value: "LV-VEN"
      }
    ]
  },
  {
    text: "Lebanon",
    value: "LB",
    divisions: [
      {
        text: "Beiroût (Bayrūt)",
        value: "LB-BA"
      },
      {
        text: "El Béqaa (Al Biqā')",
        value: "LB-BI"
      },
      {
        text: "Jabal Loubnâne (Jabal Lubnān)",
        value: "LB-JL"
      },
      {
        text: "Loubnâne ech Chemâli (Ash Shamāl)",
        value: "LB-AS"
      },
      {
        text: "Loubnâne ej Jnoûbi (Al Janūb)",
        value: "LB-JA"
      },
      {
        text: "Nabatîyé (An Nabaţīyah",
        value: "LB-NA"
      }
    ]
  },
  {
    text: "Lesotho",
    value: "LS",
    divisions: [
      {
        text: "Berea",
        value: "LS-D"
      },
      {
        text: "Butha-Buthe",
        value: "LS-B"
      },
      {
        text: "Leribe",
        value: "LS-C"
      },
      {
        text: "Mafeteng",
        value: "LS-E"
      },
      {
        text: "Maseru",
        value: "LS-A"
      },
      {
        text: "Mohale's Hoek",
        value: "LS-F"
      },
      {
        text: "Mokhotlong",
        value: "LS-J"
      },
      {
        text: "Qacha's Nek",
        value: "LS-H"
      },
      {
        text: "Quthing",
        value: "LS-G"
      },
      {
        text: "Thaba-Tseka",
        value: "LS-K"
      }
    ]
  },
  {
    text: "Liberia",
    value: "LR",
    divisions: [
      {
        text: "Bomi",
        value: "LR-BM"
      },
      {
        text: "Bong",
        value: "LR-BG"
      },
      {
        text: "Grand Bassa",
        value: "LR-GB"
      },
      {
        text: "Grand Cape Mount",
        value: "LR-CM"
      },
      {
        text: "Grand Gedeh",
        value: "LR-GG"
      },
      {
        text: "Grand Kru",
        value: "LR-GK"
      },
      {
        text: "Lofa",
        value: "LR-LO"
      },
      {
        text: "Margibi",
        value: "LR-MG"
      },
      {
        text: "Maryland",
        value: "LR-MY"
      },
      {
        text: "Montserrado",
        value: "LR-MO"
      },
      {
        text: "Nimba",
        value: "LR-NI"
      },
      {
        text: "Rivercess",
        value: "LR-RI"
      },
      {
        text: "Sinoe",
        value: "LR-SI"
      }
    ]
  },
  {
    text: "Libya",
    value: "LY",
    divisions: [
      {
        text: "Al Buţnān",
        value: "LY-BU"
      },
      {
        text: "Al Jabal al Akhḑar",
        value: "LY-JA"
      },
      {
        text: "Al Jabal al Gharbī",
        value: "LY-JG"
      },
      {
        text: "Al Jufrah",
        value: "LY-Ju"
      },
      {
        text: "Al Wāḩah",
        value: "LY-WA"
      },
      {
        text: "Al Wusţá",
        value: "LY-Wu"
      },
      {
        text: "Az Zāwiyah",
        value: "LY-ZA"
      },
      {
        text: "Banghāzī",
        value: "LY-BA"
      },
      {
        text: "Fazzān",
        value: "LY-FA"
      },
      {
        text: "Mişrātah",
        value: "LY-MI"
      },
      {
        text: "Naggaza",
        value: "LY-NA"
      },
      {
        text: "Sawfajjin",
        value: "LY-SF"
      },
      {
        text: "Ţarābulus",
        value: "LY-TB"
      }
    ]
  },
  {
    text: "Liechtenstein",
    value: "LI",
    divisions: [
      {
        text: "Liechtenstein",
        value: "LI-LI"
      }
    ]
  },
  {
    text: "Lithuania",
    value: "LT",
    divisions: [
      {
        text: "Alytaus Apskritis",
        value: "LT-AL"
      },
      {
        text: "Kauno Apskritis",
        value: "LT-KU"
      },
      {
        text: "Klaipėdos Apskritis",
        value: "LT-KL"
      },
      {
        text: "Marijampolės Apskritis",
        value: "LT-MR"
      },
      {
        text: "Panevėžio Apskritis",
        value: "LT-PN"
      },
      {
        text: "Šiauliu Apskritis",
        value: "LT-SA"
      },
      {
        text: "Tauragės Apskritis",
        value: "LT-TA"
      },
      {
        text: "Telšiu Apskritis",
        value: "LT-TE"
      },
      {
        text: "Utenos Apskritis",
        value: "LT-UT"
      },
      {
        text: "Vilniaus Apskritis",
        value: "LT-VL"
      }
    ]
  },
  {
    text: "Luxembourg",
    value: "LU",
    divisions: [
      {
        text: "Diekirch",
        value: "LU-D"
      },
      {
        text: "Grevenmacher",
        value: "LU-G"
      },
      {
        text: "Luxembourg (Luxemburg)",
        value: "LU-L"
      }
    ]
  },
  {
    text: "Macau",
    value: "MO",
    divisions: [
      {
        text: "Macau",
        value: "MO-MO"
      }
    ]
  },
  {
    text: "Macedonia",
    value: "MK",
    divisions: [
      {
        text: "Macedonia",
        value: "MK-MK"
      }
    ]
  },
  {
    text: "Madagascar",
    value: "MG",
    divisions: [
      {
        text: "Antananarivo",
        value: "MG-T"
      },
      {
        text: "Antsiranana",
        value: "MG-D"
      },
      {
        text: "Fianarantsoa",
        value: "MG-F"
      },
      {
        text: "Mahajanga",
        value: "MG-M"
      },
      {
        text: "Toamasina",
        value: "MG-A"
      },
      {
        text: "Toliara",
        value: "MG-U"
      }
    ]
  },
  {
    text: "Malawi",
    value: "MW",
    divisions: [
      {
        text: "Central",
        value: "MW-C"
      },
      {
        text: "Dedza",
        value: "MW-DE"
      },
      {
        text: "Dowa",
        value: "MW-DO"
      },
      {
        text: "Kasungu",
        value: "MW-KS"
      },
      {
        text: "Lilongwe",
        value: "MW-LI"
      },
      {
        text: "Mchinji",
        value: "MW-MC"
      },
      {
        text: "Nkhotakota",
        value: "MW-NK"
      },
      {
        text: "Ntcheu",
        value: "MW-NU"
      },
      {
        text: "Ntchisi",
        value: "MW-NI"
      },
      {
        text: "Salima",
        value: "MW-SA"
      },
      {
        text: "Northern",
        value: "MW-N"
      },
      {
        text: "Chitipa",
        value: "MW-CT"
      },
      {
        text: "Karonga",
        value: "MW-KR"
      },
      {
        text: "Mzimba",
        value: "MW-MZ"
      },
      {
        text: "Nkhata Bay",
        value: "MW-NB"
      },
      {
        text: "Rumphi",
        value: "MW-RU"
      },
      {
        text: "Southern",
        value: "MW-S"
      },
      {
        text: "Blantyre",
        value: "MW-BL"
      },
      {
        text: "Chikwawa",
        value: "MW-CK"
      },
      {
        text: "Chiradzulu",
        value: "MW-CR"
      },
      {
        text: "Machinga",
        value: "MW-MH"
      },
      {
        text: "Mangochi",
        value: "MW-MG"
      },
      {
        text: "Mulanje",
        value: "MW-MU"
      },
      {
        text: "Mwanza",
        value: "MW-MW"
      },
      {
        text: "Nsanje",
        value: "MW-NS"
      },
      {
        text: "Thyolo",
        value: "MW-TH"
      },
      {
        text: "Zomba",
        value: "MW-ZO"
      }
    ]
  },
  {
    text: "Malaysia",
    value: "MY",
    divisions: [
      {
        text: "Wilayah Persekutuan Kuala Lumpur",
        value: "MY-W"
      },
      {
        text: "Wilayah Persekutuan Labuan",
        value: "MY-L"
      },
      {
        text: "Johor",
        value: "MY-J"
      },
      {
        text: "Kedah",
        value: "MY-K"
      },
      {
        text: "Kelantan",
        value: "MY-D"
      },
      {
        text: "Melaka",
        value: "MY-M"
      },
      {
        text: "Negeri Sembilan",
        value: "MY-N"
      },
      {
        text: "Pahang",
        value: "MY-C"
      },
      {
        text: "Perak",
        value: "MY-A"
      },
      {
        text: "Perlis",
        value: "MY-R"
      },
      {
        text: "Pulau Pinang",
        value: "MY-P"
      },
      {
        text: "Sabah",
        value: "MY-SA"
      },
      {
        text: "Sarawak",
        value: "MY-SK"
      },
      {
        text: "Selangor",
        value: "MY-B"
      },
      {
        text: "Terengganu",
        value: "MY-T"
      }
    ]
  },
  {
    text: "Maldives",
    value: "MV",
    divisions: [
      {
        text: "Male",
        value: "MV-MLE"
      },
      {
        text: "Alif",
        value: "MV-02"
      },
      {
        text: "Baa",
        value: "MV-20"
      },
      {
        text: "Dhaalu",
        value: "MV-17"
      },
      {
        text: "Faafu",
        value: "MV-14"
      },
      {
        text: "Gaaf Alif",
        value: "MV-27"
      },
      {
        text: "Gaafu Dhaalu",
        value: "MV-28"
      },
      {
        text: "Gnaviyani",
        value: "MV-29"
      },
      {
        text: "Haa Alif",
        value: "MV-07"
      },
      {
        text: "Haa Dhaalu",
        value: "MV-23"
      },
      {
        text: "Kaafu",
        value: "MV-26"
      },
      {
        text: "Laamu",
        value: "MV-05"
      },
      {
        text: "Lhaviyani",
        value: "MV-03"
      },
      {
        text: "Meemu",
        value: "MV-12"
      },
      {
        text: "Noonu",
        value: "MV-25"
      },
      {
        text: "Raa",
        value: "MV-13"
      },
      {
        text: "Seenu",
        value: "MV-01"
      },
      {
        text: "Shaviyani",
        value: "MV-24"
      },
      {
        text: "Thaa",
        value: "MV-08"
      },
      {
        text: "Vaavu",
        value: "MV-04"
      }
    ]
  },
  {
    text: "Mali",
    value: "ML",
    divisions: [
      {
        text: "Bamako",
        value: "ML-BKO"
      },
      {
        text: "Gao",
        value: "ML-7"
      },
      {
        text: "Kayes",
        value: "ML-1"
      },
      {
        text: "Kidal",
        value: "ML-8"
      },
      {
        text: "Koulikoro",
        value: "ML-2"
      },
      {
        text: "Mopti",
        value: "ML-5"
      },
      {
        text: "Ségou",
        value: "ML-4"
      },
      {
        text: "Sikasso",
        value: "ML-3"
      },
      {
        text: "Tombouctou",
        value: "ML-6"
      }
    ]
  },
  {
    text: "Malta",
    value: "MT",
    divisions: [
      {
        text: "Malta",
        value: "MT-MT"
      }
    ]
  },
  {
    text: "Marshall Islands",
    value: "MH",
    divisions: [
      {
        text: "Ralik chain",
        value: "MH-L"
      },
      {
        text: "Ailinglapalap",
        value: "MH-ALL"
      },
      {
        text: "Ebon",
        value: "MH-EBO"
      },
      {
        text: "Eniwetok",
        value: "MH-ENI"
      },
      {
        text: "Jaluit",
        value: "MH-JAL"
      },
      {
        text: "Kili",
        value: "MH-KIL"
      },
      {
        text: "Kwajalein",
        value: "MH-KWA"
      },
      {
        text: "Lae",
        value: "MH-LAE"
      },
      {
        text: "Lib",
        value: "MH-LIB"
      },
      {
        text: "Namorik",
        value: "MH-NMK"
      },
      {
        text: "Namu",
        value: "MH-NMU"
      },
      {
        text: "Rongelap",
        value: "MH-RON"
      },
      {
        text: "Ujae",
        value: "MH-UJA"
      },
      {
        text: "Ujelang",
        value: "MH-UJL"
      },
      {
        text: "Wotho",
        value: "MH-WTH"
      },
      {
        text: "Ratak chain",
        value: "MH-T"
      },
      {
        text: "Ailuk",
        value: "MH-ALK"
      },
      {
        text: "Arno",
        value: "MH-ARN"
      },
      {
        text: "Aur",
        value: "MH-AUR"
      },
      {
        text: "Likiep",
        value: "MH-LIK"
      },
      {
        text: "Majuro",
        value: "MH-MAJ"
      },
      {
        text: "Maloelap",
        value: "MH-MAL"
      },
      {
        text: "Mejit",
        value: "MH-MEJ"
      },
      {
        text: "Mili",
        value: "MH-MIL"
      },
      {
        text: "Utirik",
        value: "MH-UTI"
      },
      {
        text: "Wotje",
        value: "MH-WTJ"
      }
    ]
  },
  {
    text: "Martinique",
    value: "MQ",
    divisions: [
      {
        text: "Martinique",
        value: "MQ-MQ"
      }
    ]
  },
  {
    text: "Mauritania",
    value: "MR",
    divisions: [
      {
        text: "Nouakchott",
        value: "MR-NKC"
      },
      {
        text: "Adrar",
        value: "MR-07"
      },
      {
        text: "Assaba",
        value: "MR-03"
      },
      {
        text: "Brakna",
        value: "MR-05"
      },
      {
        text: "Dakhlet Nouādhibou",
        value: "MR-08"
      },
      {
        text: "Gorgol",
        value: "MR-04"
      },
      {
        text: "Guidimaka",
        value: "MR-10"
      },
      {
        text: "Hodh ech Chargui",
        value: "MR-01"
      },
      {
        text: "Hodh el Gharbi",
        value: "MR-02"
      },
      {
        text: "Inchiri",
        value: "MR-12"
      },
      {
        text: "Tagant",
        value: "MR-09"
      },
      {
        text: "Tiris Zemmour",
        value: "MR-11"
      },
      {
        text: "Trarza",
        value: "MR-06"
      }
    ]
  },
  {
    text: "Mauritius",
    value: "MU",
    divisions: [
      {
        text: "Beau Bassin-Rose Hill",
        value: "MU-BR"
      },
      {
        text: "Curepipe",
        value: "MU-CU"
      },
      {
        text: "Port Louis",
        value: "MU-PL"
      },
      {
        text: "Quatre Bornes",
        value: "MU-QB"
      },
      {
        text: "Vacoas-Phoenix",
        value: "MU-VP"
      },
      {
        text: "Black River",
        value: "MU-BL"
      },
      {
        text: "Flacq",
        value: "MU-FL"
      },
      {
        text: "Grand Port",
        value: "MU-GP"
      },
      {
        text: "Moka",
        value: "MU-MO"
      },
      {
        text: "Pamplemousses",
        value: "MU-PA"
      },
      {
        text: "Plaines Wilhems",
        value: "MU-PW"
      },
      {
        text: "Rivière du Rempart",
        value: "MU-RR"
      },
      {
        text: "Savanne",
        value: "MU-SA"
      },
      {
        text: "Agalega Islands",
        value: "MU-AG"
      },
      {
        text: "Cargados Carajos Shoals [Saint Brandon Islands]",
        value: "MU-CC"
      },
      {
        text: "Rodrigues Island",
        value: "MU-RO"
      }
    ]
  },
  {
    text: "Mayotte",
    value: "YT",
    divisions: [
      {
        text: "Mayotte",
        value: "YT-YT"
      }
    ]
  },
  {
    text: "Mexico",
    value: "MX",
    divisions: [
      {
        text: "Distrito Federal",
        value: "MX-DIF"
      },
      {
        text: "Aguascalientes",
        value: "MX-AGU"
      },
      {
        text: "Baja California",
        value: "MX-BCN"
      },
      {
        text: "Baja California Sur",
        value: "MX-BCS"
      },
      {
        text: "Campeche",
        value: "MX-CAM"
      },
      {
        text: "Coahuila",
        value: "MX-COA"
      },
      {
        text: "Colima",
        value: "MX-COL"
      },
      {
        text: "Chiapas",
        value: "MX-CHP"
      },
      {
        text: "Chihuahua",
        value: "MX-CHH"
      },
      {
        text: "Durango",
        value: "MX-DUR"
      },
      {
        text: "Guerrero",
        value: "MX-GRO"
      },
      {
        text: "Guanajuato",
        value: "MX-GUA"
      },
      {
        text: "Hidalgo",
        value: "MX-HID"
      },
      {
        text: "Jalisco",
        value: "MX-JAL"
      },
      {
        text: "México",
        value: "MX-MEX"
      },
      {
        text: "Michoacán",
        value: "MX-MIC"
      },
      {
        text: "Morelos",
        value: "MX-MOR"
      },
      {
        text: "Nayarit",
        value: "MX-NAY"
      },
      {
        text: "Nuevo León",
        value: "MX-NLE"
      },
      {
        text: "Oaxaca",
        value: "MX-OAX"
      },
      {
        text: "Puebla",
        value: "MX-PUE"
      },
      {
        text: "Queretaro",
        value: "MX-QUE"
      },
      {
        text: "Quintana Roo",
        value: "MX-ROO"
      },
      {
        text: "San Luis Potosí",
        value: "MX-SLP"
      },
      {
        text: "Sinaloa",
        value: "MX-SIN"
      },
      {
        text: "Sonora",
        value: "MX-SON"
      },
      {
        text: "Tabasco",
        value: "MX-TAB"
      },
      {
        text: "Tamaulipas",
        value: "MX-TAM"
      },
      {
        text: "Tlaxcala",
        value: "MX-TLA"
      },
      {
        text: "Veracruz",
        value: "MX-VER"
      },
      {
        text: "Yucatán",
        value: "MX-YUC"
      },
      {
        text: "Zacatecas",
        value: "MX-ZAC"
      }
    ]
  },
  {
    text: "Micronesia",
    value: "FM",
    divisions: [
      {
        text: "chuuk",
        value: "FM-TRK"
      },
      {
        text: "Kosrae",
        value: "FM-KSA"
      },
      {
        text: "Pohnpei",
        value: "FM-PNI"
      },
      {
        text: "Yap",
        value: "FM-YAP"
      }
    ]
  },
  {
    text: "Moldova",
    value: "MD",
    divisions: [
      {
        text: "Bălţi",
        value: "MD-BAL"
      },
      {
        text: "Cahul",
        value: "MD-CAH"
      },
      {
        text: "Chişinău",
        value: "MD-CHI"
      },
      {
        text: "Dubăsari",
        value: "MD-DUB"
      },
      {
        text: "Orhei",
        value: "MD-ORH"
      },
      {
        text: "Rîbniţa",
        value: "MD-RIB"
      },
      {
        text: "Soroca",
        value: "MD-SOC"
      },
      {
        text: "Tighina",
        value: "MD-TIG"
      },
      {
        text: "Tiraspol",
        value: "MD-TIR"
      },
      {
        text: "Ungheni",
        value: "MD-UNG"
      },
      {
        text: "Anenii Noi",
        value: "MD-ANE"
      },
      {
        text: "Basarabeasca",
        value: "MD-BAS"
      },
      {
        text: "Brinceni",
        value: "MD-BRI"
      },
      {
        text: "Cahul",
        value: "MD-CHL"
      },
      {
        text: "Camenca",
        value: "MD-CAM"
      },
      {
        text: "Cantemir",
        value: "MD-CAN"
      },
      {
        text: "Căinari",
        value: "MD-CAI"
      },
      {
        text: "Călăraşi",
        value: "MD-CAL"
      },
      {
        text: "Căuşeni",
        value: "MD-CAS"
      },
      {
        text: "Ciadîr-Lunga",
        value: "MD-CIA"
      },
      {
        text: "Cimişlia",
        value: "MD-CIM"
      },
      {
        text: "Comrat",
        value: "MD-COM"
      },
      {
        text: "Criuleni",
        value: "MD-CRI"
      },
      {
        text: "Donduşeni",
        value: "MD-DON"
      },
      {
        text: "Drochia",
        value: "MD-DRO"
      },
      {
        text: "Dubăsari",
        value: "MD-DBI"
      },
      {
        text: "Edineţ",
        value: "MD-EDI"
      },
      {
        text: "Făleşti",
        value: "MD-FAL"
      },
      {
        text: "Floreşti",
        value: "MD-FLO"
      },
      {
        text: "Glodeni",
        value: "MD-GLO"
      },
      {
        text: "Grigoriopol",
        value: "MD-GRI"
      },
      {
        text: "Hînceşti",
        value: "MD-HIN"
      },
      {
        text: "Ialoveni",
        value: "MD-IAL"
      },
      {
        text: "Leova",
        value: "MD-LEO"
      },
      {
        text: "Nisporeni",
        value: "MD-NIS"
      },
      {
        text: "Ocniţa",
        value: "MD-OCN"
      },
      {
        text: "Orhei",
        value: "MD-OHI"
      },
      {
        text: "Rezina",
        value: "MD-REZ"
      },
      {
        text: "Rîbniţa",
        value: "MD-RIT"
      },
      {
        text: "Rîşcani",
        value: "MD-RIS"
      },
      {
        text: "Sîngerei",
        value: "MD-SIN"
      },
      {
        text: "Slobozia",
        value: "MD-SLO"
      },
      {
        text: "Soroca",
        value: "MD-SOA"
      },
      {
        text: "Străşeni",
        value: "MD-STR"
      },
      {
        text: "Şoldăneşti",
        value: "MD-SOL"
      },
      {
        text: "Ştefan Vodă",
        value: "MD-STE"
      },
      {
        text: "Taraclia",
        value: "MD-TAR"
      },
      {
        text: "Teleneşti",
        value: "MD-TEL"
      },
      {
        text: "Ungheni",
        value: "MD-UGI"
      },
      {
        text: "Vulcăneşti",
        value: "MD-VUL"
      }
    ]
  },
  {
    text: "Monaco",
    value: "MC",
    divisions: [
      {
        text: "Monaco",
        value: "MC-MC"
      }
    ]
  },
  {
    text: "Mongolia",
    value: "MN",
    divisions: [
      {
        text: "Ulaanbaatar",
        value: "MN-1"
      },
      {
        text: "Arhangay",
        value: "MN-073"
      },
      {
        text: "Bayanhongor",
        value: "MN-069"
      },
      {
        text: "Bayan-Ölgiy",
        value: "MN-071"
      },
      {
        text: "Bulgan",
        value: "MN-067"
      },
      {
        text: "Darhan uul",
        value: "MN-037"
      },
      {
        text: "Dornod",
        value: "MN-061"
      },
      {
        text: "Dornogovĭ",
        value: "MN-063"
      },
      {
        text: "Dundgovĭ",
        value: "MN-059"
      },
      {
        text: "Dzavhan",
        value: "MN-057"
      },
      {
        text: "Govĭ-Altay",
        value: "MN-065"
      },
      {
        text: "Govĭ-Sümber",
        value: "MN-064"
      },
      {
        text: "Hentiy",
        value: "MN-039"
      },
      {
        text: "Hovd",
        value: "MN-043"
      },
      {
        text: "Hövsgöl",
        value: "MN-041"
      },
      {
        text: "Ömnögovĭ",
        value: "MN-053"
      },
      {
        text: "Orhon",
        value: "MN-035"
      },
      {
        text: "Övörhangay",
        value: "MN-055"
      },
      {
        text: "Selenge",
        value: "MN-049"
      },
      {
        text: "Sühbaatar",
        value: "MN-051"
      },
      {
        text: "Töv",
        value: "MN-047"
      },
      {
        text: "Uvs",
        value: "MN-046"
      }
    ]
  },
  {
    text: "Montserrat",
    value: "MS",
    divisions: [
      {
        text: "Montserrat",
        value: "MS-MS"
      }
    ]
  },
  {
    text: "Morocco",
    value: "MA",
    divisions: [
      {
        text: "Centre",
        value: "MA-CE"
      },
      {
        text: "Azilal",
        value: "MA-AZI"
      },
      {
        text: "Beni Mellal",
        value: "MA-BEM"
      },
      {
        text: "Ben Slimane",
        value: "MA-BES"
      },
      {
        text: "Boulemane",
        value: "MA-BOM"
      },
      {
        text: "Casablanca [Dar el Beïda]",
        value: "MA-CAS"
      },
      {
        text: "El Jadida",
        value: "MA-JDI"
      },
      {
        text: "Khouribga",
        value: "MA-KHO"
      },
      {
        text: "Settat",
        value: "MA-SET"
      },
      {
        text: "Centre-Nord",
        value: "MA-CN"
      },
      {
        text: "Al Hoceïma",
        value: "MA-HOC"
      },
      {
        text: "Fès",
        value: "MA-FES"
      },
      {
        text: "Sefrou",
        value: "MA-SEF"
      },
      {
        text: "Taounate",
        value: "MA-TAO"
      },
      {
        text: "Taza",
        value: "MA-TAZ"
      },
      {
        text: "Centre-Sud",
        value: "MA-CS"
      },
      {
        text: "El Hajeb",
        value: "MA-HAJ"
      },
      {
        text: "Errachidia",
        value: "MA-ERR"
      },
      {
        text: "Ifrane",
        value: "MA-IFR"
      },
      {
        text: "Khenifra",
        value: "MA-KHN"
      },
      {
        text: "Meknès",
        value: "MA-MEK"
      },
      {
        text: "Est",
        value: "MA-ES"
      },
      {
        text: "Berkane",
        value: "MA-BER"
      },
      {
        text: "Figuig",
        value: "MA-FIG"
      },
      {
        text: "Jrada",
        value: "MA-IRA"
      },
      {
        text: "Nador",
        value: "MA-NAD"
      },
      {
        text: "Oujda",
        value: "MA-OUJ"
      },
      {
        text: "Nord-Ouest",
        value: "MA-NO"
      },
      {
        text: "Chefchaouene",
        value: "MA-CHE"
      },
      {
        text: "Kénitra",
        value: "MA-KEN"
      },
      {
        text: "Khemisset",
        value: "MA-KHE"
      },
      {
        text: "Larache",
        value: "MA-LAR"
      },
      {
        text: "Rabat-Salé",
        value: "MA-RBA"
      },
      {
        text: "Sidi Kacem",
        value: "MA-SIK"
      },
      {
        text: "Tanger",
        value: "MA-TNG"
      },
      {
        text: "Tétouan",
        value: "MA-TET"
      },
      {
        text: "Sud",
        value: "MA-SU"
      },
      {
        text: "Agadir",
        value: "MA-AGD"
      },
      {
        text: "Aït Baha",
        value: "MA-BAH"
      },
      {
        text: "Aït Melloul",
        value: "MA-MEL"
      },
      {
        text: "Assa-Zag",
        value: "MA-ASZ"
      },
      {
        text: "Boujdour (EH)",
        value: "MA-BOD"
      },
      {
        text: "Es Semara (EH)",
        value: "MA-ESM"
      },
      {
        text: "Guelmim",
        value: "MA-GUE"
      },
      {
        text: "Laayoune (EH)",
        value: "MA-LAA"
      },
      {
        text: "Ouarzazate",
        value: "MA-OUA"
      },
      {
        text: "Oued ed Dahab (EH)",
        value: "MA-OUD"
      },
      {
        text: "Tan-Tan",
        value: "MA-TNT"
      },
      {
        text: "Taroudannt",
        value: "MA-TAR"
      },
      {
        text: "Tata",
        value: "MA-TAT"
      },
      {
        text: "Tiznit",
        value: "MA-TIZ"
      },
      {
        text: "Tensift",
        value: "MA-TS"
      },
      {
        text: "Al Haouz",
        value: "MA-HAO"
      },
      {
        text: "Chichaoua",
        value: "MA-CHI"
      },
      {
        text: "Essaouira",
        value: "MA-ESI"
      },
      {
        text: "Kelaat Sraghna",
        value: "MA-KES"
      },
      {
        text: "Marrakech",
        value: "MA-MAR"
      },
      {
        text: "Safi",
        value: "MA-SAF"
      }
    ]
  },
  {
    text: "Mozambique",
    value: "MZ",
    divisions: [
      {
        text: "Maputo",
        value: "MZ-MPM"
      },
      {
        text: "Cabo Delgado",
        value: "MZ-P"
      },
      {
        text: "Gaza",
        value: "MZ-G"
      },
      {
        text: "Inhambane",
        value: "MZ-I"
      },
      {
        text: "Manica",
        value: "MZ-B"
      },
      {
        text: "Maputo",
        value: "MZ-L"
      },
      {
        text: "Nampula",
        value: "MZ-N"
      },
      {
        text: "Niassa",
        value: "MZ-A"
      },
      {
        text: "Sofala",
        value: "MZ-S"
      },
      {
        text: "Tete",
        value: "MZ-T"
      },
      {
        text: "Zambézia",
        value: "MZ-Q"
      }
    ]
  },
  {
    text: "Myanmar",
    value: "MM",
    divisions: [
      {
        text: "Ayeyarwady",
        value: "MM-07"
      },
      {
        text: "Bago",
        value: "MM-02"
      },
      {
        text: "Magway",
        value: "MM-03"
      },
      {
        text: "Mandalay",
        value: "MM-04"
      },
      {
        text: "Sagaing",
        value: "MM-01"
      },
      {
        text: "Tanintharyi",
        value: "MM-05"
      },
      {
        text: "Yangon",
        value: "MM-06"
      },
      {
        text: "Chin",
        value: "MM-14"
      },
      {
        text: "Kachin",
        value: "MM-11"
      },
      {
        text: "Kayah",
        value: "MM-12"
      },
      {
        text: "Kayin",
        value: "MM-13"
      },
      {
        text: "Mon",
        value: "MM-15"
      },
      {
        text: "Rakhine",
        value: "MM-16"
      },
      {
        text: "Shan",
        value: "MM-17"
      }
    ]
  },
  {
    text: "Namibia",
    value: "NA",
    divisions: [
      {
        text: "Caprivi",
        value: "NA-CA"
      },
      {
        text: "Erongo",
        value: "NA-ER"
      },
      {
        text: "Hardap",
        value: "NA-HA"
      },
      {
        text: "Karas",
        value: "NA-KA"
      },
      {
        text: "Khomas",
        value: "NA-KH"
      },
      {
        text: "Kunene",
        value: "NA-KU"
      },
      {
        text: "Ohangwena",
        value: "NA-OW"
      },
      {
        text: "Okavango",
        value: "NA-OK"
      },
      {
        text: "Omaheke",
        value: "NA-OH"
      },
      {
        text: "Omusati",
        value: "NA-OS"
      },
      {
        text: "Oshana",
        value: "NA-ON"
      },
      {
        text: "Oshikoto",
        value: "NA-OT"
      },
      {
        text: "Otjozondjupa",
        value: "NA-OD"
      }
    ]
  },
  {
    text: "Nauru",
    value: "NR",
    divisions: [
      {
        text: "Nauru",
        value: "NR-NR"
      }
    ]
  },
  {
    text: "Nepal",
    value: "NP",
    divisions: [
      {
        text: "Madhyamanchal",
        value: "NP-1"
      },
      {
        text: "Bagmati",
        value: "NP-BA"
      },
      {
        text: "Janakpur",
        value: "NP-JA"
      },
      {
        text: "Narayani",
        value: "NP-NA"
      },
      {
        text: "Madhya Pashchimanchal",
        value: "NP-2"
      },
      {
        text: "Bheri",
        value: "NP-BH"
      },
      {
        text: "Karnali",
        value: "NP-KA"
      },
      {
        text: "Rapti",
        value: "NP-RA"
      },
      {
        text: "Pashchimanchal",
        value: "NP-3"
      },
      {
        text: "Dhawalagiri",
        value: "NP-DH"
      },
      {
        text: "Gandaki",
        value: "NP-GA"
      },
      {
        text: "Lumbini",
        value: "NP-LU"
      },
      {
        text: "Purwanchal",
        value: "NP-4"
      },
      {
        text: "Kosi [Koshi]",
        value: "NP-KO"
      },
      {
        text: "Mechi",
        value: "NP-ME"
      },
      {
        text: "Sagarmatha",
        value: "NP-SA"
      },
      {
        text: "Sudur Pashchimanchal",
        value: "NP-5"
      },
      {
        text: "Mahakali",
        value: "NP-MA"
      },
      {
        text: "Seti",
        value: "NP-SE"
      }
    ]
  },
  {
    text: "Netherlands",
    value: "NL",
    divisions: [
      {
        text: "Drenthe",
        value: "NL-DR"
      },
      {
        text: "Flevoland",
        value: "NL-FL"
      },
      {
        text: "Friesland",
        value: "NL-FR"
      },
      {
        text: "Gelderland",
        value: "NL-GE"
      },
      {
        text: "Groningen",
        value: "NL-GR"
      },
      {
        text: "Limburg",
        value: "NL-LI"
      },
      {
        text: "Noord-Brabant",
        value: "NL-NB"
      },
      {
        text: "Noord-Holland",
        value: "NL-NH"
      },
      {
        text: "Overijssel",
        value: "NL-OV"
      },
      {
        text: "Utrecht",
        value: "NL-UT"
      },
      {
        text: "Zeeland",
        value: "NL-ZE"
      },
      {
        text: "Zuid-Holland",
        value: "NL-ZH"
      }
    ]
  },
  {
    text: "Netherlands Antilles",
    value: "AN",
    divisions: [
      {
        text: "Netherlands Antilles",
        value: "AN-AN"
      }
    ]
  },
  {
    text: "New Caledonia",
    value: "NC",
    divisions: [
      {
        text: "New Caledonia",
        value: "NC-NC"
      }
    ]
  },
  {
    text: "New Zealand",
    value: "NZ",
    divisions: [
      {
        text: "North Island",
        value: "NZ-N"
      },
      {
        text: "Auckland",
        value: "NZ-AUK"
      },
      {
        text: "Bay of Plenty",
        value: "NZ-BOP"
      },
      {
        text: "Gisborne",
        value: "NZ-GIS"
      },
      {
        text: "Hawkes's Bay",
        value: "NZ-HKB"
      },
      {
        text: "Manawatu-Wanganui",
        value: "NZ-MWT"
      },
      {
        text: "Northland",
        value: "NZ-NTL"
      },
      {
        text: "Taranaki",
        value: "NZ-TKI"
      },
      {
        text: "Waikato",
        value: "NZ-WKO"
      },
      {
        text: "Wellington",
        value: "NZ-WGN"
      },
      {
        text: "South Island",
        value: "NZ-S"
      },
      {
        text: "Canterbury",
        value: "NZ-CAN"
      },
      {
        text: "Marlborough",
        value: "NZ-MBH"
      },
      {
        text: "Nelson",
        value: "NZ-NSN"
      },
      {
        text: "Otago",
        value: "NZ-OTA"
      },
      {
        text: "Southland",
        value: "NZ-STL"
      },
      {
        text: "Tasman",
        value: "NZ-TAS"
      },
      {
        text: "West Coast",
        value: "NZ-WTC"
      }
    ]
  },
  {
    text: "Nicaragua",
    value: "NI",
    divisions: [
      {
        text: "Boaco",
        value: "NI-BO"
      },
      {
        text: "Carazo",
        value: "NI-CA"
      },
      {
        text: "Chinandega",
        value: "NI-CI"
      },
      {
        text: "Chontales",
        value: "NI-CO"
      },
      {
        text: "Estelí",
        value: "NI-ES"
      },
      {
        text: "Granada",
        value: "NI-GR"
      },
      {
        text: "Jinotega",
        value: "NI-JI"
      },
      {
        text: "León",
        value: "NI-LE"
      },
      {
        text: "Madriz",
        value: "NI-MD"
      },
      {
        text: "Manaqua",
        value: "NI-MN"
      },
      {
        text: "Masaya",
        value: "NI-MS"
      },
      {
        text: "Matagalpa",
        value: "NI-MT"
      },
      {
        text: "Nueva Segovia",
        value: "NI-NS"
      },
      {
        text: "Río San Juan",
        value: "NI-SJ"
      },
      {
        text: "Rivas",
        value: "NI-RI"
      },
      {
        text: "Zelaya",
        value: "NI-ZE"
      }
    ]
  },
  {
    text: "Niger",
    value: "NE",
    divisions: [
      {
        text: "Niamey",
        value: "NE-8"
      },
      {
        text: "Agadez",
        value: "NE-1"
      },
      {
        text: "Diffa",
        value: "NE-2"
      },
      {
        text: "Dosso",
        value: "NE-3"
      },
      {
        text: "Maradi",
        value: "NE-4"
      },
      {
        text: "Tahoua",
        value: "NE-5"
      },
      {
        text: "Tillaberi",
        value: "NE-6"
      },
      {
        text: "Zinder",
        value: "NE-7"
      }
    ]
  },
  {
    text: "Nigeria",
    value: "NG",
    divisions: [
      {
        text: "Abuja Capital Territory",
        value: "NG-FC"
      },
      {
        text: "Abia",
        value: "NG-AB"
      },
      {
        text: "Adamawa",
        value: "NG-AD"
      },
      {
        text: "Akwa Ibom",
        value: "NG-AK"
      },
      {
        text: "Anambra",
        value: "NG-AN"
      },
      {
        text: "Bauchi",
        value: "NG-BA"
      },
      {
        text: "Benue",
        value: "NG-BE"
      },
      {
        text: "Borno",
        value: "NG-BO"
      },
      {
        text: "Cross River",
        value: "NG-CR"
      },
      {
        text: "Delta",
        value: "NG-DE"
      },
      {
        text: "Edo",
        value: "NG-ED"
      },
      {
        text: "Enugu",
        value: "NG-EN"
      },
      {
        text: "Imo",
        value: "NG-IM"
      },
      {
        text: "Jigawa",
        value: "NG-JI"
      },
      {
        text: "Kaduna",
        value: "NG-KD"
      },
      {
        text: "Kano",
        value: "NG-KN"
      },
      {
        text: "Katsina",
        value: "NG-KT"
      },
      {
        text: "Kebbi",
        value: "NG-KE"
      },
      {
        text: "Kogi",
        value: "NG-KO"
      },
      {
        text: "Kwara",
        value: "NG-KW"
      },
      {
        text: "Lagos",
        value: "NG-LA"
      },
      {
        text: "Niger",
        value: "NG-NI"
      },
      {
        text: "Ogun",
        value: "NG-OG"
      },
      {
        text: "Ondo",
        value: "NG-ON"
      },
      {
        text: "Osun",
        value: "NG-OS"
      },
      {
        text: "Oyo",
        value: "NG-OY"
      },
      {
        text: "Plateau",
        value: "NG-PL"
      },
      {
        text: "Rivers",
        value: "NG-RI"
      },
      {
        text: "Sokoto",
        value: "NG-SO"
      },
      {
        text: "Taraba",
        value: "NG-TA"
      },
      {
        text: "Yobe",
        value: "NG-YO"
      }
    ]
  },
  {
    text: "Niue",
    value: "NU",
    divisions: [
      {
        text: "Niue",
        value: "NU-NU"
      }
    ]
  },
  {
    text: "Norfolk Island",
    value: "NF",
    divisions: [
      {
        text: "Norfolk Island",
        value: "NF-NF"
      }
    ]
  },
  {
    text: "Northern Mariana Islands",
    value: "MP",
    divisions: [
      {
        text: "Northern Mariana Islands",
        value: "MP-MP"
      }
    ]
  },
  {
    text: "Norway",
    value: "NO",
    divisions: [
      {
        text: "Akershus",
        value: "NO-02"
      },
      {
        text: "Aust-Agder",
        value: "NO-09"
      },
      {
        text: "Buskerud",
        value: "NO-06"
      },
      {
        text: "Finnmark",
        value: "NO-20"
      },
      {
        text: "Hedmark",
        value: "NO-04"
      },
      {
        text: "Hordaland",
        value: "NO-12"
      },
      {
        text: "Møre og Romsdal",
        value: "NO-15"
      },
      {
        text: "Nordland",
        value: "NO-18"
      },
      {
        text: "Nord-Trøndelag",
        value: "NO-17"
      },
      {
        text: "Oppland",
        value: "NO-05"
      },
      {
        text: "Oslo",
        value: "NO-03"
      },
      {
        text: "Rogaland",
        value: "NO-11"
      },
      {
        text: "Sogn og Fjordane",
        value: "NO-14"
      },
      {
        text: "Sør-Trøndelag",
        value: "NO-16"
      },
      {
        text: "Telemark",
        value: "NO-08"
      },
      {
        text: "Troms",
        value: "NO-19"
      },
      {
        text: "Vest-Agder",
        value: "NO-10"
      },
      {
        text: "Vestfold",
        value: "NO-07"
      },
      {
        text: "Østfold",
        value: "NO-01"
      },
      {
        text: "Jan Mayen (Arctic Region)",
        value: "NO-22"
      },
      {
        text: "Svalbard (Arctic Region)",
        value: "NO-21"
      }
    ]
  },
  {
    text: "Oman",
    value: "OM",
    divisions: [
      {
        text: "Ad Dākhilīyah",
        value: "OM-DA"
      },
      {
        text: "Al Bāţinah",
        value: "OM-BA"
      },
      {
        text: "Al Janūbīyah [Zufār]",
        value: "OM-JA"
      },
      {
        text: "Al Wusţā",
        value: "OM-WU"
      },
      {
        text: "Ash Sharqīyah",
        value: "OM-SH"
      },
      {
        text: "Az Zāhirah",
        value: "OM-ZA"
      },
      {
        text: "Masqaţ",
        value: "OM-MA"
      },
      {
        text: "Musandam",
        value: "OM-MU"
      }
    ]
  },
  {
    text: "Pakistan",
    value: "PK",
    divisions: [
      {
        text: "Islamabad",
        value: "PK-IS"
      },
      {
        text: "Baluchistan (Balochistān)",
        value: "PK-BA"
      },
      {
        text: "North-West Frontier",
        value: "PK-NW"
      },
      {
        text: "Punjab",
        value: "PK-PB"
      },
      {
        text: "Sind (Sindh)",
        value: "PK-SD"
      },
      {
        text: "Federally Administered Tribal Areas",
        value: "PK-TA"
      },
      {
        text: "Azad Kashmir",
        value: "PK-JK"
      },
      {
        text: "Northern Areas",
        value: "PK-NA"
      }
    ]
  },
  {
    text: "Palau",
    value: "PW",
    divisions: [
      {
        text: "Palau",
        value: "PW-PW"
      }
    ]
  },
  {
    text: "Panama",
    value: "PA",
    divisions: [
      {
        text: "Botas del Toro",
        value: "PA-1"
      },
      {
        text: "Coclé",
        value: "PA-2"
      },
      {
        text: "Colón",
        value: "PA-3"
      },
      {
        text: "Chiriquī",
        value: "PA-4"
      },
      {
        text: "Darién",
        value: "PA-5"
      },
      {
        text: "Herrera",
        value: "PA-6"
      },
      {
        text: "Los Santos",
        value: "PA-7"
      },
      {
        text: "Panamá",
        value: "PA-8"
      },
      {
        text: "Veraguas",
        value: "PA-9"
      },
      {
        text: "Comarca de San Blas",
        value: "PA-0"
      }
    ]
  },
  {
    text: "Papua New Guinea",
    value: "PG",
    divisions: [
      {
        text: "National Capital District (Port Moresby)",
        value: "PG-NCD"
      },
      {
        text: "Central",
        value: "PG-CPM"
      },
      {
        text: "Chimbu",
        value: "PG-CPK"
      },
      {
        text: "Eastern Highlands",
        value: "PG-EHG"
      },
      {
        text: "East New Britain",
        value: "PG-EBR"
      },
      {
        text: "East Sepik",
        value: "PG-ESW"
      },
      {
        text: "Enga",
        value: "PG-EPW"
      },
      {
        text: "Gulf",
        value: "PG-GPK"
      },
      {
        text: "Madang",
        value: "PG-MPM"
      },
      {
        text: "Manus",
        value: "PG-MRL"
      },
      {
        text: "Milne Bay",
        value: "PG-MBA"
      },
      {
        text: "Morobe",
        value: "PG-MPL"
      },
      {
        text: "New Ireland",
        value: "PG-NIK"
      },
      {
        text: "Northern",
        value: "PG-NPP"
      },
      {
        text: "North Solomons",
        value: "PG-NSA"
      },
      {
        text: "Sandaun [West Sepik]",
        value: "PG-SAN"
      },
      {
        text: "Southern Highlands",
        value: "PG-SHM"
      },
      {
        text: "Western",
        value: "PG-WPD"
      },
      {
        text: "Western Highlands",
        value: "PG-WHM"
      },
      {
        text: "West New Britain",
        value: "PG-WBK"
      }
    ]
  },
  {
    text: "Paraguay",
    value: "PY",
    divisions: [
      {
        text: "Asunción",
        value: "PY-ASU"
      },
      {
        text: "Alto Paraguay",
        value: "PY-16"
      },
      {
        text: "Alto Parang",
        value: "PY-10"
      },
      {
        text: "Amambay",
        value: "PY-13"
      },
      {
        text: "Boquerón",
        value: "PY-19"
      },
      {
        text: "Caaguazú",
        value: "PY-5"
      },
      {
        text: "Caazapá",
        value: "PY-6"
      },
      {
        text: "Canindeyú",
        value: "PY-14"
      },
      {
        text: "Central",
        value: "PY-11"
      },
      {
        text: "Concepción",
        value: "PY-1"
      },
      {
        text: "Cordillera",
        value: "PY-3"
      },
      {
        text: "Guairá",
        value: "PY-4"
      },
      {
        text: "Itapúa",
        value: "PY-7"
      },
      {
        text: "Misiones",
        value: "PY-8"
      },
      {
        text: "Neembucú",
        value: "PY-12"
      },
      {
        text: "Paraguarī",
        value: "PY-9"
      },
      {
        text: "Presidente Hayes",
        value: "PY-15"
      },
      {
        text: "San Pedro",
        value: "PY-2"
      }
    ]
  },
  {
    text: "Peru",
    value: "PE",
    divisions: [
      {
        text: "El Callao",
        value: "PE-CAL"
      },
      {
        text: "Amazonas",
        value: "PE-AMA"
      },
      {
        text: "Ancash",
        value: "PE-ANC"
      },
      {
        text: "Apurímac",
        value: "PE-APU"
      },
      {
        text: "Arequipa",
        value: "PE-ARE"
      },
      {
        text: "Ayacucho",
        value: "PE-AYA"
      },
      {
        text: "Cajamarca",
        value: "PE-CAJ"
      },
      {
        text: "Cuzco [Cusco]",
        value: "PE-CUS"
      },
      {
        text: "Huancavelica",
        value: "PE-HUV"
      },
      {
        text: "Huánuco",
        value: "PE-HUC"
      },
      {
        text: "Ica",
        value: "PE-ICA"
      },
      {
        text: "Junín",
        value: "PE-JUN"
      },
      {
        text: "La Libertad",
        value: "PE-LAL"
      },
      {
        text: "Lambayeque",
        value: "PE-LAM"
      },
      {
        text: "Lima",
        value: "PE-LIM"
      },
      {
        text: "Loreto",
        value: "PE-LOR"
      },
      {
        text: "Madre de Dios",
        value: "PE-MDD"
      },
      {
        text: "Moquegua",
        value: "PE-MOQ"
      },
      {
        text: "Pasco",
        value: "PE-PAS"
      },
      {
        text: "Piura",
        value: "PE-PIU"
      },
      {
        text: "Puno",
        value: "PE-PUN"
      },
      {
        text: "San Martín",
        value: "PE-SAM"
      },
      {
        text: "Tacna",
        value: "PE-TAC"
      },
      {
        text: "Tumbes",
        value: "PE-TUM"
      },
      {
        text: "Ucayali",
        value: "PE-UCA"
      }
    ]
  },
  {
    text: "Philippines",
    value: "PH",
    divisions: [
      {
        text: "Philippines",
        value: "PH-PH"
      }
    ]
  },
  {
    text: "Pitcairn",
    value: "PN",
    divisions: [
      {
        text: "Pitcairn",
        value: "PN-PN"
      }
    ]
  },
  {
    text: "Poland",
    value: "PL",
    divisions: [
      {
        text: "Biała Podlaska",
        value: "PL-BP"
      },
      {
        text: "Białystok",
        value: "PL-BK"
      },
      {
        text: "Bielsko",
        value: "PL-BB"
      },
      {
        text: "Bydgoszcz",
        value: "PL-BY"
      },
      {
        text: "Chełm",
        value: "PL-CH"
      },
      {
        text: "Ciechanów",
        value: "PL-CI"
      },
      {
        text: "Czestochowa",
        value: "PL-CZ"
      },
      {
        text: "Elblag",
        value: "PL-EL"
      },
      {
        text: "Gdańsk",
        value: "PL-GD"
      },
      {
        text: "Gorzów",
        value: "PL-GO"
      },
      {
        text: "Jelenia Gera",
        value: "PL-JG"
      },
      {
        text: "Kalisz",
        value: "PL-KL"
      },
      {
        text: "Katowice",
        value: "PL-KA"
      },
      {
        text: "Kielce",
        value: "PL-KI"
      },
      {
        text: "Konin",
        value: "PL-KN"
      },
      {
        text: "Koszalin",
        value: "PL-KO"
      },
      {
        text: "Kraków",
        value: "PL-KR"
      },
      {
        text: "Krosno",
        value: "PL-KS"
      },
      {
        text: "Legnica",
        value: "PL-LG"
      },
      {
        text: "Leszno",
        value: "PL-LE"
      },
      {
        text: "Lublin",
        value: "PL-LU"
      },
      {
        text: "Łomia",
        value: "PL-LO"
      },
      {
        text: "Łódź",
        value: "PL-LD"
      },
      {
        text: "Nowy Sacz",
        value: "PL-NS"
      },
      {
        text: "Olsztyn",
        value: "PL-OL"
      },
      {
        text: "Opole",
        value: "PL-OP"
      },
      {
        text: "Ostrołeka",
        value: "PL-OS"
      },
      {
        text: "Piła",
        value: "PL-PI"
      },
      {
        text: "Piotrków",
        value: "PL-PT"
      },
      {
        text: "Płock",
        value: "PL-PL"
      },
      {
        text: "Poznań",
        value: "PL-PO"
      },
      {
        text: "Przemyśl",
        value: "PL-PR"
      },
      {
        text: "Radom",
        value: "PL-RA"
      },
      {
        text: "Rzeszów",
        value: "PL-RZ"
      },
      {
        text: "Siedlce",
        value: "PL-SE"
      },
      {
        text: "Sieradz",
        value: "PL-SI"
      },
      {
        text: "Skierniewice",
        value: "PL-SK"
      },
      {
        text: "Słupsk",
        value: "PL-SL"
      },
      {
        text: "Suwałki",
        value: "PL-SU"
      },
      {
        text: "Szczecin",
        value: "PL-SZ"
      },
      {
        text: "Tarnobrzeg",
        value: "PL-TG"
      },
      {
        text: "Tarnów",
        value: "PL-TA"
      },
      {
        text: "Toruń",
        value: "PL-TO"
      },
      {
        text: "Wałbrzych",
        value: "PL-WB"
      },
      {
        text: "Warszawa",
        value: "PL-WA"
      },
      {
        text: "Włocławek",
        value: "PL-WL"
      },
      {
        text: "Wrocław",
        value: "PL-WR"
      },
      {
        text: "Zamość",
        value: "PL-ZA"
      },
      {
        text: "Zielona Góra",
        value: "PL-ZG"
      }
    ]
  },
  {
    text: "Portugal",
    value: "PT",
    divisions: [
      {
        text: "Aveiro",
        value: "PT-01"
      },
      {
        text: "Beja",
        value: "PT-02"
      },
      {
        text: "Braga",
        value: "PT-03"
      },
      {
        text: "Bragança",
        value: "PT-04"
      },
      {
        text: "Castelo Branco",
        value: "PT-05"
      },
      {
        text: "Coimbra",
        value: "PT-06"
      },
      {
        text: "Évora",
        value: "PT-07"
      },
      {
        text: "Faro",
        value: "PT-08"
      },
      {
        text: "Guarda",
        value: "PT-09"
      },
      {
        text: "Leiria",
        value: "PT-10"
      },
      {
        text: "Lisboa",
        value: "PT-11"
      },
      {
        text: "Portalegre",
        value: "PT-12"
      },
      {
        text: "Porto",
        value: "PT-13"
      },
      {
        text: "Santarém",
        value: "PT-14"
      },
      {
        text: "Setúbal",
        value: "PT-15"
      },
      {
        text: "Viana do Castelo",
        value: "PT-16"
      },
      {
        text: "Vila Real",
        value: "PT-17"
      },
      {
        text: "Viseu",
        value: "PT-18"
      },
      {
        text: "Regiāo Autónoma dos Açores",
        value: "PT-20"
      },
      {
        text: "Regiāo Autónoma da Madeira",
        value: "PT-30"
      }
    ]
  },
  {
    text: "Puerto Rico",
    value: "PR",
    divisions: [
      {
        text: "Puerto Rico",
        value: "PR-PR"
      }
    ]
  },
  {
    text: "Qatar",
    value: "QA",
    divisions: [
      {
        text: "Ad Dawḩah",
        value: "QA-DA"
      },
      {
        text: "Al Ghuwayrīyah",
        value: "QA-GH"
      },
      {
        text: "Al Jumaylīyah",
        value: "QA-JU"
      },
      {
        text: "Al Khawr",
        value: "QA-KH"
      },
      {
        text: "Al Wakrah",
        value: "QA-WA"
      },
      {
        text: "Ar Rayyān",
        value: "QA-RA"
      },
      {
        text: "Jarīyān al Bāţnah",
        value: "QA-JB"
      },
      {
        text: "Madīnat ash Shamāl",
        value: "QA-MS"
      },
      {
        text: "Umm Şalāl",
        value: "QA-US"
      }
    ]
  },
  {
    text: "Reunion",
    value: "RE",
    divisions: [
      {
        text: "Reunion",
        value: "RE-RE"
      }
    ]
  },
  {
    text: "Romania",
    value: "RO",
    divisions: [
      {
        text: "Bucureşti",
        value: "RO-B"
      },
      {
        text: "Alba",
        value: "RO-AB"
      },
      {
        text: "Arad",
        value: "RO-AR"
      },
      {
        text: "Argeş",
        value: "RO-AG"
      },
      {
        text: "Bacău",
        value: "RO-BC"
      },
      {
        text: "Bihor",
        value: "RO-BH"
      },
      {
        text: "Bistriţa-Năsăud",
        value: "RO-BN"
      },
      {
        text: "Botoşani",
        value: "RO-BT"
      },
      {
        text: "Braşov",
        value: "RO-BV"
      },
      {
        text: "Brăila",
        value: "RO-BR"
      },
      {
        text: "Buzău",
        value: "RO-BZ"
      },
      {
        text: "Caraş-Severin",
        value: "RO-CS"
      },
      {
        text: "Călăraşi",
        value: "RO-CL"
      },
      {
        text: "Cluj",
        value: "RO-CJ"
      },
      {
        text: "Constanţa",
        value: "RO-CT"
      },
      {
        text: "Covasna",
        value: "RO-CV"
      },
      {
        text: "Dâmboviţa",
        value: "RO-DB"
      },
      {
        text: "Dolj",
        value: "RO-DJ"
      },
      {
        text: "Galaţi",
        value: "RO-GL"
      },
      {
        text: "Giurgiu",
        value: "RO-GR"
      },
      {
        text: "Gorj",
        value: "RO-GJ"
      },
      {
        text: "Harghita",
        value: "RO-HR"
      },
      {
        text: "Hunedoara",
        value: "RO-HD"
      },
      {
        text: "Ialomiţa",
        value: "RO-IL"
      },
      {
        text: "Iaşi",
        value: "RO-IS"
      },
      {
        text: "Maramureş",
        value: "RO-MM"
      },
      {
        text: "Mehedinţi",
        value: "RO-MH"
      },
      {
        text: "Mureş",
        value: "RO-MS"
      },
      {
        text: "Neamţ",
        value: "RO-NT"
      },
      {
        text: "Olt",
        value: "RO-OT"
      },
      {
        text: "Prahova",
        value: "RO-PH"
      },
      {
        text: "Satu Mare",
        value: "RO-SM"
      },
      {
        text: "Sălaj",
        value: "RO-SJ"
      },
      {
        text: "Sibiu",
        value: "RO-SB"
      },
      {
        text: "Suceava",
        value: "RO-SV"
      },
      {
        text: "Teleorman",
        value: "RO-TR"
      },
      {
        text: "Timiş",
        value: "RO-TM"
      },
      {
        text: "Tulcea",
        value: "RO-TL"
      },
      {
        text: "Vaslui",
        value: "RO-VS"
      },
      {
        text: "Vâlcea",
        value: "RO-VL"
      },
      {
        text: "Vrancea",
        value: "RO-VN"
      }
    ]
  },
  {
    text: "Russian Federation",
    value: "RU",
    divisions: [
      {
        text: "Adygeya, Respublika",
        value: "RU-AD"
      },
      {
        text: "Altay, Respublika",
        value: "RU-AL"
      },
      {
        text: "Bashkortostan, Respublika",
        value: "RU-BA"
      },
      {
        text: "Buryatiya, Respublika",
        value: "RU-BU"
      },
      {
        text: "Chechenskaya Respublika",
        value: "RU-CE"
      },
      {
        text: "Chuvashskaya Respublika",
        value: "RU-CU"
      },
      {
        text: "Dagestan, Respublika",
        value: "RU-DA"
      },
      {
        text: "Ingushskaya Respublika",
        value: "RU-IN"
      },
      {
        text: "Kabardino-Balkarskaya Respublika",
        value: "RU-KB"
      },
      {
        text: "Kalmykiya, Respublika",
        value: "RU-KL"
      },
      {
        text: "Karachayevo-Cherkesskaya Respublika",
        value: "RU-KC"
      },
      {
        text: "Kareliya, Respublika",
        value: "RU-KR"
      },
      {
        text: "Khakasiya, Respublika",
        value: "RU-KK"
      },
      {
        text: "Komi, Respublika",
        value: "RU-KO"
      },
      {
        text: "Mariy El, Respublika",
        value: "RU-ME"
      },
      {
        text: "Mordoviya, Respublika",
        value: "RU-MO"
      },
      {
        text: "Sakha, Respublika [Yakutiya]",
        value: "RU-SA"
      },
      {
        text: "Severnaya Osetiya, Respublika [Alaniya]",
        value: "RU-SE"
      },
      {
        text: "Tatarstan, Respublika",
        value: "RU-TA"
      },
      {
        text: "Tyva, Respublika [Tuva]",
        value: "RU-TY"
      },
      {
        text: "Udmurtskaya Respublika",
        value: "RU-UD"
      },
      {
        text: "Altayskiy kray",
        value: "RU-ALT"
      },
      {
        text: "Khabarovskiy kray",
        value: "RU-KHA"
      },
      {
        text: "Krasnodarskiy kray",
        value: "RU-KDA"
      },
      {
        text: "Krasnoyarskiy kray",
        value: "RU-KYA"
      },
      {
        text: "Primorskiy kray",
        value: "RU-PRI"
      },
      {
        text: "Stavropol 'skiy kray",
        value: "RU-STA"
      },
      {
        text: "Amurskaya Oblast'",
        value: "RU-AMU"
      },
      {
        text: "Arkhangel'skaya Oblast'",
        value: "RU-ARK"
      },
      {
        text: "Astrakhanskaya Oblast'",
        value: "RU-AST"
      },
      {
        text: "Belgorodskaya Oblast'",
        value: "RU-BEL"
      },
      {
        text: "Bryanskaya Oblast'",
        value: "RU-BRY"
      },
      {
        text: "Chelyabinskaya Oblast'",
        value: "RU-CHE"
      },
      {
        text: "Chitinskaya Oblast'",
        value: "RU-CHI"
      },
      {
        text: "Irkutskaya Oblast'",
        value: "RU-IRK"
      },
      {
        text: "Ivanovskaya Oblast'",
        value: "RU-IVA"
      },
      {
        text: "Kaliningradskaya Oblast'",
        value: "RU-KGD"
      },
      {
        text: "Kaluzhskaya Oblast'",
        value: "RU-KLU"
      },
      {
        text: "Kamchatskaya Oblast'",
        value: "RU-KAM"
      },
      {
        text: "Kemerovskaya Oblast'",
        value: "RU-KEM"
      },
      {
        text: "Kirovskaya Oblast'",
        value: "RU-KIR"
      },
      {
        text: "Kostromskaya Oblast'",
        value: "RU-KOS"
      },
      {
        text: "Kurganskaya Oblast'",
        value: "RU-KGN"
      },
      {
        text: "Kurskaya Oblast'",
        value: "RU-KRS"
      },
      {
        text: "Leningradskaya Oblast'",
        value: "RU-LEN"
      },
      {
        text: "Lipetskaya Oblast'",
        value: "RU-LIP"
      },
      {
        text: "Magadanskaya Oblast'",
        value: "RU-MAG"
      },
      {
        text: "Moskovskaya Oblast'",
        value: "RU-MOS"
      },
      {
        text: "Murmanskaya Oblast'",
        value: "RU-MUR"
      },
      {
        text: "Nizhegorodskaya Oblast'",
        value: "RU-NIZ"
      },
      {
        text: "Novgorodskaya Oblast'",
        value: "RU-NGR"
      },
      {
        text: "Novosibirskaya Oblast'",
        value: "RU-NVS"
      },
      {
        text: "Omskaya Oblast'",
        value: "RU-OMS"
      },
      {
        text: "Orenburgskaya Oblast'",
        value: "RU-ORE"
      },
      {
        text: "Orlovskaya Oblast'",
        value: "RU-ORL"
      },
      {
        text: "Penzenskaya Oblast'",
        value: "RU-PNZ"
      },
      {
        text: "Permskaya Oblast'",
        value: "RU-PER"
      },
      {
        text: "Pskovskaya Oblast'",
        value: "RU-PSK"
      },
      {
        text: "Rostovskaya Oblast'",
        value: "RU-ROS"
      },
      {
        text: "Ryazanskaya Oblast'",
        value: "RU-RYA"
      },
      {
        text: "Sakhalinskaya Oblast'",
        value: "RU-SAK"
      },
      {
        text: "Samarskaya Oblast’",
        value: "RU-SAM"
      },
      {
        text: "Saratovskaya Oblast'",
        value: "RU-SAR"
      },
      {
        text: "Smolenskaya Oblast'",
        value: "RU-SMO"
      },
      {
        text: "Sverdlovskaya Oblast'",
        value: "RU-SVE"
      },
      {
        text: "Tambovskaya Oblast'",
        value: "RU-TAM"
      },
      {
        text: "Tomskaya Oblast’",
        value: "RU-TOM"
      },
      {
        text: "Tul'skaya Oblast'",
        value: "RU-TUL"
      },
      {
        text: "Tverskaya Oblast'",
        value: "RU-TVE"
      },
      {
        text: "Tyumenskaya Oblast'",
        value: "RU-TYU"
      },
      {
        text: "Ul'yanovskaya Oblast'",
        value: "RU-ULY"
      },
      {
        text: "Vladimirskaya Oblast'",
        value: "RU-VLA"
      },
      {
        text: "Volgogradskaya Oblast'",
        value: "RU-VGG"
      },
      {
        text: "Vologodskaya Oblast'",
        value: "RU-VLG"
      },
      {
        text: "Voronezhskaya Oblast'",
        value: "RU-VOR"
      },
      {
        text: "Yaroslavskaya Oblast'",
        value: "RU-YAR"
      },
      {
        text: "Moskva",
        value: "RU-MOW"
      },
      {
        text: "Sankt-Peterburg",
        value: "RU-SPE"
      },
      {
        text: "Yevreyskaya avtonomnaya oblast'",
        value: "RU-YEV"
      },
      {
        text: "Aginskiy Buryatskiy avtonomnyy okrug",
        value: "RU-AGB"
      },
      {
        text: "Chukotskiy avtonomnyy okrug",
        value: "RU-CHU"
      },
      {
        text: "Evenkiyskiy avtonomnyy okrug",
        value: "RU-EVE"
      },
      {
        text: "Khanty-Mansiyskiy avtonomnyy okrug",
        value: "RU-KHM"
      },
      {
        text: "Komi-Permyatskiy avtonomnyy okrug",
        value: "RU-KOP"
      },
      {
        text: "Koryakskiy avtonomnyy okrug",
        value: "RU-KOR"
      },
      {
        text: "Nenetskiy avtonomnyy okrug",
        value: "RU-NEN"
      },
      {
        text: "Taymyrskiy (Dolgano-Nenetskiy) avtonomnyy okrug",
        value: "RU-TAY"
      },
      {
        text: "Ust’-Ordynskiy Buryatskiy avtonomnyy okrug",
        value: "RU-UOB"
      },
      {
        text: "Yamalo-Nenetskiy avtonomnyy okrug",
        value: "RU-YAN"
      }
    ]
  },
  {
    text: "Rwanda",
    value: "RW",
    divisions: [
      {
        text: "Butare",
        value: "RW-C"
      },
      {
        text: "Byumba",
        value: "RW-I"
      },
      {
        text: "Cyangugu",
        value: "RW-E"
      },
      {
        text: "Gikongoro",
        value: "RW-D"
      },
      {
        text: "Gisenyi",
        value: "RW-G"
      },
      {
        text: "Gitarama",
        value: "RW-B"
      },
      {
        text: "Kibungo",
        value: "RW-J"
      },
      {
        text: "Kibuye",
        value: "RW-F"
      },
      {
        text: "Kigali-Rural (Kigali y’ Icyaro)",
        value: "RW-K"
      },
      {
        text: "Kigali-Ville (Kilgali Ngari)",
        value: "RW-L"
      },
      {
        text: "Mutara",
        value: "RW-M"
      },
      {
        text: "Ruhengeri",
        value: "RW-H"
      }
    ]
  },
  {
    text: "St. Helena",
    value: "SH",
    divisions: [
      {
        text: "Saint Helena",
        value: "SH-SH"
      },
      {
        text: "Ascension",
        value: "SH-AC"
      },
      {
        text: "Tristan da Cunha",
        value: "SH-TA"
      }
    ]
  },
  {
    text: "Saint Kitts & Nevis",
    value: "KN",
    divisions: [
      {
        text: "Saint Kitts & Nevis",
        value: "KN-KN"
      }
    ]
  },
  {
    text: "Saint Lucia",
    value: "LC",
    divisions: [
      {
        text: "Saint Lucia",
        value: "LC-LC"
      }
    ]
  },
  {
    text: "St. Pierre & Miquelon",
    value: "PM",
    divisions: [
      {
        text: "St. Pierre & Miquelon",
        value: "PM-PM"
      }
    ]
  },
  {
    text: "St. Vincent & the Grenadines",
    value: "VC",
    divisions: [
      {
        text: "St. Vincent & the Grenadines",
        value: "VC-VC"
      }
    ]
  },
  {
    text: "Samoa",
    value: "WS",
    divisions: [
      {
        text: "A'ana",
        value: "WS-AA"
      },
      {
        text: "Aiga-i-le-Tai",
        value: "WS-AL"
      },
      {
        text: "Atua",
        value: "WS-AT"
      },
      {
        text: "Fa'asaleleaga",
        value: "WS-FA"
      },
      {
        text: "Gaga'emauga",
        value: "WS-GE"
      },
      {
        text: "Gagaifomauga",
        value: "WS-GI"
      },
      {
        text: "Palauli",
        value: "WS-PA"
      },
      {
        text: "Satupa'itea",
        value: "WS-SA"
      },
      {
        text: "Tuamasaga",
        value: "WS-TU"
      },
      {
        text: "Va'a-o-Fonoti",
        value: "WS-VF"
      },
      {
        text: "Vaisigano",
        value: "WS-VS"
      }
    ]
  },
  {
    text: "San Marino",
    value: "SM",
    divisions: [
      {
        text: "San Marino",
        value: "SM-SM"
      }
    ]
  },
  {
    text: "Sao Tome & Principe",
    value: "ST",
    divisions: [
      {
        text: "Príncipe",
        value: "ST-P"
      },
      {
        text: "Sāo Tomé",
        value: "ST-S"
      }
    ]
  },
  {
    text: "Saudi Arabia",
    value: "SA",
    divisions: [
      {
        text: "Al Bāḩah",
        value: "SA-11"
      },
      {
        text: "Al Ḩudūd ash Shamālīyah",
        value: "SA-08"
      },
      {
        text: "Al Jawf",
        value: "SA-12"
      },
      {
        text: "Al Madīnah",
        value: "SA-03"
      },
      {
        text: "Al Qaşim",
        value: "SA-05"
      },
      {
        text: "Ar Riyāḑ",
        value: "SA-O1"
      },
      {
        text: "Ash Sharqīyah",
        value: "SA-04"
      },
      {
        text: "‘Asīr",
        value: "SA-14"
      },
      {
        text: "Ḩā'il",
        value: "SA-06"
      },
      {
        text: "Jīzān",
        value: "SA-09"
      },
      {
        text: "Makkah",
        value: "SA-02"
      },
      {
        text: "Najrān",
        value: "SA-10"
      },
      {
        text: "Tabūk",
        value: "SA-07"
      }
    ]
  },
  {
    text: "Senegal",
    value: "SN",
    divisions: [
      {
        text: "Dakar",
        value: "SN-DK"
      },
      {
        text: "Diourbel",
        value: "SN-DB"
      },
      {
        text: "Fatick",
        value: "SN-FK"
      },
      {
        text: "Kaolack",
        value: "SN-KL"
      },
      {
        text: "Kolda",
        value: "SN-KD"
      },
      {
        text: "Louga",
        value: "SN-LG"
      },
      {
        text: "Saint-Louis",
        value: "SN-SL"
      },
      {
        text: "Tambacounda",
        value: "SN-TC"
      },
      {
        text: "Thiès",
        value: "SN-TH"
      },
      {
        text: "Ziguinchor",
        value: "SN-ZG"
      }
    ]
  },
  {
    text: "Seychelles",
    value: "SC",
    divisions: [
      {
        text: "Seychelles",
        value: "SC-SC"
      }
    ]
  },
  {
    text: "Sierra Leone",
    value: "SL",
    divisions: [
      {
        text: "Western Area (Freetown)",
        value: "SL-W"
      },
      {
        text: "Eastern",
        value: "SL-E"
      },
      {
        text: "Northern",
        value: "SL-N"
      },
      {
        text: "Southern",
        value: "SL-S"
      }
    ]
  },
  {
    text: "Singapore",
    value: "SG",
    divisions: [
      {
        text: "Singapore",
        value: "SG-SG"
      }
    ]
  },
  {
    text: "Slovak Republic",
    value: "SK",
    divisions: [
      {
        text: "Banskobystrický kraj",
        value: "SK-BC"
      },
      {
        text: "Bratislavský kraj",
        value: "SK-BL"
      },
      {
        text: "Košický kraj",
        value: "SK-KI"
      },
      {
        text: "Nitriansky kraj",
        value: "SK-NI"
      },
      {
        text: "Prešovský kraj",
        value: "SK-PV"
      },
      {
        text: "Trenčiansky kraj",
        value: "SK-TC"
      },
      {
        text: "Trnavský kraj",
        value: "SK-TA"
      },
      {
        text: "Žilinský kraj",
        value: "SK-ZI"
      }
    ]
  },
  {
    text: "Slovenia",
    value: "SI",
    divisions: [
      {
        text: "Dolenjska",
        value: "SI-07"
      },
      {
        text: "Gorenjska",
        value: "SI-09"
      },
      {
        text: "Goriška",
        value: "SI-11"
      },
      {
        text: "Koroška",
        value: "SI-03"
      },
      {
        text: "Notranjsko-kraška",
        value: "SI-10"
      },
      {
        text: "Obalno-kraška",
        value: "SI-12"
      },
      {
        text: "Osrednjeslovenska",
        value: "SI-08"
      },
      {
        text: "Podravska",
        value: "SI-02"
      },
      {
        text: "Pomurska",
        value: "SI-01"
      },
      {
        text: "Savinjska",
        value: "SI-04"
      },
      {
        text: "Spodnjeposavska",
        value: "SI-06"
      },
      {
        text: "Zasavska",
        value: "SI-05"
      }
    ]
  },
  {
    text: "Solomon Islands",
    value: "SB",
    divisions: [
      {
        text: "Capital Territory (Honiara)",
        value: "SB-CT"
      },
      {
        text: "Central",
        value: "SB-CE"
      },
      {
        text: "Guadalcanal",
        value: "SB-GU"
      },
      {
        text: "Isabel",
        value: "SB-IS"
      },
      {
        text: "Makira",
        value: "SB-MK"
      },
      {
        text: "Malaita",
        value: "SB-ML"
      },
      {
        text: "Temotu",
        value: "SB-TE"
      },
      {
        text: "Western",
        value: "SB-WE"
      }
    ]
  },
  {
    text: "Somalia",
    value: "SO",
    divisions: [
      {
        text: "Awdal",
        value: "SO-AW"
      },
      {
        text: "Bakool",
        value: "SO-BK"
      },
      {
        text: "Banaadir",
        value: "SO-BN"
      },
      {
        text: "Bari",
        value: "SO-BR"
      },
      {
        text: "BaY",
        value: "SO-BY"
      },
      {
        text: "Galguduud",
        value: "SO-GA"
      },
      {
        text: "Gedo",
        value: "SO-GE"
      },
      {
        text: "Hiiraan",
        value: "SO-HI"
      },
      {
        text: "Jubbada Dhexe",
        value: "SO-JD"
      },
      {
        text: "Jubbada Hoose",
        value: "SO-JH"
      },
      {
        text: "Mudug",
        value: "SO-MU"
      },
      {
        text: "Nugaal",
        value: "SO-NU"
      },
      {
        text: "Sanaag",
        value: "SO-SA"
      },
      {
        text: "Shabeellaha Dhexe",
        value: "SO-SD"
      },
      {
        text: "Shabeellaha Hoose",
        value: "SO-SH"
      },
      {
        text: "Sool",
        value: "SO-SO"
      },
      {
        text: "Togdheer",
        value: "SO-TO"
      },
      {
        text: "Woqooyi Galbeed",
        value: "SO-WO"
      }
    ]
  },
  {
    text: "South Africa",
    value: "ZA",
    divisions: [
      {
        text: "Eastern Cape (Oos-Kaap)",
        value: "ZA-EC"
      },
      {
        text: "Free State (Vrystaat)",
        value: "ZA-FS"
      },
      {
        text: "Gauteng",
        value: "ZA-GT"
      },
      {
        text: "Kwazulu-Natal",
        value: "ZA-NL"
      },
      {
        text: "Mpumalanga",
        value: "ZA-MP"
      },
      {
        text: "Northern Cape (Noord-Kaap)",
        value: "ZA-NC"
      },
      {
        text: "Northern Province (Noordelike Provinsie)",
        value: "ZA-NP"
      },
      {
        text: "North-West (Noord-Wes)",
        value: "ZA-NW"
      },
      {
        text: "Western Cape (Wes-Kaap)",
        value: "ZA-WC"
      }
    ]
  },
  {
    text: "S.Georgia & S.Sandwich Islands",
    value: "GS",
    divisions: [
      {
        text: "S.Georgia & S.Sandwich Islands",
        value: "GS-GS"
      }
    ]
  },
  {
    text: "Spain",
    value: "ES",
    divisions: [
      {
        text: "Andalucía",
        value: "ES-AN"
      },
      {
        text: "Almería",
        value: "ES-AL"
      },
      {
        text: "Cádiz",
        value: "ES-CA"
      },
      {
        text: "Córdoba",
        value: "ES-CO"
      },
      {
        text: "Granada",
        value: "ES-GR"
      },
      {
        text: "Huelva",
        value: "ES-H"
      },
      {
        text: "Jaén",
        value: "ES-J"
      },
      {
        text: "Málaga",
        value: "ES-MA"
      },
      {
        text: "Sevilla",
        value: "ES-SE"
      },
      {
        text: "Aragón",
        value: "ES-AR"
      },
      {
        text: "Huesca",
        value: "ES-HU"
      },
      {
        text: "Teruel",
        value: "ES-TE"
      },
      {
        text: "Zaragoza",
        value: "ES-Z"
      },
      {
        text: "Asturias, Principado de",
        value: "ES-O"
      },
      {
        text: "Asturias",
        value: "ES-O"
      },
      {
        text: "Canarias",
        value: "ES-CN"
      },
      {
        text: "Las Palmas",
        value: "ES-GC"
      },
      {
        text: "Santa Cruz De Tenerife",
        value: "ES-TF"
      },
      {
        text: "Cantabria",
        value: "ES-S"
      },
      {
        text: "Cantabria",
        value: "ES-S"
      },
      {
        text: "Castilla-La Mancha",
        value: "ES-CM"
      },
      {
        text: "Albacete",
        value: "ES-AB"
      },
      {
        text: "Ciudad Real",
        value: "ES-CR"
      },
      {
        text: "Cuenca",
        value: "ES-CU"
      },
      {
        text: "Guadalajara",
        value: "ES-GU"
      },
      {
        text: "Toledo",
        value: "ES-TO"
      },
      {
        text: "Castilla y León",
        value: "ES-CL"
      },
      {
        text: "Ávila",
        value: "ES-AV"
      },
      {
        text: "Burgos",
        value: "ES-BU"
      },
      {
        text: "León",
        value: "ES-LE"
      },
      {
        text: "Palencia",
        value: "ES-P"
      },
      {
        text: "Salamanca",
        value: "ES-SA"
      },
      {
        text: "Segovia",
        value: "ES-SG"
      },
      {
        text: "Soria",
        value: "ES-SO"
      },
      {
        text: "Valladolid",
        value: "ES-VA"
      },
      {
        text: "Zamora",
        value: "ES-ZA"
      },
      {
        text: "Cataluña",
        value: "ES-CT"
      },
      {
        text: "Barcelona",
        value: "ES-B"
      },
      {
        text: "Gerona",
        value: "ES-GE"
      },
      {
        text: "Lérida",
        value: "ES-L"
      },
      {
        text: "Tarragona",
        value: "ES-T"
      },
      {
        text: "Extremadura",
        value: "ES-EX"
      },
      {
        text: "Badajoz",
        value: "ES-BA"
      },
      {
        text: "Cáceres",
        value: "ES-CC"
      },
      {
        text: "Galicia",
        value: "ES-GA"
      },
      {
        text: "La Coruña",
        value: "ES-C"
      },
      {
        text: "Lugo",
        value: "ES-LU"
      },
      {
        text: "Orense",
        value: "ES-OR"
      },
      {
        text: "Pontevedra",
        value: "ES-PO"
      },
      {
        text: "Islas Baleares",
        value: "ES-PM"
      },
      {
        text: "Baleares",
        value: "ES-PM"
      },
      {
        text: "La Rioja",
        value: "ES-LO"
      },
      {
        text: "La Rioja",
        value: "ES-LO"
      },
      {
        text: "Madrid, Comunidad de",
        value: "ES-M"
      },
      {
        text: "Madrid",
        value: "ES-M"
      },
      {
        text: "Murcia, Región de",
        value: "ES-MU"
      },
      {
        text: "Murcia",
        value: "ES-MU"
      },
      {
        text: "Navarra, Comunidad Foral de",
        value: "ES-NA"
      },
      {
        text: "Navarra",
        value: "ES-NA"
      },
      {
        text: "País Vasco",
        value: "ES-PV"
      },
      {
        text: "Álava",
        value: "ES-VI"
      },
      {
        text: "Guipúzcoa",
        value: "ES-SS"
      },
      {
        text: "Vizcaya",
        value: "ES-BI"
      },
      {
        text: "Valenciana, Comunidad",
        value: "ES-VC"
      },
      {
        text: "Alicante",
        value: "ES-A"
      },
      {
        text: "Castellón",
        value: "ES-CS"
      },
      {
        text: "Valencia",
        value: "ES-V"
      }
    ]
  },
  {
    text: "Sri Lanka",
    value: "LK",
    divisions: [
      {
        text: "Basnahira Palata (Western Province)",
        value: "LK-1"
      },
      {
        text: "Colombo",
        value: "LK-11"
      },
      {
        text: "Gampaha",
        value: "LK-12"
      },
      {
        text: "Kalutara",
        value: "LK-13"
      },
      {
        text: "Dakunu Palata (Southern Province)",
        value: "LK-3"
      },
      {
        text: "Galle",
        value: "LK-31"
      },
      {
        text: "Hambantota",
        value: "LK-33"
      },
      {
        text: "Matara",
        value: "LK-32"
      },
      {
        text: "Madhyama Palata (Central Province)",
        value: "LK-2"
      },
      {
        text: "Kandy",
        value: "LK-21"
      },
      {
        text: "Matale",
        value: "LK-22"
      },
      {
        text: "Nuwara Eliya",
        value: "LK-23"
      },
      {
        text: "Negenahira Palata (Eastern Province)",
        value: "LK-5"
      },
      {
        text: "Arnpara",
        value: "LK-52"
      },
      {
        text: "Batticaloa",
        value: "LK-51"
      },
      {
        text: "Trincomalee",
        value: "LK-53"
      },
      {
        text: "Sabaragamuwa Palata",
        value: "LK-9"
      },
      {
        text: "Kegalla",
        value: "LK-92"
      },
      {
        text: "Ratnapura",
        value: "LK-91"
      },
      {
        text: "Uturumeda Palata (North Central Province)",
        value: "LK-7"
      },
      {
        text: "Anuradhapura",
        value: "LK-71"
      },
      {
        text: "Polonnaruwa",
        value: "LK-72"
      },
      {
        text: "Uturu Palata (Northern Province)",
        value: "LK-4"
      },
      {
        text: "Jaffna",
        value: "LK-41"
      },
      {
        text: "Kilinochchi",
        value: "LK-42"
      },
      {
        text: "Mannar",
        value: "LK-43"
      },
      {
        text: "Mullaittivu",
        value: "LK-45"
      },
      {
        text: "Vavuniya",
        value: "LK-44"
      },
      {
        text: "Uva Palata",
        value: "LK-8"
      },
      {
        text: "Badulla",
        value: "LK-81"
      },
      {
        text: "Monaragala",
        value: "LK-82"
      },
      {
        text: "Wayamba Palata (North Western Province)",
        value: "LK-6"
      },
      {
        text: "Kurunegala",
        value: "LK-61"
      },
      {
        text: "Puttalam",
        value: "LK-62"
      }
    ]
  },
  {
    text: "Sudan",
    value: "SD",
    divisions: [
      {
        text: "A‘ālī an Nīl",
        value: "SD-23"
      },
      {
        text: "Al Baḩr al Aḩmar",
        value: "SD-26"
      },
      {
        text: "Al Buḩayrāt",
        value: "SD-18"
      },
      {
        text: "Al Jazīrah",
        value: "SD-07"
      },
      {
        text: "Al Kharţūm",
        value: "SD-03"
      },
      {
        text: "Al Qaḑārif",
        value: "SD-06"
      },
      {
        text: "Al Waḩdah",
        value: "SD-22"
      },
      {
        text: "An Nīl",
        value: "SD-04"
      },
      {
        text: "An Nīl al Abyaḑ",
        value: "SD-08"
      },
      {
        text: "An Nīl al Azraq",
        value: "SD-24"
      },
      {
        text: "Ash Shamālīyah",
        value: "SD-01"
      },
      {
        text: "Baḩr al Jabal",
        value: "SD-17"
      },
      {
        text: "Gharb al Istiwā'īyah",
        value: "SD-16"
      },
      {
        text: "Gharb Baḩr al Ghazāl",
        value: "SD-14"
      },
      {
        text: "Gharb Dārfūr",
        value: "SD-12"
      },
      {
        text: "Gharb Kurdufān",
        value: "SD-10"
      },
      {
        text: "Janūb Dārfūr",
        value: "SD-11"
      },
      {
        text: "Janūb Kurdufān",
        value: "SD-13"
      },
      {
        text: "Jūnqalī",
        value: "SD-20"
      },
      {
        text: "Kassalā",
        value: "SD-05"
      },
      {
        text: "Shamāl Baḩr al Ghazāl",
        value: "SD-15"
      },
      {
        text: "Shamāl Dārfūr",
        value: "SD-02"
      },
      {
        text: "Shamāl Kurdufān",
        value: "SD-09"
      },
      {
        text: "Sharq al Istiwā'iyah",
        value: "SD-19"
      },
      {
        text: "Sinnār",
        value: "SD-25"
      },
      {
        text: "Wārāb",
        value: "SD-21"
      }
    ]
  },
  {
    text: "Suriname",
    value: "SR",
    divisions: [
      {
        text: "Brokopondo",
        value: "SR-BR"
      },
      {
        text: "Commewijne",
        value: "SR-CM"
      },
      {
        text: "Coronie",
        value: "SR-CR"
      },
      {
        text: "Marowijne",
        value: "SR-MA"
      },
      {
        text: "Nickerie",
        value: "SR-NI"
      },
      {
        text: "Para",
        value: "SR-PR"
      },
      {
        text: "Paramaribo",
        value: "SR-PM"
      },
      {
        text: "Saramacca",
        value: "SR-SA"
      },
      {
        text: "Sipaliwini",
        value: "SR-SI"
      },
      {
        text: "Wanica",
        value: "SR-WA"
      }
    ]
  },
  {
    text: "Svalbard & Jan Mayen Islands",
    value: "SJ",
    divisions: [
      {
        text: "Svalbard & Jan Mayen Islands",
        value: "SJ-SJ"
      }
    ]
  },
  {
    text: "Swaziland",
    value: "SZ",
    divisions: [
      {
        text: "Hhohho",
        value: "SZ-HH"
      },
      {
        text: "Lubombo",
        value: "SZ-LU"
      },
      {
        text: "Manzini",
        value: "SZ-MA"
      },
      {
        text: "Shiselweni",
        value: "SZ-SH"
      }
    ]
  },
  {
    text: "Sweden",
    value: "SE",
    divisions: [
      {
        text: "Blekinge län",
        value: "SE-K"
      },
      {
        text: "Dalarnas län",
        value: "SE-W"
      },
      {
        text: "Gotlands län",
        value: "SE-I"
      },
      {
        text: "Gävleborgs län",
        value: "SE-X"
      },
      {
        text: "Hallands län",
        value: "SE-N"
      },
      {
        text: "Jämtlands län",
        value: "SE-Z"
      },
      {
        text: "Jönköpings län",
        value: "SE-F"
      },
      {
        text: "Kalmar län",
        value: "SE-H"
      },
      {
        text: "Kronobergs län",
        value: "SE-G"
      },
      {
        text: "Norrbottens län",
        value: "SE-BD"
      },
      {
        text: "Skåne län",
        value: "SE-M"
      },
      {
        text: "Stockholms län",
        value: "SE-AB"
      },
      {
        text: "Södermanlands län",
        value: "SE-D"
      },
      {
        text: "Uppsala län",
        value: "SE-C"
      },
      {
        text: "Värmlands län",
        value: "SE-S"
      },
      {
        text: "Västerbottens län",
        value: "SE-AC"
      },
      {
        text: "Västernorrlands län",
        value: "SE-Y"
      },
      {
        text: "Västmanlands län",
        value: "SE-U"
      },
      {
        text: "Västra Götalands län",
        value: "SE-O"
      },
      {
        text: "Örebro län",
        value: "SE-T"
      },
      {
        text: "Östergötlands län",
        value: "SE-E"
      }
    ]
  },
  {
    text: "Switzerland",
    value: "CH",
    divisions: [
      {
        text: "Aargau",
        value: "CH-AG"
      },
      {
        text: "Appenzell Ausser-Rhoden",
        value: "CH-AR"
      },
      {
        text: "Appenzell Inner-Rhoden",
        value: "CH-AI"
      },
      {
        text: "Basel-Landschaft",
        value: "CH-BL"
      },
      {
        text: "Basel-Stadt",
        value: "CH-BS"
      },
      {
        text: "Bern",
        value: "CH-BE"
      },
      {
        text: "Freiburg",
        value: "CH-FR"
      },
      {
        text: "Geneve",
        value: "CH-GE"
      },
      {
        text: "Glarus",
        value: "CH-GL"
      },
      {
        text: "Graubünden",
        value: "CH-GR"
      },
      {
        text: "Jura",
        value: "CH-JU"
      },
      {
        text: "Luzern",
        value: "CH-LU"
      },
      {
        text: "Neuchatel",
        value: "CH-NE"
      },
      {
        text: "Nidwalden",
        value: "CH-NW"
      },
      {
        text: "Obwalden",
        value: "CH-OW"
      },
      {
        text: "Sankt Gallen",
        value: "CH-SG"
      },
      {
        text: "Schaffhausen",
        value: "CH-SH"
      },
      {
        text: "Schwyz",
        value: "CH-SZ"
      },
      {
        text: "Solothurn",
        value: "CH-SO"
      },
      {
        text: "Thurgau",
        value: "CH-TG"
      },
      {
        text: "Ticino",
        value: "CH-TI"
      },
      {
        text: "Uri",
        value: "CH-UR"
      },
      {
        text: "Wallis",
        value: "CH-VS"
      },
      {
        text: "Vaud",
        value: "CH-VD"
      },
      {
        text: "Zug",
        value: "CH-ZG"
      },
      {
        text: "Zürich",
        value: "CH-ZH"
      }
    ]
  },
  {
    text: "Syria",
    value: "SY",
    divisions: [
      {
        text: "Al Ḩasakah",
        value: "SY-HA"
      },
      {
        text: "Al Lādhiqīyah",
        value: "SY-LA"
      },
      {
        text: "Al Qunayţirah",
        value: "SY-QU"
      },
      {
        text: "Ar Raqqah",
        value: "SY-RA"
      },
      {
        text: "As Suwaydā'",
        value: "SY-SU"
      },
      {
        text: "Dar’ā",
        value: "SY-DR"
      },
      {
        text: "Dayr az Zawr",
        value: "SY-DY"
      },
      {
        text: "Dimashq",
        value: "SY-DI"
      },
      {
        text: "Ḩalab",
        value: "SY-HL"
      },
      {
        text: "Ḩamāh",
        value: "SY-HM"
      },
      {
        text: "Ḩimş",
        value: "SY-HI"
      },
      {
        text: "Idlib",
        value: "SY-ID"
      },
      {
        text: "Rīf Dimashq",
        value: "SY-RD"
      },
      {
        text: "Ţarţūs",
        value: "SY-TA"
      }
    ]
  },
  {
    text: "Taiwan",
    value: "TW",
    divisions: [
      {
        text: "Kaohsiung",
        value: "TW-KHH"
      },
      {
        text: "Taipei",
        value: "TW-TPE"
      },
      {
        text: "Chiayi",
        value: "TW-CYI"
      },
      {
        text: "Hsinchu",
        value: "TW-HSZ"
      },
      {
        text: "Keelung",
        value: "TW-KEE"
      },
      {
        text: "Taichung",
        value: "TW-TXG"
      },
      {
        text: "Tainan",
        value: "TW-TNN"
      },
      {
        text: "Changhua",
        value: "TW-CHA"
      },
      {
        text: "Hualien",
        value: "TW-HUA"
      },
      {
        text: "Ilan",
        value: "TW-ILA"
      },
      {
        text: "Miaoli",
        value: "TW-MIA"
      },
      {
        text: "Nantou",
        value: "TW-NAN"
      },
      {
        text: "Penghu",
        value: "TW-PEN"
      },
      {
        text: "Pingtung",
        value: "TW-PIF"
      },
      {
        text: "Taitung",
        value: "TW-TTT"
      },
      {
        text: "Taoyuan",
        value: "TW-TAO"
      },
      {
        text: "Yunlin",
        value: "TW-YUN"
      }
    ]
  },
  {
    text: "Tajikistan",
    value: "TJ",
    divisions: [
      {
        text: "Karategin",
        value: "TJ-KR"
      },
      {
        text: "Khatlon",
        value: "TJ-KT"
      },
      {
        text: "Leninabad",
        value: "TJ-LN"
      },
      {
        text: "Gorno-Badakhshan",
        value: "TJ-GB"
      }
    ]
  },
  {
    text: "Tanzania",
    value: "TZ",
    divisions: [
      {
        text: "Arusha",
        value: "TZ-01"
      },
      {
        text: "Dar-es-Salaam",
        value: "TZ-02"
      },
      {
        text: "Dodoma",
        value: "TZ-03"
      },
      {
        text: "Iringa",
        value: "TZ-04"
      },
      {
        text: "Kagera",
        value: "TZ-05"
      },
      {
        text: "Kaskazini Pemba (Pemba North)",
        value: "TZ-06"
      },
      {
        text: "Kaskazini Unguja (Zanzibar North)",
        value: "TZ-07"
      },
      {
        text: "Kigoma",
        value: "TZ-08"
      },
      {
        text: "Kilimanjaro",
        value: "TZ-09"
      },
      {
        text: "Kusini Pemba (Pemba South)",
        value: "TZ-10"
      },
      {
        text: "Kusini Unguja (Zanzibar South)",
        value: "TZ-11"
      },
      {
        text: "Lindi",
        value: "TZ-12"
      },
      {
        text: "Mara",
        value: "TZ-13"
      },
      {
        text: "Mbeya",
        value: "TZ-14"
      },
      {
        text: "Mjini Magharibi (Zanzibar West)",
        value: "TZ-15"
      },
      {
        text: "Morogoro",
        value: "TZ-16"
      },
      {
        text: "Mtwara",
        value: "TZ-17"
      },
      {
        text: "Mwanza",
        value: "TZ-18"
      },
      {
        text: "Pwani (Coast)",
        value: "TZ-19"
      },
      {
        text: "Rukwa",
        value: "TZ-20"
      },
      {
        text: "Ruvuma",
        value: "TZ-21"
      },
      {
        text: "Shinyanga",
        value: "TZ-22"
      },
      {
        text: "Singida",
        value: "TZ-23"
      },
      {
        text: "Tabora",
        value: "TZ-24"
      },
      {
        text: "Tanga",
        value: "TZ-25"
      }
    ]
  },
  {
    text: "Thailand",
    value: "TH",
    divisions: [
      {
        text: "Krung Thep Maha Nakhon [Bangkok]",
        value: "TH-10"
      },
      {
        text: "Phatthaya",
        value: "TH-S"
      },
      {
        text: "Amnat Charoen",
        value: "TH-37"
      },
      {
        text: "Ang Thong",
        value: "TH-15"
      },
      {
        text: "Buri Ram",
        value: "TH-31"
      },
      {
        text: "Chachoengsao",
        value: "TH-24"
      },
      {
        text: "Chai Nat",
        value: "TH-18"
      },
      {
        text: "Chaiyaphum",
        value: "TH-36"
      },
      {
        text: "Chanthaburi",
        value: "TH-22"
      },
      {
        text: "Chiang Mai",
        value: "TH-50"
      },
      {
        text: "Chiang Rai",
        value: "TH-57"
      },
      {
        text: "Chon Buri",
        value: "TH-20"
      },
      {
        text: "Chumphon",
        value: "TH-86"
      },
      {
        text: "Kalasin",
        value: "TH-46"
      },
      {
        text: "Kamphaeng Phet",
        value: "TH-62"
      },
      {
        text: "Kanchanaburi",
        value: "TH-71"
      },
      {
        text: "Khon Kaen",
        value: "TH-40"
      },
      {
        text: "Krabi",
        value: "TH-81"
      },
      {
        text: "Lampang",
        value: "TH-52"
      },
      {
        text: "Lamphun",
        value: "TH-51"
      },
      {
        text: "Loei",
        value: "TH-42"
      },
      {
        text: "Lop Buri",
        value: "TH-16"
      },
      {
        text: "Mae Hong Son",
        value: "TH-58"
      },
      {
        text: "Maha Sarakham",
        value: "TH-44"
      },
      {
        text: "Mukdahan",
        value: "TH-49"
      },
      {
        text: "Nakhon Nayok",
        value: "TH-26"
      },
      {
        text: "Nakhon Pathom",
        value: "TH-73"
      },
      {
        text: "Nakhon Phanom",
        value: "TH-48"
      },
      {
        text: "Nakhon Ratchasima",
        value: "TH-30"
      },
      {
        text: "Nakhon Sawan",
        value: "TH-60"
      },
      {
        text: "Nakhon Si Thammarat",
        value: "TH-80"
      },
      {
        text: "Nan",
        value: "TH-55"
      },
      {
        text: "Narathiwat",
        value: "TH-96"
      },
      {
        text: "Nong Bua Lam Phu",
        value: "TH-39"
      },
      {
        text: "Nong Khai",
        value: "TH-43"
      },
      {
        text: "Nonthaburi",
        value: "TH-12"
      },
      {
        text: "Pathum Thani",
        value: "TH-13"
      },
      {
        text: "Pattani",
        value: "TH-94"
      },
      {
        text: "Phangnga",
        value: "TH-82"
      },
      {
        text: "Phatthalung",
        value: "TH-93"
      },
      {
        text: "Phayao",
        value: "TH-56"
      },
      {
        text: "Phetchabun",
        value: "TH-67"
      },
      {
        text: "Phetchaburi",
        value: "TH-76"
      },
      {
        text: "Phichit",
        value: "TH-66"
      },
      {
        text: "Phitsanulok",
        value: "TH-65"
      },
      {
        text: "Phrae",
        value: "TH-54"
      },
      {
        text: "Phra Nakhon Si Ayutthaya",
        value: "TH-14"
      },
      {
        text: "Phuket",
        value: "TH-83"
      },
      {
        text: "Prachin Buri",
        value: "TH-25"
      },
      {
        text: "Prachuap Khiri Khan",
        value: "TH-77"
      },
      {
        text: "Ranong",
        value: "TH-85"
      },
      {
        text: "Ratchaburi",
        value: "TH-70"
      },
      {
        text: "Rayong",
        value: "TH-21"
      },
      {
        text: "Roi Et",
        value: "TH-45"
      },
      {
        text: "Sa Kaeo",
        value: "TH-27"
      },
      {
        text: "Sakon Nakhon",
        value: "TH-47"
      },
      {
        text: "Samut Prakan",
        value: "TH-11"
      },
      {
        text: "Samut Sakhon",
        value: "TH-74"
      },
      {
        text: "Samut Songkhram",
        value: "TH-75"
      },
      {
        text: "Saraburi",
        value: "TH-19"
      },
      {
        text: "Satun",
        value: "TH-91"
      },
      {
        text: "Sing Buri",
        value: "TH-17"
      },
      {
        text: "Si Sa Ket",
        value: "TH-33"
      },
      {
        text: "Songkhla",
        value: "TH-90"
      },
      {
        text: "Sukhothai",
        value: "TH-64"
      },
      {
        text: "Suphan Buri",
        value: "TH-72"
      },
      {
        text: "Surat Thani",
        value: "TH-84"
      },
      {
        text: "Surin",
        value: "TH-32"
      },
      {
        text: "Tak",
        value: "TH-63"
      },
      {
        text: "Trang",
        value: "TH-92"
      },
      {
        text: "Trat",
        value: "TH-23"
      },
      {
        text: "Ubon Ratchathani",
        value: "TH-34"
      },
      {
        text: "Udon Thani",
        value: "TH-41"
      },
      {
        text: "Uthai Thani",
        value: "TH-61"
      },
      {
        text: "Uttaradit",
        value: "TH-53"
      },
      {
        text: "Yala",
        value: "TH-95"
      },
      {
        text: "Yasothon",
        value: "TH-35"
      }
    ]
  },
  {
    text: "Togo",
    value: "TG",
    divisions: [
      {
        text: "Centre",
        value: "TG-C"
      },
      {
        text: "Kara",
        value: "TG-K"
      },
      {
        text: "Maritime (Région)",
        value: "TG-M"
      },
      {
        text: "Plateaux",
        value: "TG-P"
      },
      {
        text: "Savannes",
        value: "TG-S"
      }
    ]
  },
  {
    text: "Tokelau",
    value: "TK",
    divisions: [
      {
        text: "Tokelau",
        value: "TK-TK"
      }
    ]
  },
  {
    text: "Tonga",
    value: "TO",
    divisions: [
      {
        text: "Tonga",
        value: "TO-TO"
      }
    ]
  },
  {
    text: "Trinidad & Tobago",
    value: "TT",
    divisions: [
      {
        text: "Couva-Tabaquite-Talparo",
        value: "TT-CTT"
      },
      {
        text: "Diego Martin",
        value: "TT-DMN"
      },
      {
        text: "Eastern Tobago",
        value: "TT-ETO"
      },
      {
        text: "Penal-Debe",
        value: "TT-PED"
      },
      {
        text: "Princes Town",
        value: "TT-PRT"
      },
      {
        text: "Rio Claro-Mayaro",
        value: "TT-RCM"
      },
      {
        text: "Sangre Grande",
        value: "TT-SGE"
      },
      {
        text: "San Juan-Laventille",
        value: "TT-SJL"
      },
      {
        text: "Siparia",
        value: "TT-SIP"
      },
      {
        text: "Tunapuna-Piarco",
        value: "TT-TUP"
      },
      {
        text: "Western Tobago",
        value: "TT-WTO"
      },
      {
        text: "Arima",
        value: "TT-ARI"
      },
      {
        text: "Chaguanas",
        value: "TT-CHA"
      },
      {
        text: "Point Fortin",
        value: "TT-PTF"
      },
      {
        text: "Port of Spain",
        value: "TT-POS"
      },
      {
        text: "San Fernando",
        value: "TT-SFO"
      }
    ]
  },
  {
    text: "Tunisia",
    value: "TN",
    divisions: [
      {
        text: "Béja",
        value: "TN-31"
      },
      {
        text: "Ben Arous",
        value: "TN-13"
      },
      {
        text: "Bizerte",
        value: "TN-23"
      },
      {
        text: "Gabès",
        value: "TN-81"
      },
      {
        text: "Gafsa",
        value: "TN-71"
      },
      {
        text: "Jendouba",
        value: "TN-32"
      },
      {
        text: "Kairouan",
        value: "TN-41"
      },
      {
        text: "Kasserine",
        value: "TN-42"
      },
      {
        text: "Kebili",
        value: "TN-73"
      },
      {
        text: "L'Ariana",
        value: "TN-12"
      },
      {
        text: "Le Kef",
        value: "TN-33"
      },
      {
        text: "Mahdia",
        value: "TN-53"
      },
      {
        text: "Medenine",
        value: "TN-82"
      },
      {
        text: "Monastir",
        value: "TN-52"
      },
      {
        text: "Nabeul",
        value: "TN-21"
      },
      {
        text: "Sfax",
        value: "TN-61"
      },
      {
        text: "Sidi Bouzid",
        value: "TN-43"
      },
      {
        text: "Siliana",
        value: "TN-34"
      },
      {
        text: "Sousse",
        value: "TN-51"
      },
      {
        text: "Tataouine",
        value: "TN-83"
      },
      {
        text: "Tozeur",
        value: "TN-72"
      },
      {
        text: "Tunis",
        value: "TN-11"
      },
      {
        text: "Zaghouan",
        value: "TN-22"
      }
    ]
  },
  {
    text: "Turkey",
    value: "TR",
    divisions: [
      {
        text: "Adana",
        value: "TR-01"
      },
      {
        text: "Adiyaman",
        value: "TR-02"
      },
      {
        text: "Afyon",
        value: "TR-03"
      },
      {
        text: "Ağrı",
        value: "TR-04"
      },
      {
        text: "Aksaray",
        value: "TR-68"
      },
      {
        text: "Amasya",
        value: "TR-05"
      },
      {
        text: "Ankara",
        value: "TR-06"
      },
      {
        text: "Antalya",
        value: "TR-07"
      },
      {
        text: "Ardahan",
        value: "TR-75"
      },
      {
        text: "Artvin",
        value: "TR-08"
      },
      {
        text: "Aydin",
        value: "TR-09"
      },
      {
        text: "Balıkesir",
        value: "TR-10"
      },
      {
        text: "Bartın",
        value: "TR-74"
      },
      {
        text: "Batman",
        value: "TR-72"
      },
      {
        text: "Bayburt",
        value: "TR-69"
      },
      {
        text: "Bilecik",
        value: "TR-11"
      },
      {
        text: "Bingöl",
        value: "TR-12"
      },
      {
        text: "Bitlis",
        value: "TR-13"
      },
      {
        text: "Bolu",
        value: "TR-14"
      },
      {
        text: "Burdur",
        value: "TR-15"
      },
      {
        text: "Bursa",
        value: "TR-16"
      },
      {
        text: "Çanakkale",
        value: "TR-17"
      },
      {
        text: "Çankırı",
        value: "TR-18"
      },
      {
        text: "Çorum",
        value: "TR-19"
      },
      {
        text: "Denizli",
        value: "TR-20"
      },
      {
        text: "Diyarbakır",
        value: "TR-21"
      },
      {
        text: "Edirne",
        value: "TR-22"
      },
      {
        text: "Elaziğ",
        value: "TR-23"
      },
      {
        text: "Erzincan",
        value: "TR-24"
      },
      {
        text: "Erzurum",
        value: "TR-25"
      },
      {
        text: "Eskişehir",
        value: "TR-26"
      },
      {
        text: "Gaziantep",
        value: "TR-27"
      },
      {
        text: "Giresun",
        value: "TR-28"
      },
      {
        text: "Gümüşhane",
        value: "TR-29"
      },
      {
        text: "Hakkari",
        value: "TR-30"
      },
      {
        text: "Hatay",
        value: "TR-31"
      },
      {
        text: "Iğdir",
        value: "TR-76"
      },
      {
        text: "Isparta",
        value: "TR-32"
      },
      {
        text: "İçel",
        value: "TR-33"
      },
      {
        text: "İstanbul",
        value: "TR-34"
      },
      {
        text: "İzmir",
        value: "TR-35"
      },
      {
        text: "Kahramanmaraş",
        value: "TR-46"
      },
      {
        text: "Karabük",
        value: "TR-78"
      },
      {
        text: "Karaman",
        value: "TR-70"
      },
      {
        text: "Kars",
        value: "TR-36"
      },
      {
        text: "Kastamonu",
        value: "TR-37"
      },
      {
        text: "Kayseri",
        value: "TR-38"
      },
      {
        text: "Kırıkkale",
        value: "TR-71"
      },
      {
        text: "Kırklareli",
        value: "TR-39"
      },
      {
        text: "Kırşehir",
        value: "TR-40"
      },
      {
        text: "Kilis",
        value: "TR-79"
      },
      {
        text: "Kocaeli",
        value: "TR-41"
      },
      {
        text: "Konya",
        value: "TR-42"
      },
      {
        text: "Kütahya",
        value: "TR-43"
      },
      {
        text: "Malatya",
        value: "TR-44"
      },
      {
        text: "Manisa",
        value: "TR-4S"
      },
      {
        text: "Mardin",
        value: "TR-47"
      },
      {
        text: "Muğla",
        value: "TR-48"
      },
      {
        text: "Muş",
        value: "TR-49"
      },
      {
        text: "Nevşehir",
        value: "TR-SO"
      },
      {
        text: "Niğde",
        value: "TR-51"
      },
      {
        text: "Ordu",
        value: "TR-52"
      },
      {
        text: "Rize",
        value: "TR-53"
      },
      {
        text: "Sakarya",
        value: "TR-54"
      },
      {
        text: "Samsun",
        value: "TR-SS"
      },
      {
        text: "Siirt",
        value: "TR-56"
      },
      {
        text: "Sinop",
        value: "TR-57"
      },
      {
        text: "Sivas",
        value: "TR-S8"
      },
      {
        text: "Şanlıurfa",
        value: "TR-63"
      },
      {
        text: "Şirnak",
        value: "TR-73"
      },
      {
        text: "Tekirdağ",
        value: "TR-59"
      },
      {
        text: "Tokat",
        value: "TR-60"
      },
      {
        text: "Trabzon",
        value: "TR-61"
      },
      {
        text: "Tunceli",
        value: "TR-62"
      },
      {
        text: "Uşak",
        value: "TR-64"
      },
      {
        text: "Van",
        value: "TR-65"
      },
      {
        text: "Yalova",
        value: "TR-77"
      },
      {
        text: "Yozgat",
        value: "TR-66"
      },
      {
        text: "Zonguldak",
        value: "TR-67"
      }
    ]
  },
  {
    text: "Turkmenistan",
    value: "TM",
    divisions: [
      {
        text: "Ahal",
        value: "TM-A"
      },
      {
        text: "Balkan",
        value: "TM-B"
      },
      {
        text: "Daşhowuz",
        value: "TM-D"
      },
      {
        text: "Lebap",
        value: "TM-L"
      },
      {
        text: "Mary",
        value: "TM-M"
      }
    ]
  },
  {
    text: "Turks & Caicos Islands",
    value: "TC",
    divisions: [
      {
        text: "Turks & Caicos Islands",
        value: "TC-TC"
      }
    ]
  },
  {
    text: "Tuvalu",
    value: "TV",
    divisions: [
      {
        text: "Tuvalu",
        value: "TV-TV"
      }
    ]
  },
  {
    text: "Uganda",
    value: "UG",
    divisions: [
      {
        text: "Apac",
        value: "UG-APA"
      },
      {
        text: "Arua",
        value: "UG-ARU"
      },
      {
        text: "Bundibugyo",
        value: "UG-BUN"
      },
      {
        text: "Bushenyi",
        value: "UG-BUS"
      },
      {
        text: "Gulu",
        value: "UG-GUL"
      },
      {
        text: "Hoima",
        value: "UG-HOI"
      },
      {
        text: "Iganga",
        value: "UG-IGA"
      },
      {
        text: "Jinja",
        value: "UG-JIN"
      },
      {
        text: "Kabale",
        value: "UG-KBL"
      },
      {
        text: "Kabarole",
        value: "UG-KBR"
      },
      {
        text: "Kalangala",
        value: "UG-KLG"
      },
      {
        text: "Kampala",
        value: "UG-KLA"
      },
      {
        text: "Kamuli",
        value: "UG-KLI"
      },
      {
        text: "Kapchorwa",
        value: "UG-KAP"
      },
      {
        text: "Kasese",
        value: "UG-KAS"
      },
      {
        text: "Kibaale",
        value: "UG-KLE"
      },
      {
        text: "Kiboga",
        value: "UG-KIB"
      },
      {
        text: "Kisoro",
        value: "UG-KIS"
      },
      {
        text: "Kitgum",
        value: "UG-KIT"
      },
      {
        text: "Kotido",
        value: "UG-KOT"
      },
      {
        text: "Kumi",
        value: "UG-KUM"
      },
      {
        text: "Lira",
        value: "UG-LIR"
      },
      {
        text: "Luwero",
        value: "UG-LUW"
      },
      {
        text: "Masaka",
        value: "UG-MSK"
      },
      {
        text: "Masindi",
        value: "UG-MSI"
      },
      {
        text: "Mbale",
        value: "UG-MBL"
      },
      {
        text: "Mbarara",
        value: "UG-MBR"
      },
      {
        text: "Moroto",
        value: "UG-MOR"
      },
      {
        text: "Moyo",
        value: "UG-MOY"
      },
      {
        text: "Mpigi",
        value: "UG-MPI"
      },
      {
        text: "Mubende",
        value: "UG-MUB"
      },
      {
        text: "Mukono",
        value: "UG-MUK"
      },
      {
        text: "Nebbi",
        value: "UG-NEB"
      },
      {
        text: "Ntungamo",
        value: "UG-NTU"
      },
      {
        text: "Pallisa",
        value: "UG-PAL"
      },
      {
        text: "Rakai",
        value: "UG-RAK"
      },
      {
        text: "Rukungiri",
        value: "UG-RUK"
      },
      {
        text: "Soroti",
        value: "UG-SOR"
      },
      {
        text: "Tororo",
        value: "UG-TOR"
      }
    ]
  },
  {
    text: "Ukraine",
    value: "UA",
    divisions: [
      {
        text: "Cherkas'ka Oblast'",
        value: "UA-71"
      },
      {
        text: "Chernihivs'ka Oblast'",
        value: "UA-74"
      },
      {
        text: "Chernivets'ka Oblast'",
        value: "UA-77"
      },
      {
        text: "Dnipropetrovs'ka Oblast'",
        value: "UA-12"
      },
      {
        text: "Donets'ka Oblast'",
        value: "UA-14"
      },
      {
        text: "Ivano-Frankivs'ka Oblast'",
        value: "UA-26"
      },
      {
        text: "Kharkivs'ka Oblast'",
        value: "UA-63"
      },
      {
        text: "Khersons'ka Oblast'",
        value: "UA-65"
      },
      {
        text: "Khmel'nyts'ka Oblast'",
        value: "UA-68"
      },
      {
        text: "Kirovohrads'ka Oblast'",
        value: "UA-35"
      },
      {
        text: "Kyïvs'ka Oblast'",
        value: "UA-32"
      },
      {
        text: "Luhans'ka Oblast'",
        value: "UA-09"
      },
      {
        text: "L'vivs'ka Oblast'",
        value: "UA-46"
      },
      {
        text: "Mykolaïvs'ka Oblast'",
        value: "UA-48"
      },
      {
        text: "Odes'ka Oblast'",
        value: "UA-51"
      },
      {
        text: "Poltavs'ka Oblast'",
        value: "UA-53"
      },
      {
        text: "Rivnens'ka Oblast'",
        value: "UA-56"
      },
      {
        text: "Sums'ka Oblast'",
        value: "UA-59"
      },
      {
        text: "Ternopil's'ka Oblast'",
        value: "UA-61"
      },
      {
        text: "Vinnyts'ka Oblast'",
        value: "UA-05"
      },
      {
        text: "Volyns'ka Oblast'",
        value: "UA-07"
      },
      {
        text: "Zakarpats'ka Oblast'",
        value: "UA-21"
      },
      {
        text: "Zaporiz'ka Oblast'",
        value: "UA-23"
      },
      {
        text: "Zhytomyrs'ka Oblast'",
        value: "UA-18"
      },
      {
        text: "Respublika Krym",
        value: "UA-43"
      },
      {
        text: "Kyïv",
        value: "UA-30"
      },
      {
        text: "Sevastopol'",
        value: "UA-40"
      }
    ]
  },
  {
    text: "United Arab Emirates",
    value: "AE",
    divisions: [
      {
        text: "Abū Zaby (Abu Dhabi)",
        value: "AE-AZ"
      },
      {
        text: "‘Ajmān",
        value: "AE-AJ"
      },
      {
        text: "Al Fujayrah",
        value: "AE-FU"
      },
      {
        text: "Ash Shāriqah (Sharjah)",
        value: "AE-SH"
      },
      {
        text: "Dubayy (Dubai)",
        value: "AE-DU"
      },
      {
        text: "R'as al Khaymah",
        value: "AE-RK"
      },
      {
        text: "Umm al Qaywayn",
        value: "AE-UQ"
      }
    ]
  },
  {
    text: "United States",
    value: "US",
    divisions: [
      {
        text: "Alabama",
        value: "US-AL"
      },
      {
        text: "Alaska",
        value: "US-AK"
      },
      {
        text: "Arizona",
        value: "US-AZ"
      },
      {
        text: "Arkansas",
        value: "US-AR"
      },
      {
        text: "California",
        value: "US-CA"
      },
      {
        text: "Colorado",
        value: "US-CO"
      },
      {
        text: "Connecticut",
        value: "US-CT"
      },
      {
        text: "Delaware",
        value: "US-DE"
      },
      {
        text: "Florida",
        value: "US-FL"
      },
      {
        text: "Georgia",
        value: "US-GA"
      },
      {
        text: "Hawaii",
        value: "US-HI"
      },
      {
        text: "Idaho",
        value: "US-ID"
      },
      {
        text: "Illinois",
        value: "US-IL"
      },
      {
        text: "Indiana",
        value: "US-IN"
      },
      {
        text: "Iowa",
        value: "US-IA"
      },
      {
        text: "Kansas",
        value: "US-KS"
      },
      {
        text: "Kentucky",
        value: "US-KY"
      },
      {
        text: "Louisiana",
        value: "US-LA"
      },
      {
        text: "Maine",
        value: "US-ME"
      },
      {
        text: "Maryland",
        value: "US-MD"
      },
      {
        text: "Massachusetts",
        value: "US-MA"
      },
      {
        text: "Michigan",
        value: "US-MI"
      },
      {
        text: "Minnesota",
        value: "US-MN"
      },
      {
        text: "Mississippi",
        value: "US-MS"
      },
      {
        text: "Missouri",
        value: "US-MO"
      },
      {
        text: "Montana",
        value: "US-MT"
      },
      {
        text: "Nebraska",
        value: "US-NE"
      },
      {
        text: "Nevada",
        value: "US-NV"
      },
      {
        text: "New Hampshire",
        value: "US-NH"
      },
      {
        text: "New Jersey",
        value: "US-NJ"
      },
      {
        text: "New Mexico",
        value: "US-NM"
      },
      {
        text: "New York",
        value: "US-NY"
      },
      {
        text: "North Carolina",
        value: "US-NC"
      },
      {
        text: "North Dakota",
        value: "US-ND"
      },
      {
        text: "Ohio",
        value: "US-OH"
      },
      {
        text: "Oklahoma",
        value: "US-OK"
      },
      {
        text: "Oregon",
        value: "US-OR"
      },
      {
        text: "Pennsylvania",
        value: "US-PA"
      },
      {
        text: "Rhode Island",
        value: "US-RI"
      },
      {
        text: "South Carolina",
        value: "US-SC"
      },
      {
        text: "South Dakota",
        value: "US-SD"
      },
      {
        text: "Tennessee",
        value: "US-TN"
      },
      {
        text: "Texas",
        value: "US-TX"
      },
      {
        text: "Utah",
        value: "US-UT"
      },
      {
        text: "Vermont",
        value: "US-VT"
      },
      {
        text: "Virginia",
        value: "US-VA"
      },
      {
        text: "Washington",
        value: "US-WA"
      },
      {
        text: "West Virginia",
        value: "US-WV"
      },
      {
        text: "Wisconsin",
        value: "US-WI"
      },
      {
        text: "Wyoming",
        value: "US-WY"
      },
      {
        text: "District of Columbia",
        value: "US-DC"
      },
      {
        text: "American Samoa",
        value: "US-AS"
      },
      {
        text: "Guam",
        value: "US-GU"
      },
      {
        text: "Northern Mariana Islands",
        value: "US-MP"
      },
      {
        text: "Puerto Rico",
        value: "US-PR"
      },
      {
        text: "United States Minor Outlying Islands",
        value: "US-UM"
      },
      {
        text: "Virgin Islands, U.S.",
        value: "US-VI"
      }
    ]
  },
  {
    text: "Uruguay",
    value: "UY",
    divisions: [
      {
        text: "Artigas",
        value: "UY-AR"
      },
      {
        text: "Canelones",
        value: "UY-CA"
      },
      {
        text: "Cerro Largo",
        value: "UY-CL"
      },
      {
        text: "Colonia",
        value: "UY-CO"
      },
      {
        text: "Durazno",
        value: "UY-DU"
      },
      {
        text: "Flores",
        value: "UY-FS"
      },
      {
        text: "Florida",
        value: "UY-FD"
      },
      {
        text: "Lavalleja",
        value: "UY-LA"
      },
      {
        text: "Maldonado",
        value: "UY-MA"
      },
      {
        text: "Montevideo",
        value: "UY-MO"
      },
      {
        text: "Paysandú",
        value: "UY-PA"
      },
      {
        text: "Río Negro",
        value: "UY-RN"
      },
      {
        text: "Rivera",
        value: "UY-RV"
      },
      {
        text: "Rocha",
        value: "UY-RO"
      },
      {
        text: "Salto",
        value: "UY-SA"
      },
      {
        text: "San José",
        value: "UY-SJ"
      },
      {
        text: "Soriano",
        value: "UY-SO"
      },
      {
        text: "Tacuarembó",
        value: "UY-TA"
      },
      {
        text: "Treinta y Tres",
        value: "UY-TT"
      }
    ]
  },
  {
    text: "Uzbekistan",
    value: "UZ",
    divisions: [
      {
        text: "Qoraqalpoghiston Respublikasi (Karakalpakstan, Respublika)",
        value: "UZ-QR"
      },
      {
        text: "Andijon (Andizhan)",
        value: "UZ-AN"
      },
      {
        text: "Bukhoro (Bukhara)",
        value: "UZ-BU"
      },
      {
        text: "Farghona (Fergana)",
        value: "UZ-FA"
      },
      {
        text: "Jizzakh (Dzhizak)",
        value: "UZ-JI"
      },
      {
        text: "Khorazm (Khorezm)",
        value: "UZ-KH"
      },
      {
        text: "Namangan",
        value: "UZ-NG"
      },
      {
        text: "Nawoiy (Navoi)",
        value: "UZ-NW"
      },
      {
        text: "Qashqadaryo (Kashkadar'ya)",
        value: "UZ-QA"
      },
      {
        text: "Samarqand (Samarkand)",
        value: "UZ-SA"
      },
      {
        text: "Sirdaryo (Syrdar'ya)",
        value: "UZ-SI"
      },
      {
        text: "Surkhondaryo (Surkhandar'ya)",
        value: "UZ-SU"
      },
      {
        text: "Toshkent (Tashkent)",
        value: "UZ-TO"
      }
    ]
  },
  {
    text: "Vanuatu",
    value: "VU",
    divisions: [
      {
        text: "Malampa",
        value: "VU-MAP"
      },
      {
        text: "Pénama",
        value: "VU-PAM"
      },
      {
        text: "Sanma",
        value: "VU-SAM"
      },
      {
        text: "Shéfa",
        value: "VU-SEE"
      },
      {
        text: "Taféa",
        value: "VU-TAE"
      },
      {
        text: "Torba",
        value: "VU-TOB"
      }
    ]
  },
  {
    text: "Venezuela",
    value: "VE",
    divisions: [
      {
        text: "Distrito Federal",
        value: "VE-A"
      },
      {
        text: "Anzoátegui",
        value: "VE-B"
      },
      {
        text: "Apure",
        value: "VE-C"
      },
      {
        text: "Aragua",
        value: "VE-D"
      },
      {
        text: "Barinas",
        value: "VE-E"
      },
      {
        text: "Bolívar",
        value: "VE-F"
      },
      {
        text: "Carabobo",
        value: "VE-G"
      },
      {
        text: "Cojedes",
        value: "VE-H"
      },
      {
        text: "Falcón",
        value: "VE-I"
      },
      {
        text: "Guárico",
        value: "VE-J"
      },
      {
        text: "Lara",
        value: "VE-K"
      },
      {
        text: "Mérida",
        value: "VE-L"
      },
      {
        text: "Miranda",
        value: "VE-M"
      },
      {
        text: "Monagas",
        value: "VE-N"
      },
      {
        text: "Nueva Esparta",
        value: "VE-O"
      },
      {
        text: "Portuguesa",
        value: "VE-P"
      },
      {
        text: "Sucre",
        value: "VE-R"
      },
      {
        text: "Táchira",
        value: "VE-S"
      },
      {
        text: "Trujillo",
        value: "VE-T"
      },
      {
        text: "Yaracuy",
        value: "VE-U"
      },
      {
        text: "Zulia",
        value: "VE-V"
      },
      {
        text: "Amazonas",
        value: "VE-Z"
      },
      {
        text: "Delta Amacuro",
        value: "VE-Y"
      },
      {
        text: "Dependencias Federales",
        value: "VE-W"
      }
    ]
  },
  {
    text: "Viet Nam",
    value: "VN",
    divisions: [
      {
        text: "An Giang",
        value: "VN-44"
      },
      {
        text: "Bat Can",
        value: "VN-53"
      },
      {
        text: "Bat Giang",
        value: "VN-54"
      },
      {
        text: "Bat Lieu",
        value: "VN-55"
      },
      {
        text: "Bat Ninh",
        value: "VN-56"
      },
      {
        text: "Ba Ria - Vung Tau",
        value: "VN-43"
      },
      {
        text: "Ben Tre",
        value: "VN-50"
      },
      {
        text: "Binh Dinh",
        value: "VN-31"
      },
      {
        text: "Binh Duong",
        value: "VN-57"
      },
      {
        text: "Binh Phuoc",
        value: "VN-58"
      },
      {
        text: "Binh Thuan",
        value: "VN-40"
      },
      {
        text: "Ca Mau",
        value: "VN-59"
      },
      {
        text: "Can Tho",
        value: "VN-48"
      },
      {
        text: "Cao Bang",
        value: "VN-04"
      },
      {
        text: "Dac Lac",
        value: "VN-33"
      },
      {
        text: "Da Nang, thanh pho",
        value: "VN-60"
      },
      {
        text: "Dong Nai",
        value: "VN-39"
      },
      {
        text: "Dong Thap",
        value: "VN-45"
      },
      {
        text: "Gia Lai",
        value: "VN-30"
      },
      {
        text: "Ha Giang",
        value: "VN-03"
      },
      {
        text: "Hai Duong",
        value: "VN-61"
      },
      {
        text: "Hai Phong, thanh pho",
        value: "VN-62"
      },
      {
        text: "Ha Nam",
        value: "VN-63"
      },
      {
        text: "Ha Noi, thu do",
        value: "VN-64"
      },
      {
        text: "Ha Tay",
        value: "VN-15"
      },
      {
        text: "Ha Tinh",
        value: "VN-23"
      },
      {
        text: "Hoa Binh",
        value: "VN-14"
      },
      {
        text: "Ho Chi Minh, thanh po [Sai Gon]",
        value: "VN-65"
      },
      {
        text: "Hung Yen",
        value: "VN-66"
      },
      {
        text: "Khanh Hoa",
        value: "VN-34"
      },
      {
        text: "Kien Giang",
        value: "VN-47"
      },
      {
        text: "Kon Turn",
        value: "VN-28"
      },
      {
        text: "Lai Chau",
        value: "VN-01"
      },
      {
        text: "Lam Dong",
        value: "VN-35"
      },
      {
        text: "Lang Son",
        value: "VN-09"
      },
      {
        text: "Lao Cai",
        value: "VN-02"
      },
      {
        text: "Long An",
        value: "VN-41"
      },
      {
        text: "Nam Dinh",
        value: "VN-67"
      },
      {
        text: "Nghe An",
        value: "VN-22"
      },
      {
        text: "Ninh Binh",
        value: "VN-18"
      },
      {
        text: "Ninh Thuan",
        value: "VN-36"
      },
      {
        text: "Phu Tho",
        value: "VN-68"
      },
      {
        text: "Phu Yen",
        value: "VN-32"
      },
      {
        text: "Quang Ninh",
        value: "VN-24"
      },
      {
        text: "Quang Nam",
        value: "VN-27"
      },
      {
        text: "Quang Ngai",
        value: "VN-29"
      },
      {
        text: "Quang Tri",
        value: "VN-25"
      },
      {
        text: "Sec Trang",
        value: "VN-52"
      },
      {
        text: "Son La",
        value: "VN-05"
      },
      {
        text: "Tay Ninh",
        value: "VN-37"
      },
      {
        text: "Thai Binh",
        value: "VN-20"
      },
      {
        text: "Thai Nguyen",
        value: "VN-69"
      },
      {
        text: "Thanh Hoa",
        value: "VN-21"
      },
      {
        text: "Thua Thien-Hue",
        value: "VN-26"
      },
      {
        text: "Tien Giang",
        value: "VN-46"
      },
      {
        text: "Tra Vinh",
        value: "VN-51"
      },
      {
        text: "Tuyen Quang",
        value: "VN-07"
      },
      {
        text: "Vinh Long",
        value: "VN-49"
      },
      {
        text: "Vinh Yen",
        value: "VN-70"
      },
      {
        text: "Yen Bai",
        value: "VN-06"
      }
    ]
  },
  {
    text: "Virgin Islands (British)",
    value: "VG",
    divisions: [
      {
        text: "Virgin Islands (British)",
        value: "VG-VG"
      }
    ]
  },
  {
    text: "Virgin Islands (U.S.)",
    value: "VI",
    divisions: [
      {
        text: "Virgin Islands (U.S.)",
        value: "VI-VI"
      }
    ]
  },
  {
    text: "Wallis & Futuna Islands",
    value: "WF",
    divisions: [
      {
        text: "Wallis & Futuna Islands",
        value: "WF-WF"
      }
    ]
  },
  {
    text: "Western Sahara",
    value: "EH",
    divisions: [
      {
        text: "Western Sahara",
        value: "EH-EH"
      }
    ]
  },
  {
    text: "Yemen",
    value: "YE",
    divisions: [
      {
        text: "Abyān",
        value: "YE-AB"
      },
      {
        text: "‘Adan",
        value: "YE-AD"
      },
      {
        text: "Al Bayḑā'",
        value: "YE-BA"
      },
      {
        text: "Al Ḩudaydah",
        value: "YE-HU"
      },
      {
        text: "Al Jawf",
        value: "YE-JA"
      },
      {
        text: "Al Mahrah",
        value: "YE-MR"
      },
      {
        text: "Al Maḩwit",
        value: "YE-MW"
      },
      {
        text: "Dhamār",
        value: "YE-DH"
      },
      {
        text: "Ḩaḑramawt",
        value: "YE-HD"
      },
      {
        text: "Ḩajjah",
        value: "YE-HJ"
      },
      {
        text: "Ibb",
        value: "YE-IB"
      },
      {
        text: "Laḩij",
        value: "YE-LA"
      },
      {
        text: "Ma'rib",
        value: "YE-MA"
      },
      {
        text: "Şa'dah",
        value: "YE-SD"
      },
      {
        text: "Şan‘ā'",
        value: "YE-SN"
      },
      {
        text: "Shabwah",
        value: "YE-SH"
      },
      {
        text: "Ta‘izz",
        value: "YE-TA"
      }
    ]
  },
  {
    text: "Yugoslavia",
    value: "YU",
    divisions: [
      {
        text: "Crna Gora",
        value: "YU-CG"
      },
      {
        text: "Srbija",
        value: "YU-SR"
      },
      {
        text: "Kosovo-Metohija",
        value: "YU-KM"
      },
      {
        text: "Vojvodina",
        value: "YU-VO"
      }
    ]
  },
  {
    text: "Zambia",
    value: "ZM",
    divisions: [
      {
        text: "Central",
        value: "ZM-02"
      },
      {
        text: "Copperbelt",
        value: "ZM-08"
      },
      {
        text: "Eastern",
        value: "ZM-03"
      },
      {
        text: "Luapula",
        value: "ZM-04"
      },
      {
        text: "Lusaka",
        value: "ZM-09"
      },
      {
        text: "Northern",
        value: "ZM-05"
      },
      {
        text: "North-Western",
        value: "ZM-06"
      },
      {
        text: "Southern",
        value: "ZM-07"
      },
      {
        text: "Western",
        value: "ZM-01"
      }
    ]
  },
  {
    text: "Zimbabwe",
    value: "ZW",
    divisions: [
      {
        text: "Bulawayo",
        value: "ZW-BU"
      },
      {
        text: "Harare",
        value: "ZW-HA"
      },
      {
        text: "Manicaland",
        value: "ZW-MA"
      },
      {
        text: "Mashonaland Central",
        value: "ZW-MC"
      },
      {
        text: "Mashonaland East",
        value: "ZW-ME"
      },
      {
        text: "Mashonaland West",
        value: "ZW-MW"
      },
      {
        text: "Masvingo",
        value: "ZW-MV"
      },
      {
        text: "Matabeleland North",
        value: "ZW-MN"
      },
      {
        text: "Matabeleland South",
        value: "ZW-MS"
      },
      {
        text: "Midlands",
        value: "ZW-MI"
      }
    ]
  }
];