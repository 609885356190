import {MutationTree} from 'vuex';
import {
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION
} from './mutation-types';
import {
  IntNotification
} from '@/common/interfaces/notifications/INotification';
import {
  IntNotificationState
} from './IntNotificationState';

const mutations: MutationTree<IntNotificationState> = {
  [SET_NOTIFICATIONS](state, payload: []) {
    state.notifications = payload;
  },
  [UPDATE_NOTIFICATION](state, payload: IntNotification) {
    state.notifications.forEach(item => {
      if(item.id == payload.id) item.isRead = true;
    });
  }
};
export default mutations;