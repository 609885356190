export const GET_ORG_PROFILE_INFO = 'GET_ORG_PROFILE_INFO';
export const GET_ORG_PROFILE_ADDRESS = 'GET_ORG_PROFILE_ADDRESS';
export const GET_ORG_IS_PROGRESS = 'GET_ORG_IS_PROGRESS';
export const GET_ORG_REQUEST_CHANGE_INPROGRESS = 'GET_ORG_REQUEST_CHANGE_INPROGRESS';
export const GET_REQUEST_INFO = 'GET_REQUEST_INFO';
export const GET_ACTIVE_STEPS = 'GET_ACTIVE_STEPS';
export const GET_CARLA_KEYCLOAK_TOKEN = 'GET_CARLA_KEYCLOAK_TOKEN';
export const GET_VC_FILTER_PARAMS = 'GET_VC_FILTER_PARAMS';
export const GET_VC_DETAILS = 'GET_VC_DETAILS';
export const GET_FETCH_VC_DETAILS_IN_PROGRESS = 'GET_FETCH_VC_DETAILS_IN_PROGRESS';
export const GET_APPEND_VCS = 'GET_APPEND_VCS';