import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';

const i18n = createI18n({
    legacy: false,
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    availableLocales: ["en", "de"],
    messages: {
      en,
      de
    }, // set locale messages
});

export default i18n;