/**
 * This service is use for get verified requests
 */

import axios from 'axios';
import Util from '../util';

export default class GetVerifiedService {
  /**
     * fetch a new carla service token from "notarisation" REALM
     * @param {}
     * @returns object
     */
  static carlaTokenRequest(payload: {}) {
    return axios.post(Util.getRestApiUrl(`token`, 'carlaKeycloak'), payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    });
  }

  /**
   * Create a new verified credentials
   * @param requestPayload
   * @returns object
   */
  static createVCRequest(requestPayload: any, carlaKeycloakToken: string) {
    return axios.post(Util.getRestApiUrl('validation/liLa', 'carla'), requestPayload, {
      headers: {
        "Authorization": 'bearer ' + carlaKeycloakToken,
        "UI": true
      }
    });
  }

  /**
   * Fetch verified credentials
   * @param filter  <string> - parameter to filter based on status
   * @param page  <number> - start index of record to fetch VCs
   * @param pageSize  <number> - number of records to be fetched
   * @param find  <string> - parameter to pass search keyword
   * @returns object
   */
  static fetchVCRequest(filter:string, page:number, pageSize:number, find:string, carlaKeycloakToken: string) {
    return axios.get(Util.getRestApiUrl(`validation/liLa/search?filter=${filter}&page=${page}&pageSize=${pageSize}&find=${find}`, 'carla'), {
      headers: {
        "Authorization": 'bearer ' + carlaKeycloakToken,
        "UI": true
      }
    });
  }

  /**
   * Revoke verified credentials
   * @param verifiedCredentials  <array> - parameter to filter based on status
   * @returns object
   */
  static revokeVCRequest(verifiedCredentials:any[], carlaKeycloakToken: string) {
    return axios.post(Util.getRestApiUrl('', 'carla-revoke'), verifiedCredentials, {
      headers: {
        "Authorization": 'bearer ' + carlaKeycloakToken,
        "UI": true
      }
    });
  }
}